import React from 'react';
import PropTypes from 'prop-types';

import PostEditor from './PostEditor';
import { PostEditorProvider } from './PostEditorStore';

const PostEditorContainer = ({
  post,
  attachmentEnabled,
  attachmentVisible,
  toolbarVisible,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  title,
  bigSubmit,
  isAuthorEditable,
}) => (
  <PostEditorProvider
    attachmentEnabled={attachmentEnabled}
    attachmentVisible={attachmentVisible}
    toolbarVisible={toolbarVisible}
    post={post}
  >
    <PostEditor
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitText={submitText}
      cancelText={cancelText}
      title={title}
      bigSubmit={bigSubmit}
      post={post} // TODO: Remove? Use context instead?
      isAuthorEditable={isAuthorEditable}
    />
  </PostEditorProvider>
);

PostEditorContainer.propTypes = {
  post: PropTypes.object,
  attachmentEnabled: PropTypes.bool,
  attachmentVisible: PropTypes.bool,
  toolbarVisible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired, // callback that returns the edited post when submitted
  onCancel: PropTypes.func, // if undefined cancel button will not show
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  bigSubmit: PropTypes.bool, // Makes the submit button bigger. Cant be used in conjunction with cancel
  isAuthorEditable: PropTypes.bool,
};

export default PostEditorContainer;
