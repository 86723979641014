import { SectionDataType } from 'Types';

export const SECTIONS = Object.freeze([
  {
    name: 'Riks',
    children: ['Nyhetstecken', 'Ođđasat', 'Uutiset', 'Väder'],
  },
  {
    name: 'Sport',
    children: [],
  },
  {
    name: 'Duo',
    children: [
      'Fråga doktorn',
      `Go'kväll`,
      'Lilla Aktuellt',
      'Melodifestivalen',
      'Musikhjälpen',
    ],
  },
  {
    name: 'Lokalt',
    children: [
      'Blekinge',
      'Dalarna',
      'Gävleborg',
      'Halland',
      'Helsingborg',
      'Jämtland',
      'Jönköping',
      'Norrbotten',
      'Skåne',
      'Småland',
      'Stockholm',
      'Södertälje',
      'Sörmland',
      'Uppsala',
      'Värmland',
      'Väst',
      'Västerbotten',
      'Västernorrland',
      'Västmanland',
      'Örebro',
      'Öst',
    ],
  },
  {
    name: 'Barn',
    children: [],
  },
  {
    name: 'Nödsajt',
    children: [],
  },
  {
    name: 'Test',
    children: ['Regression'],
  },
]);

export function validateSection(section: string) {
  const hasSection = SECTIONS.some((o) => o.children.includes(section));

  if (hasSection) {
    return section;
  }

  return '';
}

export function validateParentSection(section: string) {
  if (SECTIONS.some((o) => o.name === section)) {
    return section;
  }

  return '';
}

export function getParentSection(childSectionName: string) {
  const parentSection = SECTIONS.find((o) =>
    o.children.includes(childSectionName)
  );

  if (parentSection) {
    return parentSection.name;
  }

  return '';
}

export function createSectionData(value: string): SectionDataType {
  return {
    parentSection: validateParentSection(value) || getParentSection(value),
    section: validateSection(value),
  };
}
