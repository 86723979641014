import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './PauseNotifications.module.scss';

const PauseNotifications = ({ pauseState, isScrolledDown, onClick }) => {
  const disabled = pauseState !== 'MANUALLY_PAUSED' && !isScrolledDown;

  const rootClasses = cn(s.root, {
    [s.manuallyPaused]: pauseState === 'MANUALLY_PAUSED',
    [s.scrolledDown]: pauseState === 'AUTO_PAUSED' && isScrolledDown,
  });

  return (
    <button onClick={onClick} className={rootClasses} disabled={disabled}>
      {pauseState === 'MANUALLY_PAUSED'
        ? 'Inflöde pausat - återuppta'
        : 'Inflöde pausat - till toppen'}
    </button>
  );
};

PauseNotifications.propTypes = {
  pauseState: PropTypes.oneOf(['ACTIVE', 'MANUALLY_PAUSED', 'AUTO_PAUSED'])
    .isRequired,
  isScrolledDown: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PauseNotifications;
