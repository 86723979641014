import React, { useCallback, useContext, useState } from 'react';

import betterTrim from 'utils/betterTrim';
import AttachmentWebpage from 'components/Attachment/AttachmentWebpage';
import api from '../../../../services/api';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import addProtocol from 'utils/addProtocol';
import WebpageEditor from './WebpageEditor';

const whiteListedDomains = ['svt.se', 'svtplay.se', 'oppetarkiv.se'];

const isSvtUrl = (url) => {
  return whiteListedDomains.some((whiteListedDomain) =>
    url.toLowerCase().includes(whiteListedDomain)
  );
};

const useFetchOgData = () => {
  return useCallback(async (url) => {
    const res = await api.fetchOgData(url);

    if (res.status !== 'success') {
      throw new Error(res.message);
    }

    return {
      title: res.data.ogTitle,
      url: res.data.ogUrl,
      description: res.data.ogDescription,
      image: res.data?.ogImage?.url,
      escenicImageId: res.data.escenicImageId,
      escenicImageVersion: res.data.escenicImageVersion,
      astridImage: res.data.astridImage,
    };
  }, []);
};

const WebpageEditorContainer = () => {
  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);
  const attachment = postEditorState.postInProgress.attachment;
  const [inputUrl, setInputUrl] = useState(attachment.url);
  const [loadingOgData, setLoadingOgData] = useState(false);
  const [inputError, setInputError] = useState(null);

  const fetchOgData = useFetchOgData();

  const reactToUserInputChange = useCallback(
    async (url) => {
      if (!isSvtUrl(url)) {
        setInputError('Ange en SVT-webbadress');
        setLoadingOgData(false);
        return;
      }
      setInputError(null);
      try {
        const {
          title,
          image,
          description,
          url: ogUrl,
          escenicImageId,
          escenicImageVersion,
          astridImage,
        } = await fetchOgData(url);

        postEditorDispatcher({
          type: postEditorActions.SET_ATTACHMENT,
          payload: {
            type: AttachmentWebpage.type,
            title,
            image: image,
            description,
            url: ogUrl || url,
            escenicImageId,
            escenicImageVersion,
            astridImage,
          },
        });
      } catch (err) {
        postEditorDispatcher({
          type: postEditorActions.SET_ATTACHMENT,
          payload: WebpageEditorContainer.initialData,
        });
        setInputError('Kunde inte hämta webbsidan, prova att ändra adressen');
      }

      setLoadingOgData(false);
    },
    [fetchOgData, postEditorDispatcher]
  );

  const debouncedReactToUserInputChange = useDebouncedCallback(
    reactToUserInputChange,
    2000
  );

  const handleUrlChange = (e) => {
    const url = betterTrim(e.target.value);
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: WebpageEditorContainer.initialData,
    });
    setInputError(null);
    setInputUrl(url);

    if (url) {
      const formatedUrl = addProtocol(url, 'https');
      setLoadingOgData(true);
      debouncedReactToUserInputChange(formatedUrl);
    }
  };

  return (
    <WebpageEditor
      inputUrl={inputUrl}
      handleUrlChange={handleUrlChange}
      inputError={inputError}
      attachment={AttachmentWebpage.validate(attachment) ? attachment : null}
      loadingOgData={loadingOgData}
    />
  );
};

WebpageEditorContainer.type = 'webpage-editor';

WebpageEditorContainer.initialData = {
  type: AttachmentWebpage.type,
  url: '',
  image: '',
  description: '',
  title: '',
};

export default WebpageEditorContainer;
