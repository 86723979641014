import { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ErrorContextProvider } from 'contexts/ErrorContext';
import { NotificationContextProvider } from 'contexts/NotificationContext';
import UserContext, { UserContextProvider } from 'contexts/UserContext';
import { ConnectivityContextProvider } from 'contexts/ConnectivityContext';

import AppBarContainer from 'components/AppBar/AppBarContainer';
import Statistics from 'components/Statistics/Statistics';
import NotificationListContainer from 'components/Notification/NotificationListContainer';
import ConnectivityDimmer from 'components/ConnectivityDimmer/ConnectivityDimmer';
import UserInfo from 'components/UserInfo/UserInfo';
import ServiceMessage from 'components/ServiceMessage/ServiceMessage';
import PageHeader from 'components/PageHeader/PageHeader';
import s from './Page.module.scss';
import PotentialErrorPage from 'pages/PotentialErrorPage';

type PageWrapperProps = {
  children: JSX.Element[];
};

const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
  const { isRestoring } = useContext(UserContext);
  if (isRestoring) {
    return null;
  } else {
    return <div className={s.root}>{children}</div>;
  }
};

type PageProps = {
  children: JSX.Element;
  showAppBar?: boolean;
};

const Page: FC<PageProps> = ({ children, showAppBar = true }) => (
  <ErrorContextProvider>
    <NotificationContextProvider>
      <ConnectivityContextProvider>
        <UserContextProvider>
          <PageWrapper>
            <Helmet titleTemplate="%s | SVT Direktcenter" />
            <PageHeader>
              <Statistics />
              <ServiceMessage />
              <NotificationListContainer />
              <UserInfo />
              <ConnectivityDimmer />
              {showAppBar && <AppBarContainer />}
            </PageHeader>
            <PotentialErrorPage>{children}</PotentialErrorPage>
          </PageWrapper>
        </UserContextProvider>
      </ConnectivityContextProvider>
    </NotificationContextProvider>
  </ErrorContextProvider>
);

export default Page;
