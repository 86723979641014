import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import useStream from 'hooks/useStream';
import ErrorContext from 'contexts/ErrorContext';
import withAuthentication from 'components/withAuthentication';
import withGuestAuthorization from 'components/withGuestAuthorization';
import useCreatePost from 'hooks/useCreatePost';
import PageContent from 'components/PageContent/PageContent';
import { isStream, Stream } from 'Types';
import useDisasterStream from 'hooks/useDisasterStream';
import PostingPage from './PostingPage';
import DisasterPostingPage from './DisasterPostingPage';

const PostingPageContainer = () => {
  const { streamId } = useParams() as { streamId: string };
  const { setNotFound, unsetNotFound } = useContext(ErrorContext);
  const navigate = useNavigate();

  const streamData = useStream(streamId);
  // TODO: Remove this typing when useStream is TypeScript
  const stream: Stream | {} = streamData[0];
  const loading = streamData[1] as boolean;
  const notFound = streamData[2] as boolean;
  const { isDisasterStream } = useDisasterStream();
  const createPost = useCreatePost(streamId);

  useEffect(() => {
    if (notFound) {
      setNotFound();
    } else {
      unsetNotFound();
    }
  }, [notFound, setNotFound, unsetNotFound]);

  const handleStreamDeleted = () => {
    navigate('/streams');
  };

  if (!isStream(stream)) {
    return null;
  }

  return (
    <PageContent withAside>
      <Helmet title={stream.title} />
      {isDisasterStream ? (
        <DisasterPostingPage
          createPost={createPost}
          stream={stream}
          loading={loading}
          streamId={streamId}
          handleStreamDeleted={handleStreamDeleted}
        />
      ) : (
        <PostingPage
          createPost={createPost}
          stream={stream}
          loading={loading}
          streamId={streamId}
          handleStreamDeleted={handleStreamDeleted}
        />
      )}
    </PageContent>
  );
};

export default withAuthentication(withGuestAuthorization(PostingPageContainer));
