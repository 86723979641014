import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { MdError } from 'react-icons/md';

import NotificationContext from 'contexts/NotificationContext';
import AvatarUpload from 'components/AvatarUpload/AvatarUpload';
import betterTrim from 'utils/betterTrim';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Tooltip from 'components/Tooltip/Tooltip';

import api from '../../services/api';
import s from './CreateGuestsEditor.module.scss';

const CreateGuestsEditor = ({
  streamId,
  onListGuests,
  close,
  setOpenedGuestId,
}) => {
  const [displayNameValue, setDisplayNameValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [newAvatarPath, setNewAvatarPath] = useState();
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [isModerationRequired, setIsModerationRequired] = useState(true);

  const { flashError } = useContext(NotificationContext);

  const onCreateGuest = useCallback(async () => {
    const response = await api.guestCreate(streamId, {
      displayName: betterTrim(displayNameValue),
      title: betterTrim(titleValue),
      avatarPath: betterTrim(newAvatarPath),
      isModerationRequired,
    });

    if (response.status !== 'success') {
      flashError(
        'Misslyckades med att skapa gäst. Kontrollera din anslutning och försök igen.'
      );
    } else {
      setDisplayNameValue('');
      setTitleValue('');
      setIsModerationRequired(true);
      setOpenedGuestId(response.data.guestId);
      await onListGuests();
      close();
    }
  }, [
    streamId,
    displayNameValue,
    titleValue,
    newAvatarPath,
    isModerationRequired,
    flashError,
    setOpenedGuestId,
    onListGuests,
    close,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreateGuest();
  };

  const onChangeDisplayName = (e) => {
    setDisplayNameValue(e.target.value);
  };

  const onChangeTitle = (e) => {
    setTitleValue(e.target.value);
  };

  const titleValueLength = betterTrim(titleValue).length;

  const isFormSubmittable =
    !isAvatarLoading &&
    betterTrim(displayNameValue).length >= 2 &&
    (titleValueLength <= 0 || titleValueLength >= 2);

  return (
    <React.Fragment>
      <h2 className={s.header}>Lägg till en gäst</h2>
      <form onSubmit={handleSubmit}>
        <AvatarUpload
          onChange={setNewAvatarPath}
          path={newAvatarPath}
          changeLoadingState={(loading) => {
            setIsAvatarLoading(loading);
          }}
          guest
        />

        <Input
          id="displayName"
          name="displayName"
          label="Namn"
          value={displayNameValue}
          onChange={onChangeDisplayName}
          type="text"
          maxLength={50}
          required
          autoFocus
        />
        <Input
          id="title"
          name="title"
          label="Titel, plats (frivilligt)"
          value={titleValue}
          onChange={onChangeTitle}
          type="text"
          maxLength={50}
        />
        <fieldset
          className={s.fieldsetElement}
          onChange={(e) => {
            setIsModerationRequired(e.target.value === 'true');
          }}
        >
          <legend className={s.legend}>Behörighetsnivå</legend>
          <label className={s.radioInput}>
            <input
              type="radio"
              name="isModerationRequired"
              value="true"
              className={s.radioInputElement}
              defaultChecked={isModerationRequired}
            />
            <div className={s.radioShadowElement} />
            <div className={s.radioLabelText}>
              Gästen får enbart se <strong>utvalda</strong> inkommande
              besökarinlägg
            </div>
          </label>
          <label className={s.radioInput}>
            <input
              type="radio"
              name="isModerationRequired"
              value="false"
              className={s.radioInputElement}
              defaultChecked={!isModerationRequired}
            />
            <div className={s.radioShadowElement} />
            <div className={s.radioLabelText}>
              Gästen får se <strong>alla</strong> inkommande besökarinlägg
            </div>
            <div className={s.warningWrapper}>
              <div className={s.warning}>
                <MdError />
                <Tooltip className={s.tooltip}>
                  OBS! Gästen kan inte radera besökarinlägg, blockera besökare
                  eller anmäla som hot
                </Tooltip>
              </div>
            </div>
          </label>
        </fieldset>
        <div className={s.formFooter}>
          <Button
            className={s.abortButton}
            variant="secondary"
            type="button"
            label="Avbryt"
            onClick={close}
          >
            Avbryt
          </Button>
          <Button
            variant="primary"
            type="submit"
            label="Nästa steg"
            disabled={!isFormSubmittable}
          >
            Nästa steg
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

CreateGuestsEditor.propTypes = {
  close: PropTypes.func.isRequired,
  streamId: PropTypes.string.isRequired,
  onListGuests: PropTypes.func.isRequired,
  setOpenedGuestId: PropTypes.func.isRequired,
};

export default CreateGuestsEditor;
