export default function getPostParts(html: string): {
  heading: string | null;
  body: string | null;
} {
  const parts = html
    .trim()
    .match(/^<p>\s*<strong>([^<]*)<\/strong>\s*<\/p>(.*)/);

  if (parts) {
    const heading = parts[1].trim();
    const body = parts[2].trim();
    return {
      heading: heading === '' ? null : heading,
      body: body === '' ? null : body,
    };
  }

  return {
    heading: null,
    body: html.trim() === '' ? null : html.trim(),
  };
}
