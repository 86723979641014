import { ReactNode } from 'react';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { NotificationTypes } from 'contexts/NotificationContext';

import s from './Notification.module.scss';

type NotificationProps = {
  message: ReactNode | ReactNode[];
  type: NotificationTypes;
  closing: boolean;
  onClose: () => void;
};
export default function Notification({
  message,
  type,
  closing,
  onClose,
}: NotificationProps) {
  function renderMessages() {
    if (_.isArray(message)) {
      return message.map((message, i) => {
        return (
          <p className={s.message} key={i}>
            {message}
          </p>
        );
      });
    } else if (_.isString(message)) {
      return <p className={s.message}>{message}</p>;
    }
    return null;
  }

  if (!message || _.isEmpty(message)) {
    return null;
  }

  const rootClasses = cn(s.root, {
    [s.rootSuccess]: type === 'success',
    [s.rootError]: type === 'error',
    [s.rootInfo]: type === 'info',
    [s.rootClosing]: closing,
  });

  return (
    <div className={rootClasses}>
      {onClose ? (
        <button title="stäng" className={s.close} onClick={onClose}>
          <CloseIcon className={s.closeIcon} />
        </button>
      ) : null}
      {renderMessages()}
    </div>
  );
}
