import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import StyleguidePage from './styleguide/Styleguide';
import StreamsPage from './pages/StreamsPage';
import PostingPageContainer from './pages/PostingPageContainer';
import ReportedPage from './pages/ReportedPage';
import VisitorPostsPage from './pages/VisitorPostsPage';
import StatisticsPage from './pages/StatisticsPage';
import UsersPage from './pages/UsersPage';
import GuestsPage from './pages/GuestsPage';
import CreateStreamPage from './pages/CreateStreamPage';
import LoginPage from './pages/LoginPage';
import LoginEmailPasswordPage from './pages/LoginEmailPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import LoginAdPage from './pages/LoginAdPage';
import LoginGuestPage from './pages/LoginGuestPage';
import Page from 'components/Page/Page';
import './styles/global.scss';
import useDisasterStream from 'hooks/useDisasterStream';

const styleguideEnabled = Boolean(
  Number(process.env.REACT_APP_STYLEGUIDE_ENABLED)
);
const guestsEnabled = Boolean(Number(process.env.REACT_APP_GUESTS_ENABLED));

const App = () => {
  const { disasterStreamId } = useDisasterStream();
  return (
    <Router>
      <Routes>
        {styleguideEnabled && (
          <Route path="/styleguide" element={<StyleguidePage />} />
        )}

        <Route path="/" element={<Navigate replace to="/streams" />} />

        <Route
          path="/streams"
          element={
            <Page>
              <StreamsPage />
            </Page>
          }
        />
        <Route
          path="/login"
          element={
            <Page showAppBar={false}>
              <LoginPage />
            </Page>
          }
        />
        <Route
          path="/login-ad"
          element={
            <Page>
              <LoginAdPage />
            </Page>
          }
        />
        <Route
          path="/login2"
          element={
            <Page>
              <LoginEmailPasswordPage />
            </Page>
          }
        />
        <Route
          path="/streams/create"
          element={
            <Page>
              <CreateStreamPage />
            </Page>
          }
        />
        <Route
          path="/streams/:streamId"
          element={
            <Page>
              <PostingPageContainer />
            </Page>
          }
        />
        <Route
          path="/streams/:streamId/statistics"
          element={
            <Page>
              <StatisticsPage />
            </Page>
          }
        />
        <Route
          path="/streams/:streamId/visitor-posts"
          element={
            <Page>
              <VisitorPostsPage />
            </Page>
          }
        />
        <Route
          path="/streams/:streamId/reported"
          element={
            <Page>
              <ReportedPage />
            </Page>
          }
        />
        <Route
          path="/reported"
          element={
            <Page>
              <ReportedPage />
            </Page>
          }
        />
        <Route
          path="/users"
          element={
            <Page>
              <UsersPage />
            </Page>
          }
        />
        <Route
          path="/guests"
          element={
            <Page>
              <GuestsPage />
            </Page>
          }
        />
        {guestsEnabled && (
          <Route
            path="/guest/:token"
            element={
              <Page>
                <LoginGuestPage />
              </Page>
            }
          />
        )}
        <Route
          path="/disaster"
          element={<Navigate replace to={`/streams/${disasterStreamId}`} />}
        />
        <Route
          path="*"
          element={
            <Page>
              <NotFoundPage />
            </Page>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
