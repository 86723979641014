import React from 'react';
import PropTypes from 'prop-types';

import UsersTableRow from './UsersTableRow';
import s from './UsersTable.module.scss';

export default function UsersTable({ users, setSortField }) {
  if (!users) {
    return null;
  }

  return (
    <table className={s.root}>
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => {
              return setSortField('id');
            }}
          >
            ID
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('avatar');
            }}
          >
            Avatar
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('displayName');
            }}
          >
            Namn
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('title');
            }}
          >
            Titel
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('email');
            }}
          >
            Email
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('role');
            }}
          >
            Roll
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('lastRefreshTokenCreatedAt');
            }}
          >
            Senast skapad refresh token
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('latestLoginAt');
            }}
          >
            Senaste loginförsök
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('loginDurationMs');
            }}
          >
            Inloggad tid
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('adGroupNames');
            }}
          >
            AD-grupper
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('organisation');
            }}
          >
            Organisation
          </th>
        </tr>
      </thead>
      <tbody>
        {users.length <= 0 && (
          <tr>
            <td colSpan={100}>
              <span role="img" aria-label="">
                🤷‍♀️
              </span>{' '}
              Hittade inga användare.
            </td>
          </tr>
        )}
        {users.map((user) => {
          return <UsersTableRow user={user} key={user.id} />;
        })}
      </tbody>
    </table>
  );
}

UsersTable.propTypes = {
  users: PropTypes.array,
  setSortField: PropTypes.func,
};
