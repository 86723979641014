import { useState, useEffect } from 'react';

import StreamMetadata from 'services/Stream/StreamMetadata';

const useStream = (streamId) => {
  const [stream, setStream] = useState({});
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setLoading(true);
    const metadata = StreamMetadata({ streamId });

    const handleNewStreamData = (stream) => {
      setNotFound(false);
      setStream(stream);
      setLoading(false);
    };

    const handleStreamError = (err) => {
      if (err.notFound) {
        setNotFound(true);
      } else {
        console.error(err);
      }
      setLoading(false);
    };

    metadata.listen(handleNewStreamData, handleStreamError);

    return metadata.unsubscribe;
  }, [setNotFound, streamId]);

  return [stream, loading, notFound];
};

export default useStream;
