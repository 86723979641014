import { createContext, ReactNode, useReducer } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';

export type IdType = string;
export type NotificationTypes = 'success' | 'error' | 'info';
export type NotificationMessageType = {
  id?: IdType;
  type: NotificationTypes;
  message: ReactNode;
};
export type NotificationMessageWithIdType = {
  id: IdType;
  type: NotificationTypes;
  message: ReactNode;
};

type NotificationContextState = {
  notifications: Map<IdType, NotificationMessageWithIdType>;
  flashNotification: (message: NotificationMessageType) => void;
  flashError: (message: string) => void;
  setNotification: (payload: NotificationMessageWithIdType) => void;
  unsetNotification: (id: IdType) => void;
};
const NotificationContext = createContext<NotificationContextState>({
  notifications: new Map<IdType, NotificationMessageWithIdType>(),
  setNotification: () => {},
  unsetNotification: () => {},
  flashNotification: () => {},
  flashError: () => {},
});

type AddActionType = {
  type: 'add';
  payload: NotificationMessageType;
};
type SetActionType = {
  type: 'set';
  payload: NotificationMessageWithIdType;
};
type RemoveActionType = {
  type: 'remove';
  payload: IdType;
};
function reducer(
  state: Map<IdType, NotificationMessageWithIdType>,
  action: AddActionType | SetActionType | RemoveActionType
) {
  const newState = new Map(state);
  switch (action.type) {
    case 'add': {
      const id = uuid4();
      newState.set(id, { ...action.payload, id });
      break;
    }
    case 'set': {
      newState.set(action.payload.id, action.payload);
      break;
    }
    case 'remove': {
      newState.delete(action.payload);
      break;
    }
  }
  return newState;
}

type NotificationContextProviderProps = {
  children: ReactNode;
};
export const NotificationContextProvider = ({
  children,
}: NotificationContextProviderProps) => {
  const [notifications, dispatch] = useReducer(
    reducer,
    new Map<IdType, NotificationMessageWithIdType>()
  );

  const flashNotification = (notification: NotificationMessageType) =>
    dispatch({ type: 'add', payload: notification });
  const flashError = (message: string) =>
    dispatch({ type: 'add', payload: { type: 'error', message } });
  const unsetNotification = (id: IdType) =>
    dispatch({ type: 'remove', payload: id });
  const setNotification = (payload: NotificationMessageWithIdType) =>
    dispatch({ type: 'set', payload });

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        flashNotification,
        flashError,
        setNotification,
        unsetNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationContext;
