import { useReducer, useRef, useMemo, useCallback, useEffect } from 'react';

const INTERACTION_KEEP_ALIVE_MS = 10000;
const PAUSE_STATES = {
  MANUALLY_PAUSED: 'MANUALLY_PAUSED',
  AUTO_PAUSED: 'AUTO_PAUSED',
  ACTIVE: 'ACTIVE',
};

const initialState = {
  isManuallyPaused: false,
  isScrolledDown: false,
  isManuallyInteractingWithPendingPost: false,
  isCursorInteracting: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'MANUAL_PAUSE':
      return {
        ...state,
        isManuallyPaused: true,
      };
    case 'MANUAL_RESUME':
      return {
        ...state,
        isManuallyPaused: false,
      };
    case 'SCROLLED_DOWN':
      return {
        ...state,
        isScrolledDown: true,
      };
    case 'SCROLLED_TO_TOP':
      return {
        ...state,
        isScrolledDown: false,
      };
    case 'STARTED_MANUAL_INTERACTION_WITH_PENDING_POST':
      return {
        ...state,
        isManuallyInteractingWithPendingPost: true,
      };
    case 'STOPPED_MANUAL_INTERACTION_WITH_PENDING_POST':
      return {
        ...state,
        isManuallyInteractingWithPendingPost: false,
      };
    case 'STARTED_CURSOR_INTERACTION':
      return {
        ...state,
        isCursorInteracting: true,
      };
    case 'STOPPED_CURSOR_INTERACTION':
      return {
        ...state,
        isCursorInteracting: false,
      };
    default:
      throw new Error('Action type not recognized' + action.type);
  }
};

const usePauseManager = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const cursorInteractionTimer = useRef(null);

  const startCursorInteraction = useCallback(() => {
    dispatch({ type: 'STARTED_CURSOR_INTERACTION' });
    cursorInteractionTimer.current &&
      clearTimeout(cursorInteractionTimer.current);
    cursorInteractionTimer.current = setTimeout(
      () => dispatch({ type: 'STOPPED_CURSOR_INTERACTION' }),
      INTERACTION_KEEP_ALIVE_MS
    );
  }, []);

  const stopCursorInteraction = useCallback(() => {
    cursorInteractionTimer.current &&
      clearTimeout(cursorInteractionTimer.current);
    dispatch({ type: 'STOPPED_CURSOR_INTERACTION' });
  }, []);

  const isAutoPaused =
    state.isCursorInteracting ||
    state.isManuallyInteractingWithPendingPost ||
    state.isScrolledDown;

  let pauseState = PAUSE_STATES.ACTIVE;
  if (isAutoPaused) pauseState = PAUSE_STATES.AUTO_PAUSED;
  if (state.isManuallyPaused) pauseState = PAUSE_STATES.MANUALLY_PAUSED;

  useEffect(() => {
    return () => {
      clearTimeout(cursorInteractionTimer.current);
    };
  }, []);

  return useMemo(
    () => ({
      pauseState,
      isScrolledDown: state.isScrolledDown,
      startCursorInteraction,
      stopCursorInteraction,
      manualPause: () => dispatch({ type: 'MANUAL_PAUSE' }),
      manualResume: () => dispatch({ type: 'MANUAL_RESUME' }),
      scrolledDown: () => dispatch({ type: 'SCROLLED_DOWN' }),
      scrolledToTop: () => dispatch({ type: 'SCROLLED_TO_TOP' }),
      startedManuallyInteractingWithPendingPost: () =>
        dispatch({ type: 'STARTED_MANUAL_INTERACTION_WITH_PENDING_POST' }),
      stoppedManuallyInteractingWithPendingPost: () =>
        dispatch({ type: 'STOPPED_MANUAL_INTERACTION_WITH_PENDING_POST' }),
    }),
    [
      startCursorInteraction,
      pauseState,
      state.isScrolledDown,
      stopCursorInteraction,
    ]
  );
};

export default usePauseManager;
