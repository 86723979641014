import React from 'react';
import PropTypes from 'prop-types';
import { VISITOR, GUEST, EDITOR, ADMIN } from 'utils/roles';
import cn from 'classnames';
import Avatar from 'components/Avatar/Avatar';
import s from './PostByline.module.scss';

const PostByline = ({ author = {}, inReply = false }) => {
  const { displayName, role, title, avatar } = author;
  const fromVisitor = role === VISITOR;
  const fromGuest = role === GUEST;

  const PotentialSVTPrefix = !fromVisitor && !fromGuest && <span>SVT</span>;

  const PotentialTitle = title && <span className={s.title}>{title}</span>;

  return (
    <div
      className={cn(s.root, {
        [s.inReply]: inReply,
        [s.isVisitor]: fromVisitor,
      })}
    >
      <Avatar
        role={role}
        displayName={displayName}
        avatar={avatar}
        enablePreview
      />
      <div>
        <div className={s.displayName}>{displayName}</div>
        <div className={s.titleWrapper}>
          {PotentialSVTPrefix}
          {PotentialTitle}
        </div>
      </div>
    </div>
  );
};

PostByline.propTypes = {
  author: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    role: PropTypes.oneOf([VISITOR, GUEST, EDITOR, ADMIN]).isRequired,
    title: PropTypes.string,
    avatar: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  inReply: PropTypes.bool,
};

export default PostByline;
