import cn from 'classnames';
import s from './AvatarImage.module.scss';
import { ReactComponent as GuestIcon } from '../../img/direktcenter-guest-icon.svg';
import { ReactComponent as GuestIconDark } from '../../img/direktcenter-guest-icon-dark.svg';
import { ReactComponent as ProfileSvtIcon } from '../../img/direktcenter-svt-icon.svg';
import { ReactComponent as ProfileSvtIconDark } from '../../img/direktcenter-svt-icon-dark.svg';
import { GUEST } from 'utils/roles';
import { AvatarImageSize, DirektcenterRoleType } from 'Types';
import { FC } from 'react';

type AvatarImageProps = {
  displayName: string;
  role: DirektcenterRoleType;
  avatar: {
    path: string;
  };
  darkMode: boolean;
  size: AvatarImageSize;
  className?: string;
};

const AvatarImage: FC<AvatarImageProps> = ({
  displayName,
  avatar,
  role,
  darkMode,
  className,
  size = 'medium',
}) => {
  const classes = cn(className, s.root, {
    [s.small]: size === 'small',
    [s.medium]: size === 'medium',
    [s.large]: size === 'large',
    [s.extraLarge]: size === 'extraLarge',
  });

  if (avatar && avatar.path) {
    return (
      <div className={classes}>
        <img
          src={process.env.REACT_APP_AVATAR_BASE_URL + avatar.path}
          alt={`${displayName} bylinebild`}
        />
      </div>
    );
  } else if (role === GUEST) {
    return (
      <div className={classes}>
        {!darkMode && <GuestIcon title="Gäst" />}
        {darkMode && <GuestIconDark title="Gäst" />}
      </div>
    );
  } else {
    return (
      <div className={classes}>
        {!darkMode && <ProfileSvtIcon title="SVT-bylinebild" />}
        {darkMode && <ProfileSvtIconDark title="SVT-bylinebild" />}
      </div>
    );
  }
};

export default AvatarImage;
