import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'img/close.svg';
import s from './_SettingsContainer.module.scss';

const SettingsContainer = ({ children, onClose, enableClose }) => {
  return (
    <div className={s.root}>
      {enableClose && (
        <button className={s.closeButton} onClick={onClose} title="Stäng">
          <CloseIcon className={s.closeIcon} />
        </button>
      )}
      {children}
    </div>
  );
};

SettingsContainer.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  enableClose: PropTypes.bool.isRequired,
};

export default SettingsContainer;
