import HardError from '../../errors/HardError';

const INTERVAL = 1000;
const NOOP = () => {};

const PendingPostsPoller = ({ streamId, postsPerPage, api }) => {
  let state = {
    onUpdate: NOOP,
    onError: NOOP,
    onUnreadUpdate: NOOP,
    onUnreadError: NOOP,
    limit: postsPerPage,
    paused: false,
    pendingPostListInterval: null,
    unreadListInterval: null,
    mostRecentPostDate: null,
    hasMoreItems: null,
    hasGuests: false,
  };

  const _destroyUnreadListener = () => {
    state.unreadListInterval && clearInterval(state.unreadListInterval);
  };

  const _setupUnreadListener = () => {
    _destroyUnreadListener();
    state.unreadListInterval = setInterval(async () => {
      if (state.paused !== true) {
        return state.onUnreadUpdate(0);
      }
      try {
        const response = await api.pendingPostCount({
          streamId,
          ...(state.mostRecentPostDate !== null && {
            createdAfter: state.mostRecentPostDate,
          }),
        });
        if (response?.error instanceof Error) {
          throw response.error;
        }
        // state.pause can be changed after the request was started
        if (state.paused === true) {
          state.hasGuests = response.data.hasGuests;
          return state.onUnreadUpdate(response.data.pendingPostsCount);
        }
      } catch (error) {
        console.error('Failed to fetch unread posts due to: ', error.message);

        if (error instanceof HardError) {
          state.onUnreadError();
        }
      }
    }, INTERVAL);
  };

  const _destroyPendingPostListListener = () => {
    state.pendingPostListInterval &&
      clearInterval(state.pendingPostListInterval);
  };

  const _setupPendingPostListListener = () => {
    _destroyPendingPostListListener();
    state.pendingPostListInterval = setInterval(async () => {
      const pausedWithNoPosts = state.paused && !state.mostRecentPostDate;
      if (pausedWithNoPosts) {
        return state.onUpdate([]);
      }
      const createdAtOrBefore = !state.paused ? null : state.mostRecentPostDate;
      try {
        const response = await api.pendingPostList({
          streamId,
          limit: state.limit,
          ...(createdAtOrBefore && { createdAtOrBefore }),
        });
        if (response?.error instanceof Error) {
          throw response.error;
        }
        state.mostRecentPostDate = response.data.pendingPosts.length
          ? response.data.pendingPosts[0].createdAt
          : null;
        state.hasMoreItems = response.data.hasMoreItems;
        state.hasGuests = response.data.hasGuests;
        state.onUpdate(response.data.pendingPosts);
      } catch (error) {
        console.error('Failed to fetch pending posts due to: ', error.message);

        if (error instanceof HardError) {
          state.onError();
        }
      }
    }, INTERVAL);
  };

  const pause = () => {
    state.paused = true;
    _setupPendingPostListListener();
  };

  const resume = () => {
    state.paused = false;
    _setupPendingPostListListener();
  };

  const listen = (onUpdate, onError = () => {}) => {
    state.onUpdate = onUpdate;
    state.onError = onError;
    _setupPendingPostListListener();
  };

  const unsubscribe = () => {
    state.onUpdate = NOOP;
    state.onError = NOOP;
    _destroyPendingPostListListener();
  };

  const hasMoreItems = () => {
    return state.hasMoreItems;
  };

  const hasGuests = () => {
    return state.hasGuests;
  };

  const loadMoreItems = () => {
    state.limit = state.limit + postsPerPage;
    _setupPendingPostListListener();
  };

  const listenUnread = (onUpdate, onError = () => {}) => {
    if (!state.paused) {
      return state.onError(
        new Error('Cannot setup unread listener if not paused!')
      );
    }
    state.onUnreadUpdate = onUpdate;
    state.onUnreadError = onError;
    _setupUnreadListener();
  };

  const unsubscribeUnread = () => {
    _destroyUnreadListener();
    state.onUnreadUpdate = NOOP;
    state.onUnreadError = NOOP;
  };

  return {
    listen,
    unsubscribe,
    hasMoreItems,
    hasGuests,
    loadMoreItems,
    pause,
    resume,
    listenUnread,
    unsubscribeUnread,
  };
};

export default PendingPostsPoller;
