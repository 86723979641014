import React from 'react';
import PropTypes from 'prop-types';

import PostStream from './PostStream';
import usePaginatedPostStream from 'hooks/usePaginatedPostStream';

const PostStreamContainer = ({
  streamId,
  isEditable,
  filterHighlighted = false,
  isHighlightsFeatureEnabled,
}) => {
  const {
    posts,
    loadingInitialPosts,
    loadingMorePosts,
    hasMorePosts,
    loadMorePosts,
  } = usePaginatedPostStream({
    streamId,
    postsPerPage: 20,
    filterHighlighted,
  });

  return (
    <PostStream
      loading={loadingInitialPosts}
      posts={posts}
      loadingMorePosts={loadingMorePosts}
      hasMorePosts={hasMorePosts}
      loadMorePosts={loadMorePosts}
      streamId={streamId}
      isEditable={isEditable}
      isHighlightsFeatureEnabled={isHighlightsFeatureEnabled}
    />
  );
};

PostStreamContainer.propTypes = {
  streamId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  filterHighlighted: PropTypes.bool,
  isHighlightsFeatureEnabled: PropTypes.bool,
};

export default PostStreamContainer;
