import s from './ContactLink.module.scss';

const ContactLink = () => (
  <a
    className={s.root}
    href="https://teams.microsoft.com/l/channel/19%3afc7a58260b864873a9d34c9e95918fcc%40thread.skype/Direktcenter?groupId=f8d6da46-fbb2-4dd1-ad6b-0f73bc038a42&amp;tenantId=f5d227e4-4de5-4600-9015-df5f33e77f2f"
    target="_blank"
    rel="noopener noreferrer"
  >
    Frågor och feedback?
  </a>
);

export default ContactLink;
