import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';

function withAdminOrEditorAuthorization(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const { isAdminOrEditor } = useContext(UserContext);

    useEffect(() => {
      if (isAdminOrEditor === false) {
        navigate('/streams');
      }
    }, [isAdminOrEditor, navigate]);

    if (isAdminOrEditor === false) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAdminOrEditorAuthorization;
