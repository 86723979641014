import React from 'react';
import cn from 'classnames';
import AlwaysOnTop from '../AlwaysOnTop/AlwaysOnTop';

import s from './MenuDropdown.module.scss';
import { MenuAction } from 'Types';

type MenuDropdownProps = {
  actions: MenuAction[];
  isOpen: boolean;
  className: string;
  ariaLabelledby: string;
};

const MenuDropdown = React.forwardRef<HTMLUListElement, MenuDropdownProps>(
  ({ actions, isOpen, className, ariaLabelledby }, ref) => (
    <ul
      className={cn(s.root, className, { [s.isOpen]: isOpen })}
      role="menu"
      ref={ref}
      aria-labelledby={ariaLabelledby}
    >
      {actions.map(
        ({ title, callback, danger = false, disabled = false }, index) => (
          <AlwaysOnTop
            component="li"
            className={cn(s.item, {
              [s.danger]: danger,
              [s.disabled]: disabled,
            })}
            key={index}
            role="menuitem"
            tabIndex={-1}
            onClick={callback}
          >
            {title}
          </AlwaysOnTop>
        )
      )}
    </ul>
  )
);

export default MenuDropdown;
