import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../contexts/UserContext';

function withGuestAuthorization(WrappedComponent) {
  const EnhancedComponent = (props) => {
    const navigate = useNavigate();
    const { streamId } = useParams();
    const { isGuest, user } = useContext(UserContext);
    const userStreamId = user?.streamId;

    useEffect(() => {
      if (isGuest && streamId !== userStreamId) {
        navigate(`/streams/${userStreamId}`);
      }
    }, [isGuest, streamId, userStreamId, navigate]);

    if (isGuest && streamId !== userStreamId) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
}

export default withGuestAuthorization;
