import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { trackPageView } from 'utils/statistics';

const Statistics = () => {
  const { streamId } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView({
      product: {
        viewId: streamId,
        viewType: pathname.split('/')[1],
      },
      // TODO: when we use the correct typescript shape values are not passed to helix
    } as any);
  }, [pathname, streamId]);

  return null;
};

export default Statistics;
