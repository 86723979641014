import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './PageContent.module.scss';

const PageContent = ({ children, withAside }) => (
  <div className={cn(s.root, { [s.withAside]: withAside })}>{children}</div>
);
PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  withAside: PropTypes.bool,
};

export default PageContent;
