import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

import useStream from 'hooks/useStream';
import withAuthentication from 'components/withAuthentication';
import withAdminOrEditorAuthorization from 'components/withAdminOrEditorAuthorization';
import Module from 'components/Module/Module';
import VisitorPostsTable from 'components/VisitorPostsTable/VisitorPostsTable';
import api from '../services/api';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import s from './VisitorPostsPage.module.scss';

const pageLength = 200;

const sortPosts = (field, doSortAscending) => (postA, postB) => {
  const sortOrderMultiplier = doSortAscending ? 1 : -1;

  if (!postA[field] && postB[field]) {
    return -1 * sortOrderMultiplier;
  }

  if (postA[field] && !postB[field]) {
    return 1 * sortOrderMultiplier;
  }

  if (postA[field] > postB[field]) {
    return 1 * sortOrderMultiplier;
  } else {
    return -1 * sortOrderMultiplier;
  }
};

const Pagination = ({ page, setPage, total, className }) => {
  const nextPage = page + 1;
  const lastIndex = Math.min(nextPage * pageLength, total);

  return (
    <div className={className}>
      Visar inlägg {page * pageLength + 1} - {lastIndex} av totalt {total}
      <div>
        {page > 0 ? (
          <button
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <MdArrowBack />
          </button>
        ) : (
          <button disabled>
            <MdArrowBack />
          </button>
        )}
        {lastIndex < total ? (
          <button
            onClick={() => {
              setPage(nextPage);
            }}
          >
            <MdArrowForward />
          </button>
        ) : (
          <button disabled>
            <MdArrowForward />
          </button>
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const VisitorPostsPage = () => {
  const { streamId } = useParams();
  const [stream, loading] = useStream(streamId);
  const [visitorPostsForStream, setVisitorPostsForStream] = useState([]);
  const [sortField, setSortField] = useState('createdAt');
  const [doSortAscending, setDoSortAscending] = useState(false);
  const [page, setPage] = useState(0);

  const setSortFieldOrToggleDirection = useCallback(
    (newField) => {
      if (sortField === newField) {
        setDoSortAscending(!doSortAscending);
      } else {
        setSortField(newField);
      }
    },
    [doSortAscending, sortField]
  );

  const sortAndSaveData = useCallback(
    ({ data }) => {
      if (!data) {
        return false;
      }

      if (sortField) {
        data.pendingPosts.sort(sortPosts(sortField, doSortAscending));
      }
      setVisitorPostsForStream(data.pendingPosts);
    },
    [sortField, doSortAscending]
  );

  const listVisitorPostsForStreamId = useCallback(async () => {
    return sortAndSaveData(
      await api.pendingPostListVisitorPosts(
        streamId,
        pageLength,
        page * pageLength
      )
    );
  }, [sortAndSaveData, streamId, page]);

  useEffect(() => {
    listVisitorPostsForStreamId();
  }, [listVisitorPostsForStreamId]);

  const total = visitorPostsForStream[0]?.total;

  const title = `Besökarinlägg i strömmen '${stream?.title}'`;

  return (
    <PageContent>
      <PageMainContainer loading={loading}>
        <Helmet title={title} />
        <PageTitle>{title}</PageTitle>
        {visitorPostsForStream.length && (
          <Pagination
            className={s.pagination}
            page={page}
            setPage={setPage}
            total={total}
          />
        )}
        <Module>
          <VisitorPostsTable
            pendingPosts={visitorPostsForStream}
            setSortField={setSortFieldOrToggleDirection}
            sortField={sortField}
            doSortAscending={doSortAscending}
          ></VisitorPostsTable>
        </Module>
        {visitorPostsForStream.length && (
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            className={s.pagination}
          />
        )}
        <Module>
          <a href={`/streams/${streamId}`}>Tillbaka till strömmen</a>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(
  withAdminOrEditorAuthorization(VisitorPostsPage)
);
