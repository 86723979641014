import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from '../../contexts/UserContext';
import AppBar from './AppBar';

export default function AppBarContainer() {
  const navigate = useNavigate();
  const { logout, user } = useContext(UserContext);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
    } catch {
    } finally {
      navigate('/login');
    }
  }, [logout, navigate]);

  return <AppBar user={user} logout={handleLogout} />;
}
