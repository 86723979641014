import { useEffect, useRef } from 'react';
import { trackEvent } from 'utils/statistics';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import s from './Bookmarklet.module.scss';

const Bookmarklet = () => {
  const linkRef = useRef(null);

  const bookmarkletUrl = `${process.env.REACT_APP_EDITOR_URL}/bookmarklet.js`;

  const urlencode = (content) => {
    return encodeURIComponent(content)
      .replaceAll('!', '%21')
      .replaceAll("'", '%27')
      .replaceAll('(', '%28')
      .replaceAll(')', '%29')
      .replaceAll('*', '%2A')
      .replaceAll('\n', '');
  };

  const bookmarkletLoader = `(() => {
    const e = document.createElement("script");
    e.async = 1;
    e.src = "${bookmarkletUrl}";
    document.getElementsByTagName("body")[0].appendChild(e)
  })();`;

  const encodedBookmarkletLoader = `javascript:${urlencode(bookmarkletLoader)}`;

  // Note: This is to avoid 'Warning: A future version of React will block javascript'
  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.setAttribute('href', encodedBookmarkletLoader);
    }
  });

  const trackUserInteraction = (userInteraction) => {
    trackEvent({
      name: 'bookmarklet',
      eventValues: {
        userInteraction,
      },
    });
  };

  return (
    <div>
      <a
        className={s.link}
        href="/"
        ref={linkRef}
        onClick={() => trackUserInteraction('click')}
        onDragEnd={() => trackUserInteraction('drag')}
      >
        <StarBorderIcon fontSize="small" />
        Direktcenter
      </a>
    </div>
  );
};

export default Bookmarklet;
