import { useContext } from 'react';

import Button from 'components/Button/Button';
import ImageEditorContainer from './ImageEditor/ImageEditorContainer';
import SvtVideoEditorContainer from './SvtVideoEditor/SvtVideoEditorContainer';
import WebpageEditorContainer from './WebpageEditor/WebpageEditorContainer';
import s from './NewAttachmentSelector.module.scss';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import UserContext from 'contexts/UserContext';
import useDisasterStream from 'hooks/useDisasterStream';

const initialDataMap = {
  [ImageEditorContainer.type]: ImageEditorContainer.initialData,
  [WebpageEditorContainer.type]: WebpageEditorContainer.initialData,
  [SvtVideoEditorContainer.type]: SvtVideoEditorContainer.initialData,
};

const ATTACHMENTS = {
  disaster: [
    {
      type: ImageEditorContainer.type,
      text: 'Bild',
    },
  ],
  adminOrEditor: [
    {
      type: ImageEditorContainer.type,
      text: 'Bild',
    },
    {
      type: SvtVideoEditorContainer.type,
      text: 'Video',
    },
    {
      type: WebpageEditorContainer.type,
      text: 'Artikel',
    },
  ],
  guest: [
    {
      type: ImageEditorContainer.type,
      text: 'Lägg till bild',
    },
  ],
};

const NewAttachmentSelector = () => {
  const { isAdminOrEditor } = useContext(UserContext);
  const { isDisasterStream } = useDisasterStream();

  let attachmentType;

  if (isDisasterStream) {
    attachmentType = 'disaster';
  } else if (isAdminOrEditor) {
    attachmentType = 'adminOrEditor';
  } else {
    attachmentType = 'guest';
  }

  const attachmentButtons = ATTACHMENTS[attachmentType];

  const { dispatcher: postEditorDispatcher } = useContext(PostEditorContext);

  const createAttachmentOfType = (type) => {
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: initialDataMap[type],
    });
  };

  return attachmentButtons.map((attachmentButton) => (
    <Button
      className={s.attachmentButton}
      key={attachmentButton.type}
      variant="secondary"
      onClick={() => createAttachmentOfType(attachmentButton.type)}
      disabled={attachmentButton.disabled}
    >
      {attachmentButton.text}
    </Button>
  ));
};

export default NewAttachmentSelector;
