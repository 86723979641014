import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Timestamp from '../Timestamp';
import s from './UsersTable.module.scss';

export default function UsersTableRow({ user }) {
  if (!user) {
    return null;
  }

  const mLastRefreshTokenCreatedAt = moment(user.lastRefreshTokenCreatedAt);
  const mLatestLoginAt = moment(user.latestLoginAt);

  return (
    <React.Fragment>
      <tr key={user.id}>
        <td className={s.idCell}>{user.id}</td>
        <td className={s.avatar}>
          {user.avatar ? (
            <div className={s.avatarImageContainer}>
              <img
                src={process.env.REACT_APP_AVATAR_BASE_URL + user.avatar.path}
                alt={`${user.displayName} bylinebild`}
                className={s.avatarImage}
              />{' '}
            </div>
          ) : (
            ''
          )}
        </td>
        <td className={s.displayNameCell}>{user.displayName}</td>
        <td className={s.titleCell}>{user.title}</td>
        <td className={s.emailCell}>{user.email}</td>
        <td className={s.roleCell}>{user.role}</td>
        <td className={s.lastRefreshTokenCreatedAtCell}>
          {mLastRefreshTokenCreatedAt.isValid() && (
            <Timestamp mDate={mLastRefreshTokenCreatedAt} variant="iso" />
          )}
        </td>
        <td className={s.latestLoginAt}>
          {mLatestLoginAt.isValid() && (
            <Timestamp mDate={mLatestLoginAt} variant="iso" />
          )}
        </td>
        <td className={s.loginDurationMs}>
          {user.loginDurationMs && (
            <span>{moment.duration(user.loginDurationMs).humanize()}</span>
          )}
        </td>
        <td>{user.adGroupNames}</td>
        <td>
          <a
            className={s.link}
            href={`https://eur.delve.office.com/?p=${user.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Länk
          </a>
        </td>
      </tr>
    </React.Fragment>
  );
}

UsersTableRow.propTypes = {
  user: PropTypes.object,
};
