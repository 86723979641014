import { FC, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';

import s from './ConfirmDialog.module.scss';

import Button from 'components/Button/Button';

type ConfirmDialogProps = {
  open: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  title: string;
  cancelText?: string;
  confirmText: string | string[];
  children: ReactNode;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  title,
  cancelText = 'Avbryt',
  confirmText,
  children,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <div className={s.root}>
      <h2 className={s.title}>{title}</h2>

      <p>{children}</p>

      <div className={s.actions}>
        <Button onClick={onCancel} variant="secondary" autoFocus>
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          variant="primary"
          data-testid="dialogConfirmButton"
        >
          {confirmText}
        </Button>
      </div>
    </div>
  </Dialog>
);

export default ConfirmDialog;
