import { useContext, useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import NotificationContext from 'contexts/NotificationContext';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import MissingADGroupError from 'errors/MissingADGroupError';

const IncomingJwtPage = () => {
  const { loginWithJwt, setProfileDialogVisible } = useContext(UserContext);
  const { flashNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { token } = queryString.parse(search);

  useEffect(() => {
    const doLogin = async () => {
      try {
        await loginWithJwt({ token });

        setProfileDialogVisible(true);
        navigate(
          encodeURI(sessionStorage.getItem('loginTarget') || '/streams')
        );
        sessionStorage.removeItem('loginTarget');
      } catch (error) {
        let message = 'Inloggningen misslyckades. Försök igen.';
        if (error instanceof MissingADGroupError) {
          message =
            'Du saknar rätt behörighet för Direktcenter. Kontakta din chef.';
        }
        flashNotification({
          type: 'error',
          message,
        });
        navigate('/login');
      }
    };
    doLogin();
  }, [
    flashNotification,
    loginWithJwt,
    navigate,
    setProfileDialogVisible,
    token,
  ]);

  return (
    <PageContent>
      <PageMainContainer loading={true}></PageMainContainer>
    </PageContent>
  );
};

export default IncomingJwtPage;
