import cn from 'classnames';
import s from './Loader.module.scss';

type LoaderProps = {
  absoluteCenter?: boolean;
  padding?: boolean;
  children?: string | JSX.Element;
};

const Loader = ({ absoluteCenter, padding = true, children }: LoaderProps) => (
  <span
    className={cn(s.root, {
      [s.absoluteCenter]: absoluteCenter,
      [s.padding]: padding,
    })}
  >
    <div className={s.spinnerWrapper}>
      <div className={s.spinner}>{!children && 'Laddar...'}</div>
    </div>
    {children && <div className={s.text}>{children}</div>}
  </span>
);

export default Loader;
