const isDev = process.env.NODE_ENV === 'development';
const noop = () => {};

const logger = {
  error: isDev ? console.error : noop,
  log: isDev ? console.log : noop,
};

// TODO: Add more logging

export default logger;
