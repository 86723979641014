import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import s from './PauseIndicator.module.scss';

const pauseStateLabelMap = {
  MANUALLY_PAUSED: 'Pausat',
  AUTO_PAUSED: 'Autopausat',
  ACTIVE: 'Aktivt',
  INACTIVE: 'Stängt',
};

const PauseIndicator = ({ pauseState, onClick, disabled }) => {
  const rootClasses = cn(s.root, {
    [s.manuallyPaused]: pauseState === 'MANUALLY_PAUSED',
    [s.autoPaused]: pauseState === 'AUTO_PAUSED',
    [s.active]: pauseState === 'ACTIVE',
    [s.inactive]: pauseState === 'INACTIVE',
  });
  const PauseIcon = (
    <div className={s.pauseIcon}>
      <div className={s.pauseIconLine1} />
      <div className={s.pauseIconLine2} />
    </div>
  );
  const ActiveIcon = (
    <div className={s.activeIcon}>
      <div className={s.activeIconBall1} />
      <div className={s.activeIconBall2} />
    </div>
  );
  const InactiveIcon = (
    <div className={s.inactiveIcon}>
      <div className={s.inactiveIconIcon} />
    </div>
  );

  const getIconForState = (satte) => {
    switch (satte) {
      case 'ACTIVE':
        return ActiveIcon;
      case 'INACTIVE':
        return InactiveIcon;
      default:
        return PauseIcon;
    }
  };

  return (
    <button disabled={disabled} onClick={onClick} className={rootClasses}>
      <div className={s.label}>{pauseStateLabelMap[pauseState]}</div>
      {getIconForState(pauseState)}
    </button>
  );
};

PauseIndicator.propTypes = {
  pauseState: PropTypes.oneOf([
    'MANUALLY_PAUSED',
    'AUTO_PAUSED',
    'ACTIVE',
    'INACTIVE',
  ]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

export default PauseIndicator;
