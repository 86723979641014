import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import NotificationContext from '../../contexts/NotificationContext';
import api from '../../services/api';
import PendingPost from './PendingPost';
import { NONE, EDITING, REPLYING } from 'utils/pendingPostInteractiveStates';
import AnimateIn from 'components/AnimateIn/AnimateIn';

const PendingPostContainer = ({
  streamId,
  post,
  isActive,
  streamHasGuests,
  interactiveState,
  setInteractiveState,
}) => {
  const [loading, setLoading] = useState(false);
  const [isChangingApprovedForGuest, setIsChangingApprovedForGuest] =
    useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { flashError } = useContext(NotificationContext);

  useEffect(() => {
    return () => {
      setInteractiveState(NONE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsChangingApprovedForGuest(false);
  }, [post.isApprovedForGuest]);

  async function publish() {
    setIsVisible(false);
    const response = await api.pendingPostPublish(streamId, post.id);
    if (response.status !== 'success') {
      flashError(
        'Misslyckades med att publicera inlägget. Kontrollera din anslutning och försök igen.'
      );
      setIsVisible(true);
    }
  }

  async function publishWithReply(reply) {
    setIsVisible(false);
    setInteractiveState(NONE);

    const response = await api.pendingPostPublish(streamId, post.id, {
      body: reply.body,
      author: reply.author,
    });

    if (response.status !== 'success') {
      setInteractiveState(REPLYING);

      flashError(
        'Misslyckades med att publicera inlägget. Kontrollera din anslutning och försök igen.'
      );
      setIsVisible(true);
    }
  }

  async function update(post) {
    setLoading(true);
    setInteractiveState(NONE);

    const response = await api.pendingPostUpdate(streamId, post.id, {
      text: post.text,
      authorName: post.authorName,
    });
    if (response.status !== 'success') {
      setInteractiveState(EDITING);

      flashError(
        'Misslyckades med att uppdatera inlägget. Kontrollera din anslutning och försök igen.'
      );
    }
    setLoading(false);
  }

  async function remove() {
    setIsVisible(false);
    const response = await api.pendingPostDecline(streamId, post.id);
    if (response.status !== 'success') {
      flashError(
        'Misslyckades med att ta bort inlägget. Kontrollera din anslutning och försök igen.'
      );
      setIsVisible(true);
    }
  }

  async function reportAnnoying() {
    setIsVisible(false);
    const response = await api.pendingPostReportAnnoying(streamId, post.id);
    if (response.status !== 'success') {
      flashError(
        'Misslyckades med att rapportera inlägget. Kontrollera din anslutning och försök igen.'
      );
      setIsVisible(true);
    }
  }

  async function reportThreat() {
    setIsVisible(false);
    const response = await api.pendingPostReportThreat(streamId, post.id);
    if (response.status !== 'success') {
      flashError(
        'Misslyckades med att rapportera inlägget. Kontrollera din anslutning och försök igen.'
      );
      setIsVisible(true);
    }
  }

  async function setIsApprovedForGuest(isApprovedForGuest) {
    setIsChangingApprovedForGuest(true);
    const response = await api.pendingPostSetIsApprovedForGuest(
      streamId,
      post.id,
      isApprovedForGuest
    );
    const failureMessage = isApprovedForGuest
      ? 'skicka till gäst'
      : 'dölja för gäst';
    if (response.status !== 'success') {
      flashError(
        `Misslyckades med att ${failureMessage}. Kontrollera din anslutning och försök igen.`
      );
      setIsChangingApprovedForGuest(false);
    }
  }

  return (
    <AnimateIn isVisible={isVisible} animateInOnMount={true}>
      <PendingPost
        post={post}
        loading={loading}
        publish={publish}
        publishWithReply={publishWithReply}
        update={update}
        remove={remove}
        reportAnnoying={reportAnnoying}
        reportThreat={reportThreat}
        setIsApprovedForGuest={setIsApprovedForGuest}
        isChangingApprovedForGuest={isChangingApprovedForGuest}
        isActive={isActive}
        streamHasGuests={streamHasGuests}
        interactiveState={interactiveState}
        setInteractiveState={setInteractiveState}
      />
    </AnimateIn>
  );
};

PendingPostContainer.propTypes = {
  streamId: PropTypes.string.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isApprovedForGuest: PropTypes.bool,
    text: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  streamHasGuests: PropTypes.bool.isRequired,
  interactiveState: PropTypes.string.isRequired,
  setInteractiveState: PropTypes.func.isRequired,
};

export default PendingPostContainer;
