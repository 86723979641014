const useTodaysBackgroundImage = () => {
  const backgroundImages = [
    {
      path: '/login_background_fotboll.jpg',
      copyright: 'Pontus Lundahl/TT',
    },
    {
      path: '/login_background_loreen.jpg',
      copyright: 'Christine Olsson/TT',
    },
    {
      path: '/login_background_algvandringen.jpg',
      copyright: 'SVT BILDARKIV SVTB',
    },
    {
      path: '/login_background_skidor.jpg',
      copyright: 'Anders Wiklund/TT',
    },
    {
      path: '/login_background_musikhjalpen.jpg',
      copyright: 'Björn Larsson Rosvall/TT',
    },
    {
      path: '/login_background_nordstream.jpg',
      copyright: 'Kustbevakningen/TT',
    },
    {
      path: '/login_background_drottninggatan.jpg',
      copyright: 'Johan Nilsson/TT',
    },
  ];

  const dayOfMonth = new Date().getDate();
  const todaysImageIndex = dayOfMonth % backgroundImages.length;

  return {
    url:
      process.env.REACT_APP_IMAGE_BASE_URL +
      backgroundImages[todaysImageIndex].path,
    copyright: backgroundImages[todaysImageIndex].copyright,
  };
};

export default useTodaysBackgroundImage;
