import React from 'react';

import Module from 'components/Module/Module';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

function NotFoundPage() {
  return (
    <PageContent>
      <PageMainContainer>
        <PageTitle>Sidan kunde inte hittas</PageTitle>

        <Module>
          <p>Sidan kunde inte hittas (404)</p>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
}

export default NotFoundPage;
