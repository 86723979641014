import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as RemoveIcon } from '../img/trash_icon.svg';
import moment from 'moment';

// Styleguide specific
import Item from './Item/Item';
import Header from './Header/Header';
import Description from './Description/Description';
import Color from './Color/Color';
import s from './Styleguide.module.scss';
import {
  sampleImage,
  getPostEditorContext,
  getSampleStream,
  getSamplePost,
  getSamplePendingPost,
  getSampleReply,
} from './mockData';

// Contexts
import { NotificationContextProvider } from '../contexts/NotificationContext';
import { PostEditorContext } from '../components/PostEditor/PostEditorStore';

// Context mocks
import ErrorContextProviderMock from '../contexts/mocks/ErrorContextProviderMock';
import NotificationContextProviderMock from '../contexts/mocks/NotificationContextProviderMock';
import UserContextProviderMock from '../contexts/mocks/UserContextProviderMock';

// Components
import TextArea from '../components/TextArea/TextArea';
import Input from '../components/Input/Input';
import Button from '../components/Button/Button';
import Loader from '../components/Loader/Loader';
import Switch from '../components/Switch/Switch';
import MoreActions from '../components/MoreActions/MoreActions';
import MenuDropdown from '../components/MenuDropdown/MenuDropdown';
import AppBar from '../components/AppBar/AppBar';
import AttachmentImage from '../components/Attachment/AttachmentImage';
import AttachmentWebpage from '../components/Attachment/AttachmentWebpage';
import AttachmentVideo from '../components/Attachment/AttachmentVideo';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import CopyToClipboardField from '../components/CopyToClipboardField/CopyToClipboardField';
import LoadMoreButton from '../components/LoadMoreButton/LoadMoreButton';
import Notification from '../components/Notification/Notification';
import PendingPost from '../components/PendingPost/PendingPost';
import Post from '../components/Post/Post';
import Reply from '../components/Post/Reply/Reply';
import PostEditorContainer from '../components/PostEditor/PostEditorContainer';
import AttachmentEditor from '../components/PostEditor/AttachmentEditor/AttachmentEditor';
import ImageEditor from '../components/PostEditor/AttachmentEditor/ImageEditor/ImageEditor';
import SvtVideoEditor from '../components/PostEditor/AttachmentEditor/SvtVideoEditor/SvtVideoEditor';
import WebpageEditor from '../components/PostEditor/AttachmentEditor/WebpageEditor/WebpageEditor';
import UpdateStreamEditor from '../components/StreamEditor/UpdateStreamEditor';
import CreateStreamEditor from '../components/StreamEditor/CreateStreamEditor';
import StreamMeta from '../components/StreamMeta/StreamMeta';
import StreamTable from '../components/StreamTable/StreamTable';
import NoItemsNotice from '../components/NoItemsNotice/NoItemsNotice';
import Tooltip from '../components/Tooltip/Tooltip';
import Timestamp from '../components/Timestamp';

import { VISITOR } from '../utils/roles';
import Select from 'components/Select/Select';

const ContextProviderMocks = ({ children }) => (
  <ErrorContextProviderMock>
    <NotificationContextProviderMock>
      <UserContextProviderMock>{children}</UserContextProviderMock>
    </NotificationContextProviderMock>
  </ErrorContextProviderMock>
);

ContextProviderMocks.propTypes = {
  children: PropTypes.elementType,
};

const StyleguidePage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  return (
    <ContextProviderMocks>
      <div className={s.root}>
        <h1 className={s.title}>Direktcenter styleguide</h1>
        <Item title="Färger">
          <div>
            <Color hex="#333">$black: #333;</Color>
          </div>
          <br />
          <div>
            <Color hex="#fff">$white: #fff;</Color>
          </div>
          <br />
          <br />
          <div>
            <Color hex="#f0f0f0">$graySuperLight: #f0f0f0;</Color>
            <Color hex="#ebebeb">$grayExtraLight: #ebebeb;</Color>
            <Color hex="#ccc">$grayLight: #ccc;</Color>
            <Color hex="#8c8c8c">$gray: #8c8c8c;</Color>
          </div>
          <br />
          <br />
          <div>
            <Color hex="#ffdc8c">$yellowLight: #ffdc8c;</Color>
          </div>
          <br />
          <br />
          <div>
            <Color hex="#2d9b91">$darkTurquoise: #2d9b91;</Color>
            <Color hex="#197869">$extraDarkTurquoise: #197869;</Color>
          </div>
          <br />
          <br />
          <div>
            <Color hex="#ffd2d7">$redExtraLight: #ffd2d7;</Color>
            <Color hex="#f0828c">$redLight: #f0828c;</Color>
            <Color hex="#e13241">$red: #e13241;</Color>
            <Color hex="#b9232d">$redDark: #b9232d;</Color>
            <Color hex="#ff5b51">$redDirektcenter: #ff5b51;</Color>
          </div>
          <br />
          <br />
          <div>
            <Color hex="#32a532">$green: #32a532;</Color>
            <Color hex="#23821e">$greenDark: #23821e;</Color>
          </div>
        </Item>

        <Item title="Typografi">
          <Description>
            <p>
              Beskrivning här? Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Illo quibusdam totam nesciunt deserunt doloribus
              rem expedita, quam accusantium quisquam ab doloremque hic possimus
              eum accusamus eius error tenetur aliquam. Illum.
            </p>
          </Description>

          <Header>Rubriker</Header>
          <h1>{'<h1> Rubrik'}</h1>
          <h2>{'<h2> Rubrik'}</h2>
          <h3>{'<h3> Rubrik'}</h3>
          <h4>{'<h4> Rubrik'}</h4>
          <h5>{'<h5> Rubrik'}</h5>
          <h6>{'<h6> Rubrik'}</h6>

          <Header>Brödtext</Header>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed diam
            nulla, rhoncus ac convallis at, accumsan et massa. Sed felis neque,
            posuere eget libero at, semper iaculis libero. Nulla porttitor enim
            diam, in fermentum felis convallis at. Curabitur venenatis sed
            mauris sagittis rutrum. Curabitur dictum venenatis elit, et pharetra
            nulla mollis eu. Suspendisse auctor sollicitudin ex vitae
            condimentum. Nulla dictum, diam ut faucibus malesuada, ligula augue
            venenatis massa, et consequat ipsum purus at neque. Phasellus
            vestibulum nisl vitae egestas laoreet.
          </p>
          <p>
            Suspendisse potenti. Suspendisse mi dolor, vulputate non ultricies
            sit amet, scelerisque at est. Praesent non varius lorem. Curabitur
            pretium euismod mattis. Pellentesque vitae ligula a eros hendrerit
            porttitor et vel erat. Mauris dapibus leo eget neque lacinia, id
            fermentum sapien ornare. Phasellus ultrices magna in ligula rhoncus
            euismod. Quisque viverra suscipit mi vel molestie. Cras quis lacinia
            mauris. Sed sem nisl, posuere ut eros id, aliquet convallis tortor.
            Curabitur suscipit eros sed est posuere, non accumsan metus viverra.
          </p>

          <Header>Länkar</Header>
          <a href="https://svt.se">https://www.svt.se</a>

          <Header>Listor</Header>
          <Description>{'<ul> Onumrerad lista'}</Description>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Consectetur adipiscing elit</li>
            <li>Integer molestie lorem at massa</li>
            <li>Facilisis in pretium nisl aliquet</li>
            <li>
              Nulla volutpat aliquam velit
              <ul>
                <li>Phasellus iaculis neque</li>
                <li>Purus sodales ultricies</li>
                <li>Vestibulum laoreet porttitor sem</li>
                <li>Ac tristique libero volutpat at</li>
              </ul>
            </li>
            <li>Faucibus porta lacus fringilla vel</li>
            <li>Aenean sit amet erat nunc</li>
            <li>Eget porttitor lorem</li>
          </ul>

          <Description>{'<ol> Numrerad lista'}</Description>
          <ol>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Consectetur adipiscing elit</li>
            <li>Integer molestie lorem at massa</li>
            <li>Facilisis in pretium nisl aliquet</li>
            <li>Nulla volutpat aliquam velit</li>
            <li>Faucibus porta lacus fringilla vel</li>
            <li>Aenean sit amet erat nunc</li>
            <li>Eget porttitor lorem</li>
          </ol>
        </Item>

        <Item title="Marginaler och padding">
          <Description>
            <ul>
              <li>
                När det är praktiskt så arbetar vi med marginaler i multipler av{' '}
                <code>10px</code>/<code>0.625rem</code>
              </li>
              <li>
                <code>margin-bottom</code> föredras framför{' '}
                <code>margin-top</code> för att justera avstånd mellan element
              </li>
            </ul>
          </Description>
        </Item>

        <Item title="Fields">
          <TextArea label="Sample textarea" />
          <TextArea
            label="Sample textarea with error"
            error="This is the error!"
          />
          <Input label="Samle text input" type="text" />
          <Input
            label="Readonly input"
            readOnly
            type="text"
            value="Some text"
          />
          <Input
            label="Samle text input with error"
            type="text"
            error="This is the error!"
          />
          <Select
            onChange={(value) => {
              console.log(value);
            }}
            label={'Sample select'}
            required={false}
          >
            <>
              <option value="defaultValue">Default value</option>
              <optgroup label="Option group">
                <option key="option-1" value="option-1">
                  Option 1
                </option>
                <option key="option-2" value="option-2">
                  Option 2
                </option>
                <option key="option-3" value="option-3">
                  Option 3
                </option>
              </optgroup>
              <optgroup label="Option group 2">
                <option key="option-4" value="option-4">
                  Option 4
                </option>
                <option key="option-5" value="option-5">
                  Option 5
                </option>
                <option key="option-6" value="option-6">
                  Option 6
                </option>
              </optgroup>
            </>
          </Select>
          <Switch onLabel="Pågående" offLabel="Avslutad" labelWidth={75} />
        </Item>

        <Item title="Buttons">
          <Button variant="primary">Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="light">Light</Button>
          <Button variant="dark">Dark</Button>
          <Button variant="danger">Danger</Button>
          <Button variant="secondary" component={Link} to="#">
            As link
          </Button>
          <Button variant="secondary" disabled>
            Disabled
          </Button>
          <Button disabled variant="primary">
            Disabled primary
          </Button>
          <Button round variant="primary">
            Some text
            <RemoveIcon />
          </Button>
          <Button variant="primary">
            <RemoveIcon />
            Some text
          </Button>
          <Button variant="primary" tooltip="This is tooltip">
            With tooltip (hover me!)
          </Button>
          <Button variant="primary" big tooltip="This is tooltip">
            Big button
          </Button>
          <Button variant="primary" fullWidth tooltip="This is tooltip">
            Full width
          </Button>
        </Item>

        <Item title="Tooltip">
          <Tooltip>This is a tooltip</Tooltip>
        </Item>

        <Item title="Timestamp">
          <Timestamp mDate={moment()} variant="svt" />
        </Item>

        <Item title="Loader">
          <Loader />
          <Loader>Loading foo bar baz...</Loader>
          <div className={s.loaderAbsolute}>
            Loader centered with position absolute
            <Loader absoluteCenter />
          </div>
          <div className={s.loaderAbsolute}>
            Loader centered with position absolute
            <Loader absoluteCenter>Loading foo bar baz...</Loader>
          </div>
        </Item>

        <Item title="MenuDropdown">
          <MenuDropdown
            actions={[
              {
                title: 'Some action',
                callback: () => alert('You clicked "Some action"!'),
              },
              {
                title: 'Some really long other action',
                callback: () =>
                  alert('You clicked "Some really long other action"!'),
              },
            ]}
            isOpen
          />
        </Item>

        <Item title="MoreActions">
          <div className={s.moreActionsWrapper}>
            <div className={s.moreActions}>
              <MoreActions
                id="more-actions-9a4eae2acc25499196161ca14993fd03"
                buttonLabel="My actions"
                actions={[
                  {
                    title: 'Some action',
                    callback: () => alert('You clicked "Some action"!'),
                  },
                  {
                    title: 'Some really long other action',
                    callback: () =>
                      alert('You clicked "Some really long other action"!'),
                  },
                ]}
              />
            </div>
          </div>
        </Item>

        <Item title="AppBar">
          <Header>Inloggad</Header>
          <AppBar user={{ displayName: 'John Doe' }} logout={() => {}} />
          <Header>Ej inloggad</Header>
          <AppBar user={null} logout={() => {}} />
        </Item>

        <Item title="AttachmentImage" slim>
          <AttachmentImage
            attachment={{
              type: 'image',
              alt: 'Boll på plankgolv',
              caption:
                'En ensam bolls memoarer: "Det är inte lätt att vara rund',
              path: sampleImage,
            }}
          />
        </Item>

        <Item title="AttachmentWebpage" slim>
          <AttachmentWebpage
            attachment={{
              type: 'webpage',
              title:
                'Nadal till ny kvartsfinal: ”Känslorna är omöjliga att beskriva”',
              url: 'https://www.svt.se/sport/tennis/nadal-klar-for-ny-kvartsfinal',
              image: sampleImage,
            }}
          />
        </Item>

        <Item title="AttachmentVideo" slim>
          <AttachmentVideo
            attachment={{
              type: 'svt-video',
              videoId: '20887304',
              posterImageId: '20958300',
            }}
          />
        </Item>

        <Item title="ConfirmDialog">
          <button onClick={() => setDialogOpen(true)}>
            Klicka för att öppna
          </button>
          <ConfirmDialog
            open={dialogOpen}
            onCancel={() => setDialogOpen(false)}
            onConfirm={() => setDialogOpen(false)}
            title="Är du säker på att du vill gå vidare?"
            cancelText="Nej, avbryt"
            confirmText="Ja, gå vidare"
            children="Detta är en demo av vår dialogruta. Här kan det stå en beskrivande text om vad du vill ha bekräftat."
          />
        </Item>

        <Item title="CopyToClipboardField">
          <CopyToClipboardField value="123" buttonText="Kopiera id" />
        </Item>

        <Item title="LoadMoreButton">
          <LoadMoreButton onClick={() => {}} />
        </Item>

        <Item title="NoItemsNotice" slim gray>
          <NoItemsNotice>Inga inlägg</NoItemsNotice>
        </Item>

        <Item title="Notification">
          <button onClick={() => setShowNotification('success')}>
            Visa success-meddelande
          </button>
          <button onClick={() => setShowNotification('error')}>
            Visa error-meddelande, flera punkter
          </button>

          {showNotification === 'error' && (
            <Notification
              message={[
                'Detta är ett meddelande om att något gått dåligt',
                'Och det är flera saker som gått åt skogen',
                'Även detta',
              ]}
              type={showNotification}
              onClose={() => setShowNotification(null)}
              closing={false}
            />
          )}
          {showNotification === 'success' && (
            <Notification
              message="Detta är ett meddelande om att något gått bra"
              type={showNotification}
              onClose={() => setShowNotification(null)}
              closing={false}
            />
          )}
        </Item>
        <Item title="PendingPost" slim>
          <Header>Utan knappar (flöde inaktivt)</Header>
          <PendingPost
            {...getSamplePendingPost({
              loading: false,
              isReplying: false,
              isActive: false,
            })}
          />
          <Header>Med knappar (flöde aktivt)</Header>
          <PendingPost
            {...getSamplePendingPost({
              loading: false,
              isReplying: false,
              isActive: true,
            })}
          />
          <Header>Håller på att svara</Header>
          <PendingPost
            {...getSamplePendingPost({
              loading: false,
              isReplying: true,
              isActive: true,
            })}
          />
          <Header>Laddar</Header>
          <PendingPost
            {...getSamplePendingPost({
              loading: true,
              isReplying: true,
              isActive: true,
            })}
          />
        </Item>
        <Item title="Post" gray slim>
          <Header>Post från redaktör</Header>
          <Post {...getSamplePost()} />

          <Header>Post med svar</Header>
          <Post
            {...getSamplePost({
              includeReplies: true,
            })}
          />

          <Header>Post från besökare</Header>
          <Post
            {...getSamplePost({
              userRole: VISITOR,
              body: '<p>Vilken himla stjärna hon är! Tusen tack Johaug!!!',
            })}
          />

          <Header>Post från besökare med svar</Header>
          <Post
            {...getSamplePost({
              userRole: VISITOR,
              body: '<p>Vilken himla stjärna hon är! Tusen tack Johaug!!!',
              includeReplies: true,
            })}
          />
          <Header>Pinnad post</Header>
          <Post
            {...getSamplePost({
              pinned: true,
            })}
          />
          <Header>Ström inaktiv</Header>
          <Post
            {...getSamplePost({
              isEditable: false,
            })}
          />
          <Header>Redigerar</Header>
          <Post
            {...getSamplePost({
              isEditing: true,
            })}
          />
          <Header>Redigerar med svar</Header>
          <Post
            {...getSamplePost({
              isEditing: true,
              includeReplies: true,
            })}
          />
          <Header>Svarar</Header>
          <Post
            {...getSamplePost({
              isReplying: true,
            })}
          />
          <Header>Svarar med svar</Header>
          <Post
            {...getSamplePost({
              isReplying: true,
              includeReplies: true,
            })}
          />
        </Item>

        <Item title="Reply" slim>
          <Header>Redaktörssvar</Header>
          <Reply {...getSampleReply()} />
          <Header>Redigerar</Header>
          <Reply {...getSampleReply({ isEditing: true })} />
        </Item>

        <Item title="PostEditor" slim>
          <Header>Vanlig</Header>
          <PostEditorContainer
            onSubmit={() => {}}
            submitText="Spara"
            onCancel={() => {}}
            cancelText="Avbryt"
            title="Titel på editor"
            bigSubmit={false}
          />

          <Header>Med stor publiceraknapp</Header>
          <PostEditorContainer
            onSubmit={() => {}}
            submitText="Publicera"
            onCancel={null}
            cancelText={null}
            title={null}
            bigSubmit={true}
          />
        </Item>

        <Item title="AttachmentEditor" slim>
          <Header>Val av attachment</Header>
          <PostEditorContext.Provider {...getPostEditorContext()}>
            <AttachmentEditor />
          </PostEditorContext.Provider>
        </Item>

        <Item title="ImageEditor" slim>
          <Description>Innan uppladding</Description>
          <ImageEditor
            isUploadingImage={false}
            attachment={{ path: '', alt: '', caption: '' }}
            handleFileUpload={() => {}}
            mergeIntoAttachment={() => {}}
          />

          <Description>Under uppladding</Description>
          <ImageEditor
            isUploadingImage={true}
            attachment={{ path: '', alt: '', caption: '' }}
            handleFileUpload={() => {}}
            mergeIntoAttachment={() => {}}
          />

          <Description>Har laddats upp</Description>
          <ImageEditor
            isUploadingImage={false}
            attachment={{
              path: sampleImage,
              alt: 'Alt texten här',
              caption: 'Ensam bol är ensam',
            }}
            setIsUploadingImage={() => {}}
            handleFileUpload={() => {}}
            mergeIntoAttachment={() => {}}
          />
        </Item>

        <Item title="SvtVideoEditor" slim>
          <Description>Innan man fyller i</Description>
          <SvtVideoEditor
            inputVideoId=""
            handleVideoIdChange={() => {}}
            attachment={null}
            loadingVideoData={false}
          />

          <Description>Laddar videodata</Description>
          <SvtVideoEditor
            inputVideoId="123"
            handleVideoIdChange={() => {}}
            attachment={null}
            loadingVideoData={true}
          />

          <Description>Korrekt ifylld id</Description>
          <SvtVideoEditor
            inputVideoId="20887304"
            handleVideoIdChange={() => {}}
            attachment={{
              type: 'svt-video',
              videoId: '20887304',
              posterImageId: '20958300',
            }}
          />

          <Description>Felaktigt ifylld id</Description>
          <SvtVideoEditor
            inputVideoId="123"
            handleVideoIdChange={() => {}}
            attachment={null}
            inputError="Felmeddelande var det här"
          />
        </Item>

        <Item title="WebpageEditor" slim>
          <Description>Ej ifylld</Description>
          <WebpageEditor
            inputUrl=""
            handleUrlChange={() => {}}
            attachment={null}
            loadingOgData={false}
          />

          <Description>Laddar OG-data</Description>
          <WebpageEditor
            inputUrl="https://svt.se"
            handleUrlChange={() => {}}
            attachment={null}
            loadingOgData={true}
          />

          <Description>Korrekt ifylld</Description>
          <WebpageEditor
            inputUrl="https://svt.se"
            handleUrlChange={() => {}}
            attachment={{
              type: 'webpage',
              title: 'En fin artikel',
              url: 'https://svt.se',
              description: 'En fin beskrivning',
              image: sampleImage,
            }}
            loadingOgData={false}
          />

          <Description>Fel url</Description>
          <WebpageEditor
            inputUrl="Detta är inte en url!"
            handleUrlChange={() => {}}
            attachment={null}
            loadingOgData={false}
            inputError="Här är ett felmeddelande"
          />
        </Item>

        <Item title="StreamEditor">
          <Header>Skapa ny ström</Header>
          <CreateStreamEditor
            onCancel={() => {}}
            onCreate={() => {}}
            loading={false}
          />
          <Header>Uppdatera befintlig ström</Header>
          <UpdateStreamEditor
            stream={{ title: 'Min fina ström', isActive: false }}
            onCreate={() => {}}
            onCancel={() => {}}
            onUpdate={() => {}}
            onDelete={() => {}}
            loading={false}
          />
        </Item>

        <Item title="StreamMeta">
          <NotificationContextProvider>
            <StreamMeta
              stream={{ title: 'Min fina ström', isActive: true }}
              streamId="123"
              onStreamDeleted={() => {}}
            />
          </NotificationContextProvider>
        </Item>

        <Item title="StreamTable">
          <StreamTable
            streams={[
              getSampleStream({ title: 'En ström' }),
              getSampleStream({ title: 'Två ström' }),
              getSampleStream({
                title: 'En ström som är inaktiv',
                isActive: false,
              }),
              getSampleStream({ title: 'Strömström!' }),
            ]}
          />
        </Item>
      </div>
    </ContextProviderMocks>
  );
};

export default StyleguidePage;
