import { collection, doc, onSnapshot } from 'firebase/firestore';
import getInitializedFirestore from '../../utils/getInitializedFirestore';

const firestore = getInitializedFirestore();

const noop = () => {};

const StreamMeta = ({ streamId }) => {
  let state = {
    onUpdate: null,
    onError: null,
    unsubscribeFirestoreListener: null,
  };

  const _unsubscribeFirestoreListener = () => {
    state.unsubscribeFirestoreListener && state.unsubscribeFirestoreListener();
  };

  const _firestoreDateToIsoDate = (firestoreDate) => {
    if (!firestoreDate) {
      return undefined;
    }
    return firestoreDate.toDate().toISOString();
  };

  const _snapshotToStreamData = (snapshot) => {
    const snapshotData = snapshot.data();
    const {
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      liveBroadcast,
      title,
      createdAt,
      parentSection,
      section,
    } = snapshotData;

    return {
      isActive,
      isVisitorPostingEnabled: isVisitorPostingEnabled === false ? false : true,
      isHighlightsBoxVisible: isHighlightsBoxVisible === false ? false : true,
      liveBroadcast,
      title,
      createdAt: _firestoreDateToIsoDate(createdAt),
      parentSection,
      section,
    };
  };

  const _setupFirestoreListener = () => {
    _unsubscribeFirestoreListener();
    const streamDoc = doc(collection(firestore, 'streams'), streamId);
    state.unsubscribeFirestoreListener = onSnapshot(
      streamDoc,
      (snapshot) => {
        if (!snapshot.exists) {
          return state.onError({ notFound: true });
        }
        return state.onUpdate(_snapshotToStreamData(snapshot));
      },
      state.onError
    );
  };

  const listen = (onUpdate, onError = noop) => {
    state.onUpdate = onUpdate;
    state.onError = onError;
    _setupFirestoreListener();
  };

  const unsubscribe = () => {
    state.onUpdate = null;
    state.onError = null;
    _unsubscribeFirestoreListener();
  };

  return {
    listen,
    unsubscribe,
  };
};

export default StreamMeta;
