import React, { HTMLProps, useState } from 'react';

type AlwaysOnTopProps = HTMLProps<HTMLElement> & {
  component: React.ElementType;
};

const AlwaysOnTop = ({ component: Tag, ...props }: AlwaysOnTopProps) => {
  const [isActive, setIsActive] = useState(false);
  const style = isActive ? { zIndex: 100 } : {};
  return (
    <Tag
      style={style}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      {...props}
    />
  );
};

export default AlwaysOnTop;
