import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isKeyHotkey } from 'is-hotkey';

import Avatar from 'components/Avatar/Avatar';
import UserContext from 'contexts/UserContext';
import NotificationContext from 'contexts/NotificationContext';
import Button from '../Button/Button';
import TextEditor from './TextEditor/TextEditor';
import AttachmentEditor from './AttachmentEditor/AttachmentEditor';
import ImageEditorContainer from 'components/PostEditor/AttachmentEditor/ImageEditor/ImageEditorContainer';

import {
  PostEditorContext,
  postEditorActions,
  selectIsSubmitable,
  selectIsTextTooLong,
  selectPost,
  TEXT_MAX_LENGTH,
} from 'components/PostEditor/PostEditorStore';
import uploadImageFile from 'utils/uploadImageFile';
import isOSX from '../../utils/isOSX';
import s from './PostEditor.module.scss';
import { ADMIN, EDITOR } from 'utils/roles';

const isSubmitHotkey = isKeyHotkey('mod+enter');

const PostEditor = ({
  onSubmit,
  onCancel,
  cancelText,
  submitText,
  title,
  bigSubmit,
  isAuthorEditable,
}) => {
  const { state, dispatcher, onResetEditor } = useContext(PostEditorContext);
  const { setProfileDialogVisible, isAdminOrEditor } = useContext(UserContext);

  const { flashNotification } = useContext(NotificationContext);

  const post = selectPost(state);

  const resetPost = () => {
    dispatcher({ type: postEditorActions.RESET_POST_IN_PROGRESS });
  };

  const submit = () => {
    if (selectIsSubmitable(state)) {
      onSubmit(selectPost(state));
      resetPost();
      onResetEditor && onResetEditor();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  const handleKeyDown = (e) => {
    if (isSubmitHotkey(e)) {
      submit();
    }
  };

  const handleEditAuthor = (e) => {
    e.preventDefault();
    setProfileDialogVisible(true);
  };
  const submitDisabled = !selectIsSubmitable(state);
  const textIsTooLong = selectIsTextTooLong(state);
  const showAttachmentEditor =
    state.attachmentEnabled && state.attachmentVisible;

  const onPaste = (event) => {
    if (!showAttachmentEditor) {
      return;
    }
    // Use event.originalEvent.clipboard for newer chrome versions
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    let file = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        file = items[i].getAsFile();
      }
    }

    if (file) {
      dispatcher({
        type: postEditorActions.SET_IS_UPLOADING_IMAGE,
        payload: true,
      });
      dispatcher({
        type: postEditorActions.SET_ATTACHMENT,
        payload: ImageEditorContainer.initialData,
      });
      uploadImageFile(file, flashNotification).then((data) => {
        dispatcher({
          type: postEditorActions.SET_ATTACHMENT,
          payload: { ...data, type: 'image', alt: '', caption: '' },
        });
      });
    }
  };

  return (
    <form onKeyDown={handleKeyDown} onSubmit={handleSubmit} className={s.root}>
      <div className={s.textEditorAndHeader} onPaste={onPaste}>
        {title && <h2 className={s.header}>{title}</h2>}
        <TextEditor />
        {textIsTooLong ? (
          <p className={s.textLengthWarning}>
            Din text är för lång. Maxlängd är {TEXT_MAX_LENGTH} tecken.
          </p>
        ) : null}
      </div>
      <div className={s.byline}>
        {isAdminOrEditor && (
          <Button
            type="button"
            variant="link"
            onClick={handleEditAuthor}
            disabled={!isAuthorEditable}
            className={s.bylineButton}
            title="Redigera byline"
          >
            <Avatar
              role={post.author.role}
              displayName={post.author.displayName}
              avatar={post.author.avatar}
              size="small"
            />
            {post.author.role === ADMIN || post.author.role === EDITOR ? (
              <span className={s.bylineRole}>SVT</span>
            ) : null}
            <span className={s.bylineText}>
              {post.author.displayName}
              {post.author.title && ', '}
              {post.author.title}
            </span>
          </Button>
        )}
        {!isAdminOrEditor && (
          <div className={s.bylineButton}>
            <Avatar
              role={post.author.role}
              displayName={post.author.displayName}
              avatar={post.author.avatar}
              size="small"
            />
            {post.author.role === ADMIN || post.author.role === EDITOR ? (
              <span className={s.bylineRole}>SVT</span>
            ) : null}
            {post.author.displayName}
            {post.author.title && ', '}
            {post.author.title}
          </div>
        )}
      </div>
      {showAttachmentEditor && (
        <div className={s.attachment}>
          <AttachmentEditor />
        </div>
      )}
      <div className={cn(s.actions, { [s.actionsBigSubmit]: bigSubmit })}>
        {onCancel && (
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            className={s.cancelButton}
          >
            {cancelText}
          </Button>
        )}
        <Button
          className={s.submitButton}
          variant="primary"
          type="submit"
          big={bigSubmit}
          disabled={submitDisabled}
          data-testid="postEditorSubmitButton"
          tooltip={`(${isOSX ? '⌘' : 'Ctrl'}+Enter)`}
        >
          {submitText}
        </Button>
      </div>
    </form>
  );
};

PostEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  bigSubmit: PropTypes.bool,
  isAuthorEditable: PropTypes.bool,
};

export default PostEditor;
