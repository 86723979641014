import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ErrorContext = React.createContext();

export const ErrorContextProvider = ({ children }) => {
  const [notFound, setNotFound] = useState(false);
  const [unauthorized, setUnauthorized] = useState(null);
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider
      value={{
        error,
        notFound,
        unauthorized,
        setError,
        setNotFound: () => setNotFound(true),
        unsetNotFound: () => setNotFound(false),
        setUnauthorized,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

ErrorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorContext;
