import React, { useState } from 'react';
import Overlay from 'components/Overlay/Overlay';
import { VISITOR } from 'utils/roles';
import cn from 'classnames';
import s from './Avatar.module.scss';
import AvatarImage from 'components/Avatar/AvatarImage';
import { AvatarImageSize, DirektcenterRoleType } from 'Types';

type AvatarProps = {
  role: DirektcenterRoleType;
  displayName: string;
  avatar: {
    path: string;
  };
  size?: AvatarImageSize;
  darkMode: boolean;
  enablePreview?: boolean;
};

const Avatar = ({
  role,
  displayName,
  avatar,
  size = 'medium',
  darkMode = false,
  enablePreview = false,
}: AvatarProps) => {
  const className = cn(s.root, {
    [s.small]: size === 'small',
  });
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  if (role === VISITOR) {
    return null;
  }

  if (enablePreview) {
    return (
      <>
        <Overlay open={open} onClick={toggleOpen} title="Granska bylinebild">
          <div className={s.previewContainer}>
            <AvatarImage
              displayName={displayName}
              avatar={avatar}
              role={role}
              darkMode={darkMode}
              size="extraLarge"
            />
          </div>
        </Overlay>
        <button className={className} onClick={toggleOpen}>
          <AvatarImage
            displayName={displayName}
            avatar={avatar}
            role={role}
            darkMode={darkMode}
            size={size}
          />
        </button>
      </>
    );
  }

  return (
    <div className={className}>
      <AvatarImage
        displayName={displayName}
        avatar={avatar}
        role={role}
        darkMode={darkMode}
        size={size}
      />
    </div>
  );
};

export default Avatar;
