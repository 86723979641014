import { useState, useEffect } from 'react';

/**
 * Forces a re-render every <seconds> seconds
 * @param {Number} seconds
 */
export default function useForceUpdateInterval(seconds: number) {
  const [, set] = useState(true);
  const forceUpdate = () => set((value) => !value);
  useEffect(() => {
    const secondsIsh = Math.floor(Math.random() * seconds * 10 + 1000) / 100;
    const interval = setInterval(() => {
      forceUpdate();
    }, secondsIsh * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
}
