import { useContext, useMemo } from 'react';

import NotificationContext from 'contexts/NotificationContext';
import NotificationContainer from './NotificationContainer';
import s from './NotificationListContainer.module.scss';

export default function NotificationListContainer() {
  const { notifications } = useContext(NotificationContext);

  const notificationsArray = useMemo(
    () => Array.from(notifications.values()),
    [notifications]
  );

  return (
    <div className={s.root}>
      {notificationsArray.map((notificationMessage) => (
        <NotificationContainer
          key={notificationMessage.id}
          notificationMessage={notificationMessage}
          autoCloseTime={10}
        />
      ))}
    </div>
  );
}
