import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as SettingsIcon } from 'img/kuggis.svg';
import { ReactComponent as GuestIcon } from 'img/guest-icon.svg';
import { ReactComponent as SvtInfo } from 'img/svtinfo.svg';

import useDisasterStream from 'hooks/useDisasterStream';

import Button from 'components/Button/Button';
import ShowForAdminOrEditor from 'components/ShowForAdminOrEditor/ShowForAdminOrEditor';

import s from './_StreamInfo.module.scss';

const StreamInfo = ({
  stream,
  streamId,
  showSettings,
  showGuestSettings,
  guestSettingsEnabled,
  toggleSettings,
  toggleGuestSettings,
  toggleMetadata,
  showMetadataContainer,
}) => {
  const { isDisasterStream } = useDisasterStream();

  const isStreamActive = stream.isActive || isDisasterStream;

  return (
    <div className={s.root}>
      <div className={s.header}>
        <h1 className={s.title}>
          {stream.title}
          {!isStreamActive && ' (inaktiv)'}
        </h1>
      </div>

      <ShowForAdminOrEditor>
        <div className={s.actionButtons}>
          <Button
            className={s.button}
            data-testid="metadataButton"
            onClick={toggleMetadata}
            variant={showMetadataContainer ? 'active' : 'light'}
            round
            tooltip="Relaterat"
          >
            <SvtInfo />
          </Button>

          <Button
            className={cn({
              [s.button]: true,
              [s.isGuestSettingsDisabled]: !guestSettingsEnabled,
            })}
            onClick={toggleGuestSettings}
            variant={showGuestSettings ? 'active' : 'light'}
            round
            tooltip="Hantera gäster"
          >
            <GuestIcon />
          </Button>
          {isStreamActive && (
            <Button
              className={s.button}
              data-testid="streamSettingsButton"
              onClick={toggleSettings}
              variant={showSettings ? 'active' : 'light'}
              round
              tooltip="Inställningar"
            >
              <SettingsIcon />
            </Button>
          )}
        </div>
      </ShowForAdminOrEditor>
    </div>
  );
};

StreamInfo.propTypes = {
  stream: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
  showSettings: PropTypes.bool.isRequired,
  showGuestSettings: PropTypes.bool.isRequired,
  guestSettingsEnabled: PropTypes.bool.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  toggleGuestSettings: PropTypes.func.isRequired,
  streamId: PropTypes.string.isRequired,
  toggleMetadata: PropTypes.func.isRequired,
  showMetadataContainer: PropTypes.bool.isRequired,
};

export default StreamInfo;
