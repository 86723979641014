import { DirektcenterPost } from 'Types';

const DEFAULT_IMAGE_HEADING = 'Bild';
const DEFAULT_VIDEO_HEADING = 'Video';

export default function getFlagForPost(
  body: string | undefined,
  heading: string,
  post: DirektcenterPost
) {
  if (
    !body &&
    post.attachment?.type === 'webpage' &&
    heading === post.attachment.title
  ) {
    return 'Observera att artikelpuffens titel används som rubrik';
  }
  if (
    !body &&
    post.attachment?.type === 'image' &&
    post.attachment?.caption?.length &&
    heading === post.attachment?.caption
  ) {
    return 'Observera att bildtexten används som rubrik';
  }
  if (
    !body &&
    post.attachment?.type === 'image' &&
    heading === DEFAULT_IMAGE_HEADING
  ) {
    return 'Inlägget saknar rubrik, skriv en kort text före bilden';
  }
  if (
    !body &&
    post.attachment?.type === 'svt-video' &&
    heading === DEFAULT_VIDEO_HEADING
  ) {
    return 'Inlägget saknar rubrik, skriv en kort text före videon';
  }
  if (!body && heading.length > 60) {
    return 'Hela inlägget blir en lång rubrik i en mobil, skriv gärna en kort rubrik';
  }
  if (heading.length > 60) {
    return 'Rubriken blir flera rader i en mobil, skriv gärna en kortare rubrik';
  }
  if (!body && !post.attachment) {
    return 'Hela inlägget blir en rubrik';
  }

  return null;
}
