import React, { useContext } from 'react';

import ConnectivityContext from 'contexts/ConnectivityContext';

import s from './ConnectivityDimmer.module.scss';

const ConnectivityDimmer = () => {
  const { offline } = useContext(ConnectivityContext);

  return offline ? <div className={s.dim}></div> : null;
};

export default ConnectivityDimmer;
