import cn from 'classnames';
import { VideoPlayer } from '@svt/videoplayer-react';
//@ts-ignore
import videoPlayerTheme from '@svt/videoplayer-styling/dist/css/default';
import '@svt/videoplayer-styling/dist/css/default.css';
import { DirektcenterVideo } from 'Types';

import s from './AttachmentVideo.module.scss';

// Note: This is a blurry image used as a default when there is no image
// https://www.svtstatic.se/image/custom/1200/19124811/1677067374?format=auto&ratio=1.91
const DEFAULT_IMAGE_ID = '19124811/1677067374';

const DEFAULT_IMAGE_WIDTH = 992;
const IMAGE_PROXY_URL = process.env.REACT_APP_IMAGE_PROXY_URL;
const ASTRID_IMAGE_PROXY_URL = 'https://www.svtstatic.se/image-news';

const generateDefaultPosterUrl = (attachment: DirektcenterVideo) => {
  return generateEscenicPosterUrl({
    ...attachment,
    posterImageId: DEFAULT_IMAGE_ID,
  });
};

const generateEscenicPosterUrl = (attachment: DirektcenterVideo) => {
  if (!attachment.posterImageId) {
    return;
  }
  switch (attachment.aspectRatio) {
    case '1:1':
      return `${IMAGE_PROXY_URL}/square/${DEFAULT_IMAGE_WIDTH}/${attachment.posterImageId}?format=auto`;
    case '9:16':
      const ratio = 9 / 16;
      return `${IMAGE_PROXY_URL}/custom/${DEFAULT_IMAGE_WIDTH}/${attachment.posterImageId}?format=auto&ratio=${ratio}`;
    default:
      return `${IMAGE_PROXY_URL}/wide/${DEFAULT_IMAGE_WIDTH}/${attachment.posterImageId}?format=auto`;
  }
};

const generateAstridPosterUrl = (attachment: DirektcenterVideo) => {
  if (!attachment.astridImage) {
    return;
  }
  let aspectRatioString;
  switch (attachment.aspectRatio) {
    case '1:1':
    case '9:16':
      aspectRatioString = attachment.aspectRatio;
      break;
    default:
      aspectRatioString = '16:9';
  }
  const focalPoint = { x: 0.5, y: 0.5, ...attachment.astridImage.focalPoint };

  return `${ASTRID_IMAGE_PROXY_URL}/${DEFAULT_IMAGE_WIDTH}/${aspectRatioString}/${focalPoint.x}/${focalPoint.y}/${attachment.astridImage.id}`;
};

export const generatePosterUrl = (attachment: DirektcenterVideo) => {
  if (attachment.astridImage) {
    return generateAstridPosterUrl(attachment);
  }

  if (attachment.posterImageId) {
    return generateEscenicPosterUrl(attachment);
  }

  return generateDefaultPosterUrl(attachment);
};

export const generatePillarBoxBackgroundImage = (
  attachment: DirektcenterVideo
) => {
  // TODO: Show a blurred image if / when Astrid's image proxy can blur and dim images
  // if (attachment.astridImage) {
  //   return {
  //     backgroundImage: `url(${ASTRID_IMAGE_PROXY_URL}/${DEFAULT_IMAGE_WIDTH}/1:1/${attachment.astridImage.focalPoint.x}/${attachment.astridImage.focalPoint.y}/${attachment.astridImage.id}&dim=20&blur=20)`,
  //   };
  // }

  if (attachment.posterImageId) {
    return {
      backgroundImage: `url(${IMAGE_PROXY_URL}/square/${DEFAULT_IMAGE_WIDTH}/${attachment.posterImageId}?format=auto&dim=20&blur=20)`,
    };
  }

  return {
    backgroundImage: `url(${IMAGE_PROXY_URL}/square/${DEFAULT_IMAGE_WIDTH}/${DEFAULT_IMAGE_ID}?format=auto&quality=30&dim=100&blur=20)`,
  };
};

type AttachmentVideoProps = {
  attachment: DirektcenterVideo;
};

const AttachmentVideo = ({ attachment }: AttachmentVideoProps) => {
  const posterUrl = generatePosterUrl(attachment);

  const displayVideoInPillarBox = attachment.aspectRatio === '9:16';

  return (
    <div
      className={s.root}
      style={
        (displayVideoInPillarBox &&
          generatePillarBoxBackgroundImage(attachment)) ||
        {}
      }
    >
      <div
        className={cn({
          [s.pillarBox]: displayVideoInPillarBox,
        })}
      >
        <VideoPlayer
          videoId={attachment.svtId}
          aspectRatio={attachment.aspectRatio}
          theme={videoPlayerTheme}
          poster={posterUrl}
          autoPlay={false}
          preload={false}
          disableStatistics
        />
      </div>
    </div>
  );
};

AttachmentVideo.validate = (attachment: DirektcenterVideo) =>
  Boolean(attachment.type === AttachmentVideo.type) &&
  Boolean(attachment.svtId);

AttachmentVideo.type = 'svt-video';

export default AttachmentVideo;
