import React, { FC } from 'react';
import s from './LinkButton.module.scss';
import cn from 'classnames';

type LinkButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

const LinkButton: FC<LinkButtonProps> = ({
  children,
  className,
  type = 'button',
  disabled,
  onClick,
}) => (
  <button
    type={type}
    disabled={disabled}
    className={cn(className, s.root)}
    onClick={onClick}
  >
    {children}
  </button>
);

export default LinkButton;
