import { useState, useEffect } from 'react';
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';
import getInitializedFirestore from '../utils/getInitializedFirestore';

const useHighlightedPosts = (streamId) => {
  const [highlightedPosts, setHighlightedPosts] = useState([]);
  const [hasHighlights, setHasHighlights] = useState(false);

  useEffect(() => {
    const firestore = getInitializedFirestore();
    const postsQuery = query(
      collection(doc(collection(firestore, 'streams'), streamId), 'posts'),
      where('highlighted', '==', true),
      orderBy('createdAt', 'desc'),
      limit(5)
    );

    const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
      let posts = [];
      snapshot.forEach((doc) => {
        posts.push({ ...doc.data(), id: doc.id });
      });
      setHighlightedPosts(posts);
      setHasHighlights(posts.length > 0);
    });

    return () => unsubscribe();
  }, [streamId]);

  return { hasHighlights, highlightedPosts };
};

export default useHighlightedPosts;
