import { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from 'contexts/UserContext';

const ShowForAdmin = ({ children }) => {
  const { isAdmin } = useContext(UserContext);
  if (isAdmin) {
    return children;
  }

  return null;
};

ShowForAdmin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShowForAdmin;
