import React from 'react';

import UserContext from '../UserContext';

// eslint-disable-next-line react/prop-types
const UserContextProviderMock = ({ children }) => {
  return (
    <UserContext.Provider
      value={{
        user: {
          displayName: 'John Doe',
          id: 123,
          role: 'editor',
          email: 'john.doe@svt.se',
        },
        accessToken: '',
        isLoggedIn: true,
        login: () => {},
        logout: () => {},
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProviderMock;
