import { CSSProperties, FC, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

/**
 * Wrap a component in <AnimateIn> to slide it down or up when the isVisible prop is toggled.
 * The animateInOnMount prop is a boolean indicating wether there should be a slide down animation when a visible component is mounted.
 */

type AnimateInProps = {
  isVisible: boolean;
  children: React.ReactNode;
  animateInOnMount: boolean;
};

const AnimateIn: FC<AnimateInProps> = ({
  isVisible,
  children,
  animateInOnMount,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    animateInOnMount ? !isVisible : isVisible
  );

  useEffect(() => {
    if (animateInOnMount) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(isVisible);
    }, 1);
  }, [isVisible]);

  const initialStyle: CSSProperties = animateInOnMount ? { height: '0' } : {};

  return (
    <AnimateHeight
      style={initialStyle}
      duration={250}
      height={isExpanded ? 'auto' : 0}
    >
      {children}
    </AnimateHeight>
  );
};

export default AnimateIn;
