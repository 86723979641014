import { ReactComponent as SVTLogo } from 'img/svt.svg';

import s from './SVTFooter.module.scss';

const SVTFooter = () => (
  <footer className={s.root}>
    <div className={s.logo}>
      <SVTLogo />
    </div>
    <div className={s.copy}>&copy; Sveriges Television AB</div>
  </footer>
);

export default SVTFooter;
