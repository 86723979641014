import { useState, useEffect, useContext } from 'react';
import NotificationContext from 'contexts/NotificationContext';

import { NotificationMessageWithIdType } from 'contexts/NotificationContext';
import Notification from './Notification';

type NotificationContainerProps = {
  autoCloseTime: number;
  notificationMessage: NotificationMessageWithIdType;
};
export default function NotificationContainer({
  autoCloseTime = 10,
  notificationMessage,
}: NotificationContainerProps) {
  const [animatingClose, setAnimatingClose] = useState(false);
  const { unsetNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (animatingClose) {
      const animationTimeout = setTimeout(() => {
        setAnimatingClose(false);
        unsetNotification(notificationMessage.id);
      }, 300);
      return () => clearTimeout(animationTimeout);
    }
  }, [animatingClose, unsetNotification, notificationMessage]);

  useEffect(() => {
    if (notificationMessage) {
      const closingTimeout = setTimeout(() => {
        setAnimatingClose(true);
      }, autoCloseTime * 1000);
      return () => clearTimeout(closingTimeout);
    }
  }, [autoCloseTime, notificationMessage]);

  return (
    <Notification
      message={notificationMessage.message}
      type={notificationMessage.type}
      onClose={() => unsetNotification(notificationMessage.id)}
      closing={animatingClose}
    />
  );
}
