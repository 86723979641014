import { useState, useEffect, useContext, useRef } from 'react';

import StreamCollection from 'services/Stream/StreamCollection';
import ErrorContext from 'contexts/ErrorContext';

const usePaginatedPostStream = ({
  streamId,
  postsPerPage,
  filterHighlighted = false,
}) => {
  const { setError } = useContext(ErrorContext);

  const [loadingInitialPosts, setLoadingInitialPosts] = useState(true);
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [posts, setPosts] = useState([]);
  const postStream = useRef(null);

  const loadMorePosts = () => {
    setLoadingMorePosts(true);
    postStream.current.loadMoreItems();
  };

  useEffect(() => {
    postStream.current = StreamCollection({
      streamId,
      collectionName: 'posts',
      orderByArray: [
        ['pinned', 'desc'],
        ['createdAt', 'desc'],
      ],
      postsPerPage,
    });

    const onPostsUpdate = (posts) => {
      setLoadingInitialPosts(false);
      setLoadingMorePosts(false);
      setHasMorePosts(postStream.current.hasMoreItems());
      setPosts(posts);
    };

    const onPostsError = (err) => {
      console.error(err);
      setError(
        'Ett oväntat fel uppstod vid laddning av poster. Kontrollera din anslutning och försök igen.'
      );
    };

    postStream.current.listen(onPostsUpdate, onPostsError);

    return postStream.current.unsubscribe;
  }, [postsPerPage, setError, streamId]);

  useEffect(() => {
    postStream.current.setFilterHighlighted(filterHighlighted);
  }, [filterHighlighted]);

  return {
    posts,
    loadingInitialPosts,
    loadingMorePosts,
    hasMorePosts,
    loadMorePosts,
  };
};

export default usePaginatedPostStream;
