import { useContext } from 'react';

import s from './AttachmentEditorForm.module.scss';
import Button from 'components/Button/Button';
import ImageEditorContainer from './ImageEditor/ImageEditorContainer';
import SvtVideoEditorContainer from './SvtVideoEditor/SvtVideoEditorContainer';
import WebpageEditorContainer from './WebpageEditor/WebpageEditorContainer';
import { PostEditorContext, postEditorActions } from '../PostEditorStore';
import AttachmentImage from 'components/Attachment/AttachmentImage';
import AttachmentVideo from 'components/Attachment/AttachmentVideo';
import AttachmentWebpage from 'components/Attachment/AttachmentWebpage';

const typeMetaData = {
  [AttachmentImage.type]: {
    title: 'Bild',
    label: 'bild',
    editor: ImageEditorContainer,
  },
  [AttachmentVideo.type]: {
    title: 'SVT Video',
    label: 'video',
    editor: SvtVideoEditorContainer,
  },
  [AttachmentWebpage.type]: {
    title: 'SVT Artikel',
    label: 'artikel',
    editor: WebpageEditorContainer,
  },
};

const AttachmentEditorForm = () => {
  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);
  const attachment = postEditorState.postInProgress.attachment;
  const Editor = typeMetaData[attachment.type].editor;
  const removeAttachment = () => {
    postEditorDispatcher({
      type: postEditorActions.RESET_ATTACHMENT,
    });
  };

  return (
    <fieldset className={s.root}>
      <div className={s.header}>
        <Button
          type="button"
          className={s.removeButton}
          variant="secondary"
          onClick={removeAttachment}
        >
          Ta bort {typeMetaData[attachment.type].label}
        </Button>
        <legend className={s.legend}>
          {typeMetaData[attachment.type].title}
        </legend>
      </div>
      <div className={s.formBody}>
        <Editor />
      </div>
    </fieldset>
  );
};

export default AttachmentEditorForm;
