import { useContext } from 'react';

import NotificationContext from 'contexts/NotificationContext';
import api from '../services/api';
import { DirektcenterPost } from 'Types';

const useCreatePost = (streamId: string) => {
  const { flashNotification } = useContext(NotificationContext);

  const createPost = async (post: DirektcenterPost) => {
    const response = await api.postCreate(streamId, {
      body: post.body,
      attachment: post.attachment,
      pinned: false,
      highlighted: false,
      author: post.author,
    });
    if (response.status !== 'success') {
      flashNotification({
        type: 'error',
        message: 'Kunde inte skapa ditt inlägg. Vänligen försök igen',
      });
      return false;
    }
    return true;
  };

  return createPost;
};

export default useCreatePost;
