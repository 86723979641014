import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import { ReactComponent as SVTLogo } from 'img/svt.svg';
import { ReactComponent as DCLogo } from 'img/direktcenter-logo.svg';
import Button from 'components/Button/Button';
import s from './LoginPage.module.scss';
import useTodaysBackgroundImage from './hooks/useTodaysBackgroundImage';

const LoginBox = () => {
  const isStage = Boolean(Number(process.env.REACT_APP_STAGE));
  const title = isStage ? 'DIREKTCENTER STAGE' : 'DIREKTCENTER';

  return (
    <div className={s.box}>
      <div className={s.logoWrapper}>
        <DCLogo />
      </div>
      <div className={s.textWrapper}>
        <h1 className={s.direktcenterTitle}>{title}</h1>
        <p className={s.direktcenterDesc}>Snabb och enkel liverapportering</p>
      </div>
      <Button
        big={true}
        variant="primary"
        component="a"
        href={`${process.env.REACT_APP_API_URL}/ad/login`}
      >
        Logga in
      </Button>
    </div>
  );
};

const Footer = () => (
  <div className={s.footerContainer}>
    <SVTLogo />
  </div>
);

const LoginPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(UserContext);
  const { url, copyright } = useTodaysBackgroundImage();

  useEffect(() => {
    const loginUrlParams = new URLSearchParams(window.location.search);
    const loginTarget = loginUrlParams.get('target');

    if (isLoggedIn === true) {
      navigate(encodeURI(loginTarget || '/streams'));
      return;
    }

    if (loginTarget) {
      sessionStorage.setItem('loginTarget', loginTarget);
    }
  });

  return (
    <div style={{ backgroundImage: `url(${url})` }} className={s.root}>
      <Helmet title="Logga in" />
      <LoginBox />
      <Footer />
      <div className={s.creditBox}>
        <span>{'Fotograf: '}</span>
        <span className={s.creditText}>{copyright}</span>
      </div>
    </div>
  );
};

export default LoginPage;
