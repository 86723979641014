import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip/Tooltip';
import VisitorPostsTableRow from './VisitorPostsTableRow';
import s from './VisitorPostsTable.module.scss';

const DirectionIndicator = ({ doShow, doSortAscending }) => {
  if (!doShow) {
    return null;
  }
  return doSortAscending ? '▲' : '▼';
};

DirectionIndicator.propTypes = {
  doShow: PropTypes.bool,
  doSortAscending: PropTypes.bool,
};

export default function VisitorPostsTable({
  pendingPosts,
  setSortField,
  sortField,
  doSortAscending,
}) {
  if (!pendingPosts) {
    return null;
  }

  return (
    <table className={s.root}>
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => {
              return setSortField('createdAt');
            }}
          >
            Tid
            <DirectionIndicator
              doShow={sortField === 'createdAt'}
              doSortAscending={doSortAscending}
            />
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('authorName');
            }}
          >
            Namn
            <DirectionIndicator
              doShow={sortField === 'authorName'}
              doSortAscending={doSortAscending}
            />
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('visitorHash');
            }}
            className={s.tooltipTrigger}
          >
            Avsändare
            <DirectionIndicator
              doShow={sortField === 'visitorHash'}
              doSortAscending={doSortAscending}
            />
            <Tooltip className={s.tooltip}>Baseras bl.a. på IP-adress</Tooltip>
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('status');
            }}
          >
            Status
            <DirectionIndicator
              doShow={sortField === 'status'}
              doSortAscending={doSortAscending}
            />
          </th>
          <th
            className={s.idCell}
            scope="col"
            onClick={() => {
              return setSortField('id');
            }}
          >
            ID
            <DirectionIndicator
              doShow={sortField === 'id'}
              doSortAscending={doSortAscending}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {pendingPosts.length <= 0 && (
          <tr>
            <td colSpan={5}>
              <span role="img" aria-label="">
                🤷‍♀️
              </span>{' '}
              Hittade inga inlägg.
            </td>
          </tr>
        )}
      </tbody>
      {pendingPosts.map((pendingPost) => {
        return (
          <VisitorPostsTableRow
            pendingPost={pendingPost}
            key={pendingPost.id}
          />
        );
      })}
    </table>
  );
}

VisitorPostsTable.propTypes = {
  pendingPosts: PropTypes.array,
  setSortField: PropTypes.func,
  sortField: PropTypes.string,
  doSortAscending: PropTypes.bool,
};
