import React from 'react';
import PropTypes from 'prop-types';

import GuestsTableRow from './GuestsTableRow';
import s from './GuestsTable.module.scss';

export default function GuestsTable({ guests, setSortField }) {
  if (!guests) {
    return null;
  }

  return (
    <table className={s.root}>
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => {
              return setSortField('id');
            }}
          >
            ID
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('avatar');
            }}
          >
            Avatar
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('displayName');
            }}
          >
            Namn
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('title');
            }}
          >
            Titel
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('status');
            }}
          >
            Status
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('created_at');
            }}
          >
            Skapad datum
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('initiatorEmail');
            }}
          >
            Skapad av
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('streamId');
            }}
          >
            Inbjuden till ström
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('isModerationRequired');
            }}
          >
            Kräver moderering?
          </th>
          <th
            scope="col"
            onClick={() => {
              return setSortField('lastRefreshTokenCreatedAt');
            }}
          >
            Senast skapad refresh token
          </th>
        </tr>
      </thead>
      <tbody>
        {guests.length <= 0 && (
          <tr>
            <td colSpan={100}>
              <span role="img" aria-label="">
                🤷‍♀️
              </span>{' '}
              Hittade inga gäster.
            </td>
          </tr>
        )}
        {guests.map((guest) => {
          return <GuestsTableRow guest={guest} key={guest.id} />;
        })}
      </tbody>
    </table>
  );
}

GuestsTable.propTypes = {
  guests: PropTypes.array,
  setSortField: PropTypes.func,
};
