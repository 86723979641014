import moment, { Moment } from 'moment';
import 'moment/locale/sv';

const COLON_TIME_FORMAT = 'HH:mm';

function getMinutesSince(now: Moment, mdate: Moment): number | undefined {
  moment.relativeTimeThreshold('m', 1);
  const diffDate = now.diff(mdate);
  const duration = moment.duration(diffDate);
  const minutes = duration.minutes();
  return minutes;
}

export function svtDateFormatFull(mdate: Moment): string {
  return mdate.format('YYYY-MM-DD HH:mm');
}

export function svtDateFormat(mdate: Moment, now: Moment = moment()): string {
  const isWithin = (
    entity: number,
    type: moment.unitOfTime.DurationConstructor
  ) => {
    return (
      mdate.isAfter(now.subtract(entity, type)) &&
      mdate.isBefore(now.add(entity, type))
    );
  };

  if (mdate.isAfter(now)) {
    return 'Just nu';
  }

  if (isWithin(1, 'hour')) {
    const minutes = getMinutesSince(now, mdate);
    if (minutes === 0) {
      return 'Just nu';
    }
    return `${minutes} min`;
  }

  const isToday = mdate.isSame(now, 'day');
  const isYesterday = mdate.isSame(now.subtract(1, 'day'), 'day');

  if (isToday) {
    return mdate.format(COLON_TIME_FORMAT);
  }
  if (isYesterday) {
    return 'Igår ' + mdate.format(COLON_TIME_FORMAT);
  }
  if (mdate.isSame(now, 'year')) {
    return mdate.format('D MMMM');
  }
  return mdate.format('D MMM YYYY');
}
