import React from 'react';
import PropTypes from 'prop-types';

import s from './NoItemsNotice.module.scss';

type NoItemsNoticeProps = { children: React.ReactNode };

const NoItemsNotice = ({ children }: NoItemsNoticeProps) => (
  <div className={s.root}>{children}</div>
);

NoItemsNotice.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NoItemsNotice;
