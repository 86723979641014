import React from 'react';
import Module from 'components/Module/Module';
import { ADMIN, EDITOR, GUEST } from 'utils/roles';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import { DirektcenterRoleType } from 'Types';

type UnauthorizedPageProps = {
  role: DirektcenterRoleType;
};

function UnauthorizedPage({ role }: UnauthorizedPageProps) {
  let message;

  switch (role) {
    case GUEST:
      message =
        'Vi hittar inte din användare längre, kontakta den som bjöd in dig.';
      break;
    case ADMIN:
    case EDITOR:
    default:
      message = (
        <>
          Prova att <a href="/login">logga in</a> igen.
        </>
      );
  }

  return (
    <PageContent>
      <PageMainContainer>
        <PageTitle>Du har blivit utloggad</PageTitle>

        <Module>
          <p>{message}</p>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
}

export default UnauthorizedPage;
