import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import ErrorContext from 'contexts/ErrorContext';
import Loader from 'components/Loader/Loader';
import s from './withAuthentication.module.scss';

function withAuthentication(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isLoggedIn, isLoading, isRestoring, isLoggingIn, user, role } =
      useContext(UserContext);
    const { setUnauthorized } = useContext(ErrorContext);

    useEffect(() => {
      if (
        isLoggedIn === false &&
        isLoading === false &&
        isRestoring === false &&
        user === null &&
        role
      ) {
        return setUnauthorized(role);
      }
      if (
        isLoggedIn === false &&
        isLoading === false &&
        isRestoring === false &&
        isLoggingIn === false &&
        !user &&
        !role
      ) {
        let targetPath = '';

        if (pathname && pathname !== '/login') {
          targetPath = '?target=' + encodeURIComponent(pathname);
        }

        const redirect =
          process.env.NODE_ENV === 'development'
            ? `/login2${targetPath}`
            : `/login${targetPath}`;

        return navigate(redirect);
      }
    }, [
      user,
      role,
      isRestoring,
      isLoading,
      isLoggedIn,
      isLoggingIn,
      navigate,
      pathname,
      setUnauthorized,
    ]);

    if (isLoading) {
      return (
        <div className={s.loader}>
          <Loader />
        </div>
      );
    }

    if (isLoggedIn === false) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAuthentication;
