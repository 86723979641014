import { useRef, useEffect } from 'react';
import _ from 'lodash';

const useThrottledCallback = (callback, throttleTimeMs, trailing = true) => {
  const savedCallback = useRef(
    _.throttle(callback, throttleTimeMs, { trailing })
  );

  useEffect(() => {
    savedCallback.current = _.throttle(callback, throttleTimeMs, { trailing });
  }, [callback, throttleTimeMs, trailing]);

  return savedCallback.current;
};

export default useThrottledCallback;
