import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UpdateStreamEditor from './UpdateStreamEditor';
import api from '../../services/api';
import NotificationContext from 'contexts/NotificationContext';
import ErrorContext from 'contexts/ErrorContext';

const UpdateStreamEditorContainer = ({
  stream,
  streamId,
  onDidCancel = () => {},
  onDidUpdate = () => {},
  onDidDelete = () => {},
}) => {
  const { flashNotification } = useContext(NotificationContext);
  const { setError } = useContext(ErrorContext);

  const updateStream = async (newStreamData) => {
    const {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      createdAt,
      parentSection,
      section,
    } = newStreamData;

    const response = await api.streamUpdate(streamId, {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      createdAt,
      parentSection,
      section,
    });

    if (response.status === 'success') {
      flashNotification({
        type: 'success',
        message: `Strömmen har uppdaterats`,
      });
    } else if (response.status === 'fail') {
      flashNotification({
        type: 'error',
        message: `Misslyckades med att uppdatera inställningarna: ${response.data.join(
          ', '
        )}`,
      });
    } else {
      return setError(
        'Ett oväntat fel inträffade. Ladda om sidan och försök igen.'
      );
    }
  };

  const onUpdate = async (newStream) => {
    await updateStream(newStream);
    onDidUpdate();
  };

  const deleteStream = async () => {
    const response = await api.streamDelete(streamId);
    if (response.status === 'success') {
      flashNotification({
        type: 'success',
        message: `Strömmen har tagits bort`,
      });
    } else {
      return setError(
        'Kunde inte ta bort strömmen. Kontrollera din internetanslutning och försök igen.'
      );
    }
  };

  const onDelete = () => {
    deleteStream();
    onDidDelete();
  };

  return (
    <UpdateStreamEditor
      stream={stream}
      streamId={streamId}
      onCancel={onDidCancel}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  );
};

UpdateStreamEditorContainer.propTypes = {
  stream: PropTypes.object.isRequired,
  streamId: PropTypes.string.isRequired,
  onDidCancel: PropTypes.func,
  onDidUpdate: PropTypes.func,
  onDidDelete: PropTypes.func,
};

export default UpdateStreamEditorContainer;
