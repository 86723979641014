import { useEffect, useState } from 'react';
import _ from 'lodash';
import { doc, onSnapshot } from 'firebase/firestore';
import getInitializedFirestore from '../utils/getInitializedFirestore';

const isValidSvtUrl = (url) => {
  const isSvtDomain =
    url.startsWith('https://svt.se') ||
    url.startsWith('https://www.svt.se') ||
    url.startsWith('https://stage.svt.se') ||
    url.startsWith('https://www.stage.svt.se');

  const hasNoQueryParameters = url.search('\\?') === -1;
  return isSvtDomain && hasNoQueryParameters;
};

const filterUrls = (urls) =>
  _.chain(urls)
    .map((url) => (isValidSvtUrl(url) ? url : undefined))
    .omitBy(_.isUndefined)
    .map()
    .value();

function useDirektcenterMetadata(streamId) {
  const [metadata, setMetadata] = useState(null);
  useEffect(() => {
    const firestore = getInitializedFirestore();
    const docRef = doc(firestore, 'streamMetadata', streamId);
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const data = snap.data();
      const urls = filterUrls(data?.urls);
      setMetadata({ ...data, urls });
    });

    return () => unsubscribe();
  }, [streamId]);

  return metadata;
}

export default useDirektcenterMetadata;
