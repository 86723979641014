import PropTypes from 'prop-types';
import s from './StreamMetadataContainer.module.scss';
import _ from 'lodash';

const StreamMetadataContainer = ({ metadata, streamId }) => (
  <>
    <h2 className={s.heading}>Relaterat</h2>
    {Boolean(metadata?.urls.length) && (
      <>
        <h3>Strömmen finns publicerad på följande sidor</h3>
        <ul>
          {_.map(metadata?.urls, (url, key) => (
            <li key={key}>
              <a href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
            </li>
          ))}
        </ul>
      </>
    )}

    <h3>Inlägg från besökare</h3>
    <p>
      <a href={`/streams/${streamId}/statistics`}>
        Statistik för besökarinlägg
      </a>
    </p>
    <p>
      <a href={`/streams/${streamId}/visitor-posts`}>Se alla besökarinlägg</a>
    </p>
  </>
);

StreamMetadataContainer.propTypes = {
  metadata: PropTypes.shape({
    urls: PropTypes.arrayOf(PropTypes.string),
  }),
  streamId: PropTypes.string.isRequired,
};

export default StreamMetadataContainer;
