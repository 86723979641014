import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import withAuthentication from 'components/withAuthentication';
import withGuestAuthorization from 'components/withGuestAuthorization';
import StreamTable from 'components/StreamTable/StreamTable';
import SectionSelect from 'components/SectionSelect';
import Module from 'components/Module/Module';
import Button from 'components/Button/Button';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';
import Loader from 'components/Loader/Loader';
import Bookmarklet from 'components/Bookmarklet/Bookmarklet';
import { validateSection, validateParentSection } from 'utils/streamSection';
import useLocalStorage from 'hooks/useLocalStorage';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import Input from 'components/Input/Input';
import useDebounce from 'hooks/useDebounce';
import betterTrim from 'utils/betterTrim';

import s from './StreamsPage.module.scss';
import useSearchStreams from 'hooks/useSearchStreams';

const DEFAULT_SECTION_VALUE = 'all';

const StreamsPage = () => {
  const [query, setQuery] = useState('');
  const searchQuery = useDebounce(query, 500);

  const [section, setSection, removeSection] = useLocalStorage(
    'streamSection',
    DEFAULT_SECTION_VALUE
  );

  useEffect(() => {
    if (!validateParentSection(section) && !validateSection(section)) {
      removeSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loadMore,
    result: { hasSearchResult, hasNextPage, searchResult },
    loading: { isLoadingSearchResults, isLoadingMore },
  } = useSearchStreams({ section, searchQuery });

  const showEmptyState = !hasSearchResult && !isLoadingSearchResults;
  const showResultList = hasSearchResult && !isLoadingSearchResults;

  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title="Alla strömmar" />
        <PageTitle>Alla strömmar</PageTitle>
        <Module>
          <div className={s.header}>
            <div className={s.headerLeft}>
              <SectionSelect
                value={
                  validateSection(section) ||
                  validateParentSection(section) ||
                  DEFAULT_SECTION_VALUE
                }
                onChange={(e) => {
                  setSection(e.target.value);
                }}
                label="Filtrera på avdelning"
                defaultValue={DEFAULT_SECTION_VALUE}
                defaultString="Alla"
              />
              <Input
                className={s.search}
                name="search"
                label="Filtrera på strömtitel"
                placeholder="Fritext"
                maxLength={100}
                autoFocus
                onChange={(e) => {
                  setQuery(betterTrim(e.target.value));
                }}
              />
            </div>
            <div className={s.headerRight}>
              <Button
                fullWidth
                id="createStream"
                variant="primary"
                component={Link}
                to="/streams/create"
              >
                Skapa ny ström
              </Button>
            </div>
          </div>
          {isLoadingSearchResults && <Loader />}
          {showEmptyState && (
            <EmptyState searchQuery={searchQuery} section={section} />
          )}
          {showResultList && (
            <>
              <StreamTable streams={searchResult} />
              {isLoadingMore && <Loader />}
              {hasNextPage && !isLoadingMore && (
                <LoadMoreButton onClick={loadMore}>Ladda fler</LoadMoreButton>
              )}
            </>
          )}
        </Module>
      </PageMainContainer>
      <div className={s.bookmarkletContainer}>
        <Bookmarklet />
        <div className={s.bookmarkletTextContainer}>
          <span>
            Hitta till en direktrapport snabbt och enkelt med detta smarta
            bokmärke. Dra bara
          </span>
          <StarBorderIcon fontSize="small" />
          <span>till bokmärkesfältet i din webbläsare. </span>{' '}
        </div>
      </div>
    </PageContent>
  );
};

const EmptyState = ({ searchQuery, section }) => (
  <div className={s.empty}>
    Inga strömmar matchade din filtrering: <strong>{searchQuery}</strong>
    {searchQuery && section !== 'all' && ' i avdelningen '}
    {section !== 'all' && <strong>{section}</strong>}
  </div>
);

EmptyState.propTypes = {
  searchQuery: PropTypes.string,
  section: PropTypes.string,
};

export default withAuthentication(withGuestAuthorization(StreamsPage));
