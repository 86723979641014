import React, { forwardRef, ReactNode } from 'react';
import cn from 'classnames';

import Tooltip from 'components/Tooltip/Tooltip';
import s from './Button.module.scss';

type ButtonProps = {
  children: ReactNode;
  variant:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'dark'
    | 'light'
    | 'active'
    | 'link';
  fullWidth?: boolean;
  round?: boolean;
  leftRound?: boolean;
  rightRound?: boolean;
  big?: boolean;
  className?: string;
  component?: React.ElementType;
  tooltip?: string;
  onClick?: () => any;
  onKeyDown?: () => any;
  autoFocus?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  href?: string;
  id?: string;
};

const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      children,
      variant,
      fullWidth,
      round,
      leftRound,
      rightRound,
      big,
      className,
      component: Component = 'button',
      tooltip,
      ...props
    },
    ref
  ) => {
    const classes = cn({
      [s.button]: true,
      [s.primary]: variant === 'primary',
      [s.secondary]: variant === 'secondary',
      [s.danger]: variant === 'danger',
      [s.dark]: variant === 'dark',
      [s.light]: variant === 'light',
      [s.active]: variant === 'active',
      [s.link]: variant === 'link',
      [s.round]: round,
      [s.leftRound]: leftRound,
      [s.rightRound]: rightRound,
      [s.big]: big,
      [s.fullWidth]: fullWidth,
      ...(className ? { [className]: true } : {}),
    });
    return (
      <Component className={classes} {...props} ref={ref}>
        {tooltip && <Tooltip className={s.tooltip}>{tooltip}</Tooltip>}
        {children}
      </Component>
    );
  }
);

export default Button;
