import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../../Tooltip/Tooltip';
import s from './Toolbar.module.scss';

const Toolbar = ({ items }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        if (document.activeElement.previousElementSibling) {
          document.activeElement.previousElementSibling.focus();
          setCurrentTabIndex(currentTabIndex - 1);
        } else {
          document.activeElement.parentNode.lastChild.focus();
          setCurrentTabIndex(items.length - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (document.activeElement.nextElementSibling) {
          document.activeElement.nextElementSibling.focus();
          setCurrentTabIndex(currentTabIndex + 1);
        } else {
          document.activeElement.parentNode.firstChild.focus();
          setCurrentTabIndex(0);
        }
        break;
      default:
        return;
    }
  };

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  return (
    <div className={s.root} role="toolbar" onKeyDown={handleKeyDown}>
      {items.map((item, i) => (
        <button
          type="button"
          data-testid={`toolbar${item.testId}Button`}
          className={cn(s.item, { [s.itemActive]: item.active })}
          // onMouseDown to prevent loss of focus
          onMouseDown={item.onClick}
          key={i}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              item.onClick(event);
            }
          }}
          aria-pressed={item.active}
          aria-label={item.label}
        >
          {!isMobile && (
            <Tooltip className={s.tooltip}>
              {item.label}
              {item.shortCutLabel ? ` (${item.shortCutLabel})` : ''}
            </Tooltip>
          )}
          <item.icon className={s.icon} />
        </button>
      ))}
    </div>
  );
};

Toolbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      shortCutLabel: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.elementType.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
};

export default Toolbar;
