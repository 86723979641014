import Dialog from '@mui/material/Dialog';

import s from './DisasterLiveBroadcastModal.module.scss';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Tooltip from 'components/Tooltip/Tooltip';
import { useState } from 'react';
import AnimateIn from 'components/AnimateIn/AnimateIn';

type DisasterLiveBroadcastModalProps = {
  visible: boolean;
  currentManifest: string | undefined;
  defaultManifest: string;
  onSubmit: (manifest: string) => void;
  onCancel: () => void;
};

function isValidUrl(str: string) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

const DisasterLiveBroadcastModal = ({
  visible,
  currentManifest,
  defaultManifest,
  onSubmit,
  onCancel,
}: DisasterLiveBroadcastModalProps) => {
  const [newManifest, setNewManifest] = useState(
    !currentManifest || currentManifest === defaultManifest
      ? ''
      : currentManifest
  );

  const [useDefaultManifestValue, setUseDefaultManifestValue] = useState(
    currentManifest === defaultManifest
  );

  const invalidUrlError = isValidUrl(newManifest) ? '' : 'Ange en giltig URL';

  const manifestError = useDefaultManifestValue ? '' : invalidUrlError;

  const handleOtherManifestChange = (value: string) => {
    setNewManifest(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedManifest = useDefaultManifestValue
      ? defaultManifest
      : newManifest;

    onSubmit(updatedManifest);
  };

  return (
    <Dialog open={visible} fullWidth={true} maxWidth="xs" onClose={onCancel}>
      <div className={s.root}>
        <h2 className={s.header}>Avancerade inställningar för livesändning</h2>
        <form onSubmit={handleSubmit}>
          <fieldset
            className={s.fieldsetElement}
            onChange={() => {
              setUseDefaultManifestValue(!useDefaultManifestValue);
            }}
          >
            <label className={s.radioInput}>
              <input
                type="radio"
                name="streamStatus"
                className={s.radioInputElement}
                checked={useDefaultManifestValue}
                readOnly
              />
              <div style={{ display: 'flex' }}>
                <div className={s.radioLabelText}>{`Standard`}</div>
                <div className={s.extraLabelWrapper}>
                  <div className={s.extraLabel}>{`(LP1)`}</div>
                  <Tooltip className={s.tooltip}>{defaultManifest}</Tooltip>
                </div>
              </div>
            </label>
            <label className={s.radioInput}>
              <input
                type="radio"
                name="streamStatus"
                className={s.radioInputElement}
                checked={!useDefaultManifestValue}
                readOnly
              />
              <div className={s.radioLabelText}>Annan</div>
            </label>
          </fieldset>
          <div className={s.manifestInputWrapper}>
            <AnimateIn
              isVisible={!useDefaultManifestValue}
              animateInOnMount={false}
            >
              <Input
                id="manifest"
                label="Manifest URL"
                type="text"
                error={manifestError}
                disabled={useDefaultManifestValue}
                onChange={(e) => handleOtherManifestChange(e.target.value)}
                value={newManifest}
              />
            </AnimateIn>
          </div>

          <div className={s.footer}>
            <Button variant="secondary" type="button" onClick={onCancel}>
              Avbryt
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={Boolean(manifestError)}
            >
              Spara
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default DisasterLiveBroadcastModal;
