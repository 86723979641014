// String.prototype.trim() does not trim Unicode characters without White_Space property
// such as zero-width space and soft hyphen.
// https://en.wikipedia.org/wiki/Whitespace_character#Unicode
// https://tc39.es/ecma262/#sec-string.prototype.trim

const betterTrim = (str: string | number | undefined | null) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str
    .replace(
      /^[\s|\u00AD|\u200b|\u180e|\u200b|\u200c|\u200d|\u2060|\ufeff]+/g,
      ''
    )
    .replace(
      /[\s|\u00AD|\u200b\u180e|\u200b|\u200c|\u200d|\u2060|\ufeff]+$/g,
      ''
    );
};

export default betterTrim;
