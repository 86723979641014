import cn from 'classnames';
import s from './Banner.module.scss';
import { ReactComponent as WarningIcon } from 'img/direktcenter-info-icon.svg';
import { FC } from 'react';

type BannerProps = {
  text: string;
  className: string;
};

const Banner: FC<BannerProps> = ({ text, className }) => {
  const rootClassName = cn(s.root, s.warning, className);
  return (
    <div className={rootClassName}>
      <div className={s.ribbon}>
        <WarningIcon className={s.icon} />
      </div>
      <div className={s.content}>{text}</div>
    </div>
  );
};

export default Banner;
