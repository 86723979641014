import React from 'react';

import NotificationContext from '../NotificationContext';

// eslint-disable-next-line react/prop-types
const NotificationContextProviderMock = ({ children }) => {
  return (
    <NotificationContext.Provider
      value={{
        notification: null,
        flashNotification: () => {},
        flashInfo: () => {},
        flashError: () => {},
        flashSuccess: () => {},
        unsetNotification: () => {},
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProviderMock;
