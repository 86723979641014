import addProtocol from '../../../utils/addProtocol';
import betterTrim from '../../../utils/betterTrim';
import isValidUrl from '../../../utils/isValidUrl';
import { Editor, Element, Range, Transforms } from 'slate';

const getActiveStyles = (editor) => {
  return new Set(Object.keys(Editor.marks(editor) ?? {}));
};

const handleProtocol = (formattedUrl) => {
  if (formattedUrl.startsWith('mailto')) {
    return formattedUrl;
  }

  return addProtocol(formattedUrl, 'http');
};

const hasMark = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleStyle = (editor, style) => {
  const activeStyles = getActiveStyles(editor);
  if (activeStyles.has(style)) {
    Editor.removeMark(editor, style);
  } else {
    Editor.addMark(editor, style, true);
  }
};

export const isLinkSelected = (editor) => {
  const selection = editor.selection;
  if (selection == null) {
    return false;
  }

  return (
    Editor.above(editor, {
      at: selection,
      match: (n) => n.type === 'link',
    }) != null
  );
};

export const isBoldActive = (editor) => hasMark(editor, 'bold');

export const isItalicActive = (editor) => hasMark(editor, 'italic');

export const toggleBold = (editor) => {
  toggleStyle(editor, 'bold');
};

export const toggleItalic = (editor) => {
  toggleStyle(editor, 'italic');
};

const insertNewLink = (editor) => {
  const href = window.prompt('Ange länkens webbadress:');
  const formattedUrl = betterTrim(href);
  // TODO: Add better error handling:
  if (!isValidUrl(formattedUrl)) {
    return;
  }

  const text = window.prompt('Ange länkens text:');
  // TODO: Add better error handling:
  if (text === null) {
    return;
  }

  Transforms.insertNodes(editor, {
    type: 'link',
    url: handleProtocol(formattedUrl),
    children: [{ text }],
  });
};

const wrapSelectionWithLink = (editor) => {
  const href = window.prompt('Ange länkens webbadress:');
  const formattedUrl = betterTrim(href);
  // TODO: Add better error handling:
  if (!isValidUrl(formattedUrl)) {
    return;
  }

  Transforms.wrapNodes(
    editor,
    {
      type: 'link',
      url: handleProtocol(formattedUrl),
      children: [{ text: '' }],
    },
    { split: true, at: editor.selection }
  );
};

export const toggleLink = (editor) => {
  if (editor.selection == null) {
    return;
  }

  if (isLinkSelected(editor)) {
    Transforms.unwrapNodes(editor, {
      match: (n) => Element.isElement(n) && n.type === 'link',
    });
  } else {
    const isSelectionCollapsed =
      editor.selection == null || Range.isCollapsed(editor.selection);
    if (isSelectionCollapsed) {
      insertNewLink(editor);
    } else {
      wrapSelectionWithLink(editor);
    }
  }
};

export const insertSoftBreak = (editor) => {
  const [start] = Range.edges(editor.selection);
  const cursorPoint = start;

  const startPointOfLastCharacter = Editor.before(editor, editor.selection, {
    distance: 1,
    unit: 'character',
  });

  if (!startPointOfLastCharacter) {
    // This is the first character
    return;
  }

  const lastCharacter =
    startPointOfLastCharacter &&
    Editor.string(
      editor,
      Editor.range(editor, startPointOfLastCharacter, cursorPoint)
    );

  if (lastCharacter !== '\n') {
    Transforms.insertText(editor, '\n');
  }
};
