import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './Module.module.scss';
import Loader from 'components/Loader/Loader';

const Module = ({
  label,
  noPadding,
  transparent,
  notification,
  type = 'default',
  children,
  loading = false,
  className,
}) => (
  <section className={cn(s.root, { [s.rootError]: type === 'error' })}>
    {label && (
      <h4 className={cn(s.label, { [s.labelError]: type === 'error' })}>
        {label}
        {notification && (
          <span className={s.notificication}>{notification}</span>
        )}
      </h4>
    )}

    <div
      className={cn(className, s.body, {
        [s.bodyNoPadding]: noPadding,
        [s.bodyTransparent]: transparent,
      })}
    >
      {loading ? <Loader /> : children}
    </div>
  </section>
);

Module.propTypes = {
  label: PropTypes.string,
  noPadding: PropTypes.bool,
  transparent: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  notification: PropTypes.node,
  type: PropTypes.oneOf(['default', 'error']),
  className: PropTypes.string,
};

export default Module;
