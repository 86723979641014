import { useParams } from 'react-router-dom';

const getIsDisasterStream = (streamId: string | undefined) => {
  if (!streamId || !process.env.REACT_APP_DISASTER_STREAM) {
    return false;
  }
  return streamId === process.env.REACT_APP_DISASTER_STREAM;
};

const useDisasterStream = () => {
  const { streamId } = useParams();
  const isDisasterStream = getIsDisasterStream(streamId);

  return {
    isDisasterStream,
    disasterStreamId: process.env.REACT_APP_DISASTER_STREAM,
  };
};

export default useDisasterStream;
