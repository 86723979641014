import React, { ChangeEvent } from 'react';
import cn from 'classnames';

import s from './Input.module.scss';

type InputProps = {
  id?: string;
  value?: string;
  label: string;
  error?: string;
  className?: string;
  rows?: number;
  maxRows?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  onClick?: () => void;
  type?: string;
  disabled?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, className, required, ...restProps }, ref) => {
    return (
      <label className={cn(s.root, { [s.hasError]: error }, className)}>
        <input className={s.input} {...restProps} ref={ref} />
        <span className={s.label}>
          {label}
          {required ? ' *' : ''}
        </span>
        {error && <span className={s.error}>{error}</span>}
      </label>
    );
  }
);

export default Input;
