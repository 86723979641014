import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as DownArrowIcon } from '../../img/arrow-down.svg';
import { ReactComponent as LinkIcon } from '../../img/link-solid.svg';
import s from './Item.module.scss';

const Item = ({ title, gray = false, slim = false, children }) => {
  const id = title.replace(new RegExp(' ', 'g'), '-');
  const urlContainsId = window.location.hash.includes(id);
  const [isOpen, setIsOpen] = useState(urlContainsId);

  return (
    <section className={cn(s.root, { [s.gray]: gray })}>
      <h2 className={s.title} id={id} onClick={() => setIsOpen(!isOpen)}>
        <a
          href={`#${id}`}
          className={s.link}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <LinkIcon className={s.icon} />
        </a>
        {title}
        <button
          className={cn(s.toggle, { [s.toggleOpen]: isOpen })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <DownArrowIcon className={s.icon} />
        </button>
      </h2>
      {isOpen && (
        <div className={cn(s.content, { [s.slim]: slim })}>{children}</div>
      )}
    </section>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  gray: PropTypes.bool,
  slim: PropTypes.bool,
  children: PropTypes.node,
};

export default Item;
