import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import StreamEditor from './StreamEditor';
import s from './CreateStreamEditor.module.scss';

const initialStream = {
  title: '',
  description: '',
  isActive: true,
  isVisitorPostingEnabled: true,
  isHighlightsBoxVisible: true,
  parentSection: '',
  section: '',
};

const CreateStreamEditor = ({ onCancel, loading, onCreate }) => {
  const [newStream, setNewStream] = useState(initialStream);

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(newStream);
  };

  const { title, parentSection } = newStream;
  const isSubmitable = parentSection && title.length > 0;

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <StreamEditor stream={newStream} onChange={setNewStream} />
        <div className={s.buttons}>
          <div className={s.leftButtons} />
          <div className={s.rightButtons}>
            <Button onClick={onCancel} variant="secondary" type="button">
              Avbryt
            </Button>
            <Button variant="primary" type="submit" disabled={!isSubmitable}>
              Spara
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

CreateStreamEditor.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CreateStreamEditor;
