import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import StreamEditor from './StreamEditor';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { validateSection, validateParentSection } from 'utils/streamSection';
import ShowForAdmin from 'components/ShowForAdmin/ShowForAdmin';
import s from './UpdateStreamEditor.module.scss';

const UpdateStreamEditor = ({
  stream,
  streamId,
  onCancel,
  onUpdate,
  onDelete,
}) => {
  const [newStream, setNewStream] = useState(stream);
  const [deleteStreamConfirmOpen, setDeleteStreamConfirmOpen] = useState(false);

  const deleteStreamEnabled = Boolean(
    Number(process.env.REACT_APP_DELETE_STREAM_ENABLED)
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(newStream);
  };

  const { title, parentSection, section } = newStream;

  const isValidParentSection =
    validateParentSection(parentSection) === parentSection;
  const isValidSection = validateSection(section) === section;

  const isSubmitable =
    isValidParentSection && isValidSection && title.length > 0;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <StreamEditor
          title="Inställningar"
          stream={newStream}
          streamId={streamId}
          onChange={(newStream) => setNewStream(newStream)}
        />

        <div className={s.buttons}>
          {deleteStreamEnabled && (
            <ShowForAdmin>
              <div className={s.leftButtons}>
                <Button
                  onClick={() => setDeleteStreamConfirmOpen(true)}
                  variant="danger"
                  type="button"
                >
                  Ta bort Ström
                </Button>
              </div>
            </ShowForAdmin>
          )}
          <div className={s.rightButtons}>
            {stream.isActive && (
              <Button onClick={onCancel} variant="secondary" type="button">
                Avbryt
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              disabled={!isSubmitable}
              data-testid="streamSettingsSubmit"
            >
              Spara
            </Button>
          </div>
        </div>
      </form>
      <ConfirmDialog
        title="Ta bort ström?"
        confirmText="Ja, ta bort ström"
        open={deleteStreamConfirmOpen}
        onCancel={() => setDeleteStreamConfirmOpen(false)}
        onConfirm={onDelete}
      >
        Du håller på att ta bort en ström. DETTA GÅR INTE ATT ÅNGRA! Är du säker
        på att du vill fortsätta?
      </ConfirmDialog>
    </React.Fragment>
  );
};

UpdateStreamEditor.propTypes = {
  stream: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  streamId: PropTypes.string.isRequired,
};

export default UpdateStreamEditor;
