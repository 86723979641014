import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import s from './LoadMoreButton.module.scss';

type LoadMoreButtonProps = { onClick: () => void };

const LoadMoreButton = ({ onClick }: LoadMoreButtonProps) => (
  <div className={s.loadMoreButtonWrapper}>
    <Button variant="dark" fullWidth onClick={onClick}>
      Ladda fler
    </Button>
  </div>
);

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
