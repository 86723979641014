import { DirektcenterWebpage } from 'Types';
import s from './AttachmentWebpage.module.scss';

const IMAGE_PROXY_URL = process.env.REACT_APP_IMAGE_PROXY_URL;
const ASTRID_IMAGE_PROXY_URL = 'https://www.svtstatic.se/image-news';
const DEFAULT_WIDTH = 140;

const formatWebpageImageUrl = (attachment: DirektcenterWebpage) => {
  if (attachment.astridImage) {
    const focalPoint = { x: 0.5, y: 0.5, ...attachment.astridImage.focalPoint };
    return `${ASTRID_IMAGE_PROXY_URL}/${DEFAULT_WIDTH}/1:1/${focalPoint.x}/${focalPoint.y}/${attachment.astridImage.id}`;
  }

  if (attachment.escenicImageId && attachment.escenicImageVersion) {
    const type = 'square';
    const queryString = 'format=auto&ratio=1'; // correct queryString?
    return `${IMAGE_PROXY_URL}/${type}/${DEFAULT_WIDTH}/${attachment.escenicImageId}/${attachment.escenicImageVersion}?${queryString}`;
  }

  if (attachment.image) {
    return attachment.image
      .replace('ratio=1.91', 'ratio=1')
      .replace('/custom/', '/square/')
      .replace('/large/', '/square/');
  }
};

type AttachmentVideoProps = {
  attachment: DirektcenterWebpage;
};

const AttachmentWebpage = ({ attachment }: AttachmentVideoProps) => {
  const PotentialImage = attachment.image && (
    <div className={s.imageWrapper}>
      <img className={s.image} src={formatWebpageImageUrl(attachment)} alt="" />
    </div>
  );
  return (
    <article className={s.root}>
      <a
        className={s.link}
        href={attachment.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {PotentialImage}
        <div className={s.textWrapper}>
          <h2 className={s.title}>{attachment.title}</h2>
        </div>
        <div className={s.readMore}>
          <span className="visuallyhidden">Gå till artikel</span>
        </div>
      </a>
    </article>
  );
};

AttachmentWebpage.validate = (attachment: DirektcenterWebpage) =>
  Boolean(
    attachment.type === AttachmentWebpage.type &&
      attachment.url &&
      attachment.title
  );

AttachmentWebpage.type = 'webpage';

export default AttachmentWebpage;
