import React from 'react';
import PropTypes from 'prop-types';
import s from './PageHeader.module.scss';

const PageHeader = ({ children }) => (
  <header className={s.header}>{children}</header>
);
PageHeader.propTypes = { children: PropTypes.node.isRequired };

export default PageHeader;
