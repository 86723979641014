import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import betterTrim from '../../utils/betterTrim';
import UserContext from '../../contexts/UserContext';
import AttachmentImage from '../Attachment/AttachmentImage';
import AttachmentVideo from '../Attachment/AttachmentVideo';
import AttachmentWebpage from '../Attachment/AttachmentWebpage';
import htmlSerializer from './TextEditor/htmlSerializer';
import { GUEST, EDITOR, ADMIN, VISITOR } from '../../utils/roles';

export const TEXT_MAX_LENGTH = 4000;

/**
 * Helpers
 */
const emptySlateValue = htmlSerializer.deserializeString('');

const initialPostInProgress = { slateValue: emptySlateValue, attachment: null };
const initialPostInProgressNoAttachment = { slateValue: emptySlateValue };

const postToPostInProgress = (post, isAttachmentEnabled) => {
  let postInProgress = {
    slateValue:
      (post && htmlSerializer.deserializeString(post.body)) || emptySlateValue,
  };
  if (isAttachmentEnabled) {
    postInProgress.attachment = (post && post.attachment) || null;
  }
  return postInProgress;
};

/**
 * Selectors
 */
export const selectPost = (state) => {
  const serializedBody =
    state.postInProgress.slateValue &&
    state.postInProgress.slateValue.map(htmlSerializer.serialize).join('');

  return {
    ...state.originalPost,
    author: state.author,
    attachment: state.postInProgress.attachment,
    body: serializedBody,
  };
};

export const selectIsValidAttachment = (state) => {
  const attachment = state.postInProgress.attachment;
  if (!attachment) {
    return false;
  }

  switch (attachment.type) {
    case AttachmentImage.type:
      return AttachmentImage.validate(attachment);
    case AttachmentVideo.type:
      return AttachmentVideo.validate(attachment);
    case AttachmentWebpage.type:
      return AttachmentWebpage.validate(attachment);
    default:
      return false;
  }
};

let memoizedTextualContent = {
  value: null,
  dependency: null,
};

const selectTextualContent = (state) => {
  if (memoizedTextualContent.dependency !== state.postInProgress.slateValue) {
    const htmlPost =
      state.postInProgress.slateValue &&
      state.postInProgress.slateValue.map(htmlSerializer.serialize).join('');

    memoizedTextualContent.value = sanitizeHtml(htmlPost, {
      allowedTags: [],
      allowedAttributes: {},
    });
    memoizedTextualContent.dependency = state.postInProgress.slateValue;
  }
  return memoizedTextualContent.value;
};

export const selectIsTextTooLong = (state) => {
  const textualContent = selectTextualContent(state);
  return textualContent && textualContent.length > TEXT_MAX_LENGTH;
};

const hasEitherTextOrAttachment = (state) => {
  const textualContent = selectTextualContent(state);
  const hasAnyText = Boolean(betterTrim(textualContent));
  if (hasAnyText) {
    const noAttachmentOrValidAttachent =
      !state.postInProgress.attachment || selectIsValidAttachment(state);
    return noAttachmentOrValidAttachent;
  }

  return selectIsValidAttachment(state);
};

export const selectIsSubmitable = (state) => {
  const textTooLong = selectIsTextTooLong(state);
  if (textTooLong) {
    return false;
  }

  return hasEitherTextOrAttachment(state);
};

/**
 * Actions
 */
export const postEditorActions = {
  SET_SLATE_VALUE: 'SET_SLATE_VALUE',
  RESET_ATTACHMENT: 'RESET_ATTACHMENT',
  SET_ATTACHMENT: 'SET_ATTACHMENT',
  RESET_POST_IN_PROGRESS: 'RESET_POST_IN_PROGRESS',
  MERGE_ATTACHMENT: 'MERGE_ATTACHMENT',
  SET_ORIGINAL_POST: 'SET_ORIGINAL_POST',
  SET_AUTHOR: 'SET_AUTHOR',
  SET_IS_UPLOADING_IMAGE: 'SET_IS_UPLOADING_IMAGE',
};

/**
 * Reducer
 */
const getInitialState = ({
  attachmentEnabled = true,
  attachmentVisible = true,
  toolbarVisible = true,
  isUploadingImage = false,
  post,
}) => {
  return {
    postInProgress: postToPostInProgress(post, attachmentEnabled),
    originalPost: {},
    author: {},
    attachmentEnabled,
    attachmentVisible,
    toolbarVisible,
    isUploadingImage,
  };
};

const reducer = (state, action) => {
  if (!action.type) {
    throw new Error('No action type specified!');
  }

  const {
    SET_SLATE_VALUE,
    RESET_ATTACHMENT,
    SET_ATTACHMENT,
    MERGE_ATTACHMENT,
    RESET_POST_IN_PROGRESS,
    SET_ORIGINAL_POST,
    SET_AUTHOR,
    SET_IS_UPLOADING_IMAGE,
  } = postEditorActions;

  switch (action.type) {
    case RESET_POST_IN_PROGRESS:
      return {
        ...state,
        postInProgress: state.attachmentEnabled
          ? initialPostInProgress
          : initialPostInProgressNoAttachment,
      };
    case SET_SLATE_VALUE:
      return {
        ...state,
        postInProgress: { ...state.postInProgress, slateValue: action.payload },
      };
    case RESET_ATTACHMENT:
      return {
        ...state,
        postInProgress: {
          ...state.postInProgress,
          attachment: initialPostInProgress.attachment,
        },
      };
    case SET_ATTACHMENT:
      return {
        ...state,
        postInProgress: { ...state.postInProgress, attachment: action.payload },
      };
    case SET_ORIGINAL_POST:
      return {
        ...state,
        originalPost: action.payload,
      };
    case SET_AUTHOR:
      return {
        ...state,
        author: action.payload,
      };
    case MERGE_ATTACHMENT:
      return {
        ...state,
        postInProgress: {
          ...state.postInProgress,
          attachment: { ...state.postInProgress.attachment, ...action.payload },
        },
      };
    case SET_IS_UPLOADING_IMAGE:
      return {
        ...state,
        isUploadingImage: action.payload,
      };
    default:
      throw new Error('Action not recognized: ', action.type);
  }
};

/**
 * Context
 */
export const PostEditorContext = createContext();

export const PostEditorProvider = ({
  children,
  attachmentEnabled,
  attachmentVisible,
  toolbarVisible,
  post,
}) => {
  const { user } = useContext(UserContext);
  const [onResetEditor, setOnResetEditor] = useState();

  const [state, dispatcher] = useReducer(
    reducer,
    getInitialState({
      attachmentEnabled,
      attachmentVisible,
      toolbarVisible,
      post,
    })
  );

  useEffect(() => {
    dispatcher({ type: postEditorActions.SET_ORIGINAL_POST, payload: post });
  }, [post]);

  useEffect(() => {
    if (post) {
      dispatcher({ type: postEditorActions.SET_AUTHOR, payload: post.author });
    } else if (user) {
      let author = {
        displayName: user.displayName,
        role: user.role,
      };

      if (user.title) {
        author.title = user.title;
      }

      if (user.avatar) {
        author.avatar = user.avatar;
      }

      dispatcher({
        type: postEditorActions.SET_AUTHOR,
        payload: author,
      });
    } else {
      throw new Error('Post or author must be set!');
    }
  }, [post, user]);

  return (
    <PostEditorContext.Provider
      value={{ state, dispatcher, onResetEditor, setOnResetEditor }}
    >
      {children}
    </PostEditorContext.Provider>
  );
};

PostEditorProvider.propTypes = {
  children: PropTypes.node.isRequired,
  attachmentEnabled: PropTypes.bool,
  attachmentVisible: PropTypes.bool,
  toolbarVisible: PropTypes.bool,
  post: PropTypes.shape({
    body: PropTypes.string,
    attachment: PropTypes.object,
    author: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      role: PropTypes.oneOf([GUEST, EDITOR, ADMIN, VISITOR]).isRequired,
      title: PropTypes.string,
      avatar: PropTypes.shape({
        path: PropTypes.string,
      }),
    }).isRequired,
  }),
  author: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    title: PropTypes.string,
    avatar: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
};
