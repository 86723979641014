import { initializeApp, getApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
};

export default function getInitializedFirestore() {
  let app;

  try {
    app = getApp();
  } catch {
    initializeApp(firebaseConfig);
    app = getApp();
  }
  const db: Firestore = getFirestore(app);
  return db;
}
