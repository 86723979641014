import React, { useContext } from 'react';

import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import ContactLink from 'components/ContactLink/ContactLink';
import useMenuDropdownEvents from 'components/MenuDropdown/useMenuDropdownEvents';
import UserContext from 'contexts/UserContext';
import Avatar from 'components/Avatar/Avatar';

import s from './AppBar.module.scss';
import { MenuAction, User } from 'Types';

const isStage =
  process.env.REACT_APP_STAGE &&
  parseInt(process.env.REACT_APP_STAGE, 10) === 1;

const isDev = process.env.NODE_ENV === 'development';

type AppBarProps = {
  user: User;
  logout: () => any;
};

const AppBar = ({ user, logout }: AppBarProps) => {
  const {
    isOpen,
    toggleButtonRef,
    menuRef,
    wrapperRef,
    handleMenuButtonClick,
    handleMenuButtonKeydown,
  } = useMenuDropdownEvents();
  const { isAdminOrEditor, setProfileDialogVisible } = useContext(UserContext);

  const buttonId = 'userMenu';

  let adminOrEditorActions: MenuAction[] = [
    {
      title: 'Frågor och feedback?',
      callback: () => {
        window.open(
          'https://teams.microsoft.com/l/channel/19:fc7a58260b864873a9d34c9e95918fcc@thread.skype/Direktcenter?groupId=f8d6da46-fbb2-4dd1-ad6b-0f73bc038a42&tenantId=f5d227e4-4de5-4600-9015-df5f33e77f2f',
          '_blank',
          'noopener,noreferrer'
        );
      },
    },
    {
      title: 'Redigera byline',
      callback: () => {
        setProfileDialogVisible(true);
      },
    },
  ];

  const logoutAction: MenuAction = {
    title: 'Logga ut',
    callback: logout,
  };

  adminOrEditorActions.push(logoutAction);

  const guestActions = [logoutAction];

  return (
    <div className={s.root}>
      <div className={s.logo}>
        <span className={s.slash} aria-hidden="true">
          /
        </span>
        {isAdminOrEditor ? <a href="/streams">Direktcenter</a> : 'Direktcenter'}
        {isStage && <strong>&nbsp;STAGE</strong>}
        {isDev && <strong>&nbsp;DEV</strong>}
      </div>

      {user && (
        <div className={s.content}>
          {isAdminOrEditor && <ContactLink />}
          <div className={s.userInfo} ref={wrapperRef}>
            <button
              className={s.displayNameButton}
              ref={toggleButtonRef}
              id={buttonId}
              aria-label={`${user.displayName}, användaralternativ`}
              aria-haspopup="menu"
              aria-expanded={isOpen ? 'true' : 'false'}
              onClick={handleMenuButtonClick}
              onKeyDown={handleMenuButtonKeydown}
            >
              <div>
                <Avatar
                  role={user.role}
                  displayName={user.displayName}
                  avatar={user.avatar}
                  darkMode
                />
              </div>
              <span className={s.displayName}>{user.displayName}</span>
              <DownArrowIcon className={s.downArrowIcon} />
            </button>
            <MenuDropdown
              className={s.userActions}
              actions={isAdminOrEditor ? adminOrEditorActions : guestActions}
              ariaLabelledby={buttonId}
              isOpen={isOpen}
              ref={menuRef}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppBar;
