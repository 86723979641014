import React, { useState, useEffect, useContext, createContext } from 'react';
import api, { apiEvents } from 'services/api';

import NotificationContext from './NotificationContext';

type NoOp = () => void;
type ConnectivityContextState = {
  online: boolean | null;
  setOnline: React.Dispatch<React.SetStateAction<boolean | null>> | NoOp;
  offline: boolean;
};

const ConnectivityContext = createContext<ConnectivityContextState>({
  online: null,
  setOnline: () => {},
  offline: false,
});

const ID = 'CONNECTIVITY_NOTIFICATION';
const NETWORK_ERROR_NOTIFICATION = {
  id: ID,
  type: 'error' as 'error',
  message: 'Anslutning förlorad. Försöker igen... Är du online? 🤔',
};

type ConnectivityContextProviderProps = {
  children: React.ReactNode;
};
export const ConnectivityContextProvider = ({
  children,
}: ConnectivityContextProviderProps) => {
  const [online, setOnline] = useState<null | boolean>(null);
  const { setNotification, unsetNotification, notifications } =
    useContext(NotificationContext);

  const connectivityNotification = notifications.get(ID);

  useEffect(() => {
    const removeOnError = api.on(apiEvents.ERROR, () => {
      setOnline(false);
      if (!connectivityNotification) {
        setNotification(NETWORK_ERROR_NOTIFICATION);
      }
    });

    const removeOnSuccess = api.on(apiEvents.SUCCESS, () => {
      setOnline(true);
      if (connectivityNotification) {
        unsetNotification(ID);
      }
    });

    return () => {
      removeOnError();
      removeOnSuccess();
    };
  }, [connectivityNotification, setNotification, unsetNotification]);

  return (
    <ConnectivityContext.Provider
      value={{ online, setOnline, offline: online === false }}
    >
      {children}
    </ConnectivityContext.Provider>
  );
};

export default ConnectivityContext;
