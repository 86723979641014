import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/Input';
import Attachment from 'components/Attachment/Attachment';
import Loader from 'components/Loader/Loader';

const WebpageEditor = ({
  inputUrl,
  handleUrlChange,
  inputError,
  attachment,
  loadingOgData,
}) => (
  <React.Fragment>
    <Input
      autoFocus
      placeholder="https://www.svt.se"
      label="URL till SVT-material"
      type="text"
      inputMode="url"
      value={inputUrl}
      onChange={handleUrlChange}
      error={inputError}
    />
    {attachment && !loadingOgData && <Attachment attachment={attachment} />}
    {loadingOgData && <Loader>Laddar webbsida...</Loader>}
  </React.Fragment>
);

WebpageEditor.propTypes = {
  inputUrl: PropTypes.string.isRequired,
  handleUrlChange: PropTypes.func.isRequired,
  inputError: PropTypes.string,
  attachment: PropTypes.object,
  loadingOgData: PropTypes.bool,
};

export default WebpageEditor;
