import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as RemoveIcon } from 'img/trash_icon.svg';
import { ReactComponent as WarningIcon } from 'img/warning-solid.svg';

import Button from 'components/Button/Button';
import AlwaysOnTop from 'components/AlwaysOnTop/AlwaysOnTop';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import useMenuDropdownEvents from 'components/MenuDropdown/useMenuDropdownEvents';
import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';

import s from './RemoveSplitButton.module.scss';

const RemoveSplitButton = ({
  remove,
  postId,
  reportAnnoying,
  reportThreat,
}) => {
  const {
    isOpen,
    toggleButtonRef,
    menuRef,
    wrapperRef,
    handleMenuButtonClick,
    handleMenuButtonKeydown,
  } = useMenuDropdownEvents();

  const actions = [
    {
      title: 'Blockera besökare',
      callback: reportAnnoying,
    },
    {
      title: (
        <div className={s.titleWithIcon}>
          <WarningIcon /> <span>Anmäl som hot</span>
        </div>
      ),
      callback: reportThreat,
    },
  ];

  const buttonId = postId + '-buttonId';
  const potentialTooltip = isOpen ? undefined : 'Störande/Hot';

  return (
    <div className={s.root}>
      <AlwaysOnTop
        component={Button}
        className={s.leftButton}
        variant="light"
        leftRound
        onClick={remove}
        tooltip="Radera"
      >
        <RemoveIcon />
        Ta bort
      </AlwaysOnTop>

      <div className={cn(s.removeActionsWrapper)} ref={wrapperRef}>
        <Button
          ref={toggleButtonRef}
          className={s.rightButton}
          variant="light"
          rightRound
          id={buttonId}
          aria-label="Radera och markera inlägg"
          aria-haspopup="menu"
          aria-expanded={isOpen}
          onClick={handleMenuButtonClick}
          onKeyDown={handleMenuButtonKeydown}
          tooltip={potentialTooltip}
        >
          <DownArrowIcon />
        </Button>
        <MenuDropdown
          ref={menuRef}
          className={s.actionList}
          actions={actions}
          isOpen={isOpen}
          ariaLabelledby={buttonId}
        />
      </div>
    </div>
  );
};

RemoveSplitButton.propTypes = {
  remove: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  reportAnnoying: PropTypes.func.isRequired,
  reportThreat: PropTypes.func.isRequired,
};

export default RemoveSplitButton;
