import { useCallback, useState } from 'react';

import areEqual from 'deep-equal';

// https://www.smashingmagazine.com/2021/05/building-wysiwyg-editor-javascript-slatejs/#link-editor-menu

export default function useSelection(editor) {
  const [selection, setSelection] = useState(editor.selection);
  const setSelectionOptimized = useCallback(
    (newSelection) => {
      if (areEqual(selection, newSelection)) {
        return;
      }
      setSelection(newSelection);
    },
    [setSelection, selection]
  );

  return [selection, setSelectionOptimized];
}
