import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import MaterialUiBackdrop from '@mui/material/Backdrop';
import FocusLock from 'react-focus-lock';

import Button from 'components/Button/Button';
import { ReactComponent as CloseIcon } from 'img/close.svg';
import s from './Overlay.module.scss';

function getPortalContainer() {
  const portalContainer = document.getElementById('portal-container');
  if (!portalContainer) {
    const div = document.createElement('div');
    div.setAttribute('id', 'portal-container');
    document.body.appendChild(div);
  }
  return document.getElementById('portal-container');
}

const Overlay = (props) => {
  const { children, onClick, open, title } = props;
  const refFocusLock = React.useRef(null);
  const refDialog = React.useRef(null);
  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        onClick();
        window.removeEventListener('keydown', closeOnEsc);
      }
    };

    if (open) {
      window.addEventListener('keydown', closeOnEsc);
    } else {
      window.removeEventListener('keydown', closeOnEsc);
    }
    return () => window.removeEventListener('keydown', closeOnEsc);
  }, [onClick, open]);

  // patch issue with aria-hidden being set to true on backdrop
  // https://github.com/mui-org/material-ui/issues/19450
  // TODO: replace backdrop with a custom component
  useEffect(() => {
    if (open && refDialog.current) {
      Array.from(
        refDialog.current.getElementsByClassName('MuiBackdrop-root')
      ).forEach((el) => {
        el.setAttribute('aria-hidden', 'false');
      });
    }
  }, [open, refDialog]);

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className={s.root}
          role="dialog"
          aria-label={title}
          aria-hidden={!open}
          ref={refDialog}
        >
          <FocusLock shards={[refFocusLock]} disabled={!open}>
            <MaterialUiBackdrop {...props}>
              {children}
              <Button
                className={s.closeButton}
                aria-label="Stäng"
                variant="light"
                onClick={onClick}
                round
              >
                <CloseIcon />
              </Button>
            </MaterialUiBackdrop>
          </FocusLock>
        </div>,
        getPortalContainer()
      )}
    </>
  );
};

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Overlay;
