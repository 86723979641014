import React from 'react';
import { useNavigate } from 'react-router-dom';

import withAuthentication from 'components/withAuthentication';
import withGuestAuthorization from 'components/withGuestAuthorization';
import Module from 'components/Module/Module';
import CreateStreamEditorContainer from 'components/StreamEditor/CreateStreamEditorContainer';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

const CreateStreamPage = () => {
  const navigate = useNavigate();

  const gotoStreams = () => {
    navigate('/streams/');
  };

  const onDidCreate = () => {
    gotoStreams();
  };

  return (
    <PageContent>
      <PageMainContainer>
        <PageTitle>Skapa ny ström</PageTitle>

        <Module>
          <CreateStreamEditorContainer
            onDidCreate={onDidCreate}
            onDidCancel={gotoStreams}
          />
        </Module>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(withGuestAuthorization(CreateStreamPage));
