import piexif from 'piexifjs';

/**
 * Convert a dataUrl to a jpeg file object.
 * @param dataUrl
 */
const dataUrlToJpegFile = (dataUrl) => {
  const byteString = atob(dataUrl.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ia], { type: 'image/jpeg' });
  return new File([blob], 'image.jpg');
};

/**
 * Ascync wrapper for FileReader.
 */
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Strip exif data from jpg files
 *
 * Without removal of jpeg metadata:
 * https://svt-direktcenter.imgix.net/9fa39db3e4a2?fm=json
 *
 * With removal of jpeg metadata:
 * https://svt-direktcenter.imgix.net/44a860dc6982?fm=json
 *
 * @param imgFile
 */
const removeJpegMetadata = async (imgFile) => {
  const imageDataUrl = await readFileAsync(imgFile);
  if (imageDataUrl.split(';base64,')[0] !== 'data:image/jpeg') {
    return imgFile;
  }

  try {
    const exifObj = piexif.load(imageDataUrl);
    // Keep the Orientation since iOS only uses EXIF to differentiate between landscape and portrait.
    const orientation =
      exifObj['0th'] && exifObj['0th'][piexif.ImageIFD.Orientation];

    if (orientation) {
      const newExifObj = {
        '0th': { [piexif.ImageIFD.Orientation]: orientation },
      };
      const exifbytes = piexif.dump(newExifObj);
      return dataUrlToJpegFile(piexif.insert(exifbytes, imageDataUrl));
    }

    return dataUrlToJpegFile(piexif.remove(imageDataUrl));
  } catch (error) {
    return imgFile;
  }
};

export default removeJpegMetadata;
