import React from 'react';
import PropTypes from 'prop-types';

import s from './Header.module.scss';

const Header = ({ children }) => (
  <div className={s.root}>
    <h3 className={s.title}>{children}</h3>
  </div>
);

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
