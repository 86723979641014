import cn from 'classnames';
import s from './AttachmentImage.module.scss';
import htmlDecode from 'utils/htmlDecode';
import { DirektcenterImage } from 'Types';

type AttachmentImageProps = {
  attachment: DirektcenterImage;
  pinned: boolean;
};

const AttachmentImage = ({ attachment, pinned }: AttachmentImageProps) => {
  const caption = attachment.caption && htmlDecode(attachment.caption);
  const alt = attachment.alt && htmlDecode(attachment.alt);
  return (
    <figure className={cn(s.root, { [s.pinned]: pinned })}>
      <img
        className={s.img}
        src={`${process.env.REACT_APP_IMAGE_BASE_URL}${attachment.path}?auto=format`}
        alt={alt || undefined}
      />
      {caption && (
        <figcaption data-testid="caption" className={s.figCaption}>
          {caption}
        </figcaption>
      )}
    </figure>
  );
};

AttachmentImage.validate = (imageAttachment: DirektcenterImage) => {
  return Boolean(
    imageAttachment.type === AttachmentImage.type && imageAttachment.path
  );
};

AttachmentImage.type = 'image';

export default AttachmentImage;
