import React, { forwardRef, ChangeEvent, Ref } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDown } from 'img/arrow-down.svg';
import s from './Select.module.scss';

type SelectProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  error?: string;
  required?: boolean;
  children: JSX.Element;
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { value, onChange, label, error, required, children },
    ref: Ref<HTMLSelectElement>
  ) => {
    return (
      <label className={cn(s.root, { [s.hasError]: error })}>
        <div className={s.selectWrapper}>
          <select
            className={s.select}
            value={value}
            onChange={onChange}
            ref={ref}
          >
            {children}
          </select>
          <ArrowDown className={s.arrowDown} />
        </div>

        <span className={s.label}>
          {label}
          {required ? ' *' : ''}
        </span>
        {error && <span className={s.error}>{error}</span>}
      </label>
    );
  }
);

export default Select;
