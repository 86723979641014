import { useRef, useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Module from 'components/Module/Module';
import Button from 'components/Button/Button';
import UserContext from 'contexts/UserContext';
import NotificationContext from 'contexts/NotificationContext';
import logger from 'utils/logger';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import SVTFooter from 'components/SVTFooter/SVTFooter';
import Input from 'components/Input/Input';

const LoginEmailPasswordPage = () => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const navigate = useNavigate();
  const { loginWithEmailAndPassword, setProfileDialogVisible, isLoggedIn } =
    useContext(UserContext);
  const { flashNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState();

  const redirectAfterLogin = (loginTarget) => {
    navigate(encodeURI(loginTarget || '/streams'));
  };

  useEffect(() => {
    const loginUrlParams = new URLSearchParams(window.location.search);
    const loginTarget = loginUrlParams.get('target');

    if (isLoggedIn === true) {
      redirectAfterLogin(loginTarget);
      return;
    }

    if (loginTarget) {
      sessionStorage.setItem('loginTarget', loginTarget);
    }
  });

  const doLogin = async (e) => {
    e.preventDefault();
    if (emailInput.current.value.trim() === '') {
      emailInput.current.focus();
      return;
    }
    if (passwordInput.current.value.trim() === '') {
      passwordInput.current.focus();
      return;
    }

    setLoading(true);
    try {
      const loginResult = await loginWithEmailAndPassword({
        email: emailInput.current.value,
        password: passwordInput.current.value,
      });

      setLoading(false);
      if (loginResult.status === 'success') {
        setProfileDialogVisible(true);

        redirectAfterLogin(sessionStorage.getItem('loginTarget'));
        sessionStorage.removeItem('loginTarget');
      } else {
        flashNotification({
          type: 'error',
          message: 'Fel e-post eller lösenord. Försök igen.',
        });
      }
    } catch (err) {
      setLoading(false);
      logger.error('Failed to login: ', err);
      flashNotification({
        type: 'error',
        message: 'Fel e-post eller lösenord. Försök igen.',
      });
    }
  };

  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title="Logga in" />
        <PageTitle>Logga in</PageTitle>
        <Module loading={loading}>
          <form onSubmit={doLogin}>
            <Input
              id="email"
              name="email"
              label="E-post"
              defaultValue=""
              type="email"
              required
              ref={emailInput}
              autoFocus
            />
            <Input
              id="password"
              name="password"
              label="Lösenord"
              defaultValue=""
              required
              ref={passwordInput}
              type="password"
            />
            <Button variant="primary" type="submit" label="Submit">
              Login
            </Button>
          </form>
        </Module>
      </PageMainContainer>
      <SVTFooter />
    </PageContent>
  );
};

export default LoginEmailPasswordPage;
