import { useContext, useState, useCallback } from 'react';

import betterTrim from 'utils/betterTrim';
import isValidVideoId, { isLikelyDksCode } from 'utils/isValidVideoId';
import AttachmentVideo from 'components/Attachment/AttachmentVideo';
import api from '../../../../services/api';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import SvtVideoEditor from './SvtVideoEditor';
import { trackEvent } from 'utils/statistics';

const SvtVideoEditorContainer = () => {
  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);
  const attachment = postEditorState.postInProgress.attachment;

  const [inputVideoId, setInputVideoId] = useState(
    attachment.svtId || attachment.videoId
  );
  const [loadingVideoData, setLoadingVideoData] = useState(false);
  const [inputError, setInputError] = useState(null);

  const resetAttachment = useCallback(() => {
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: SvtVideoEditorContainer.initialData,
    });
  }, [postEditorDispatcher]);

  const fetchVideoData = useCallback(async (videoId) => {
    const res = await api.fetchVideoData(videoId);

    if (res.status !== 'success') {
      throw new Error(res.message);
    }

    return {
      aspectRatio: res.data.aspectRatio,
      posterImageId: res.data.posterImageId,
      duration: res.data.duration,
      svtId: res.data.svtId,
      escenicVideoId: res.data.legacyId,
      astridImage: res.data.astridImage,
    };
  }, []);

  const reactToUserInputChange = useCallback(
    async (videoIdInput) => {
      setInputError(null);
      try {
        const {
          aspectRatio,
          posterImageId,
          duration,
          svtId,
          escenicVideoId,
          astridImage,
        } = await fetchVideoData(videoIdInput);

        postEditorDispatcher({
          type: postEditorActions.SET_ATTACHMENT,
          payload: {
            type: AttachmentVideo.type,
            aspectRatio,
            posterImageId,
            astridImage,
            videoId: escenicVideoId,
            duration,
            svtId,
          },
        });
      } catch (err) {
        resetAttachment();
        setInputError(
          'Kunde inte hitta videon, kontrollera ID:t och att videon är publicerad.'
        );
      }

      setLoadingVideoData(false);
    },
    [fetchVideoData, postEditorDispatcher, resetAttachment]
  );

  const debouncedReactToUserInputChange = useDebouncedCallback(
    reactToUserInputChange,
    1000
  );

  const handleVideoIdChange = (e) => {
    const videoId = e.target.value;
    resetAttachment();
    setInputError(null);
    setInputVideoId(videoId);

    const trimmedVideoId = betterTrim(videoId);

    if (trimmedVideoId && isLikelyDksCode(trimmedVideoId)) {
      setInputError(
        'Det verkar som om du har fyllt i ett filnamn (DKS-kod), ange ett giltigt SVT-ID eller Escenic-ID i stället.'
      );
      trackEvent({
        name: 'video-id-validation-fail',
        eventValues: {
          validationType: 'likelyDksCode',
          validationValue: trimmedVideoId,
        },
      });
      return;
    }

    if (trimmedVideoId && !isValidVideoId(trimmedVideoId)) {
      setInputError('Ange ett giltigt SVT-ID eller Escenic-ID.');
      trackEvent({
        name: 'video-id-validation-fail',
        eventValues: {
          validationType: 'invalidVideoId',
          validationValue: trimmedVideoId,
        },
      });
      return;
    }

    if (trimmedVideoId) {
      setLoadingVideoData(true);
      debouncedReactToUserInputChange(trimmedVideoId);
    }
  };

  return (
    <SvtVideoEditor
      inputVideoId={inputVideoId}
      handleVideoIdChange={handleVideoIdChange}
      inputError={inputError}
      attachment={AttachmentVideo.validate(attachment) ? attachment : null}
      loadingVideoData={loadingVideoData}
    />
  );
};

SvtVideoEditorContainer.type = 'video-editor';

SvtVideoEditorContainer.initialData = {
  type: AttachmentVideo.type,
  videoId: '',
};

export default SvtVideoEditorContainer;
