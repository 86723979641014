import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isKeyHotkey } from 'is-hotkey';

import Button from 'components/Button/Button';
import TextArea from 'components/TextArea/TextArea';
import Input from 'components/Input/Input';
import isOSX from 'utils/isOSX';
import s from './PendingPostEditor.module.scss';

const isSubmitHotkey = isKeyHotkey('mod+enter');

const TEXT_MAX_LENGTH = 4000;
const TEXT_MIN_LENGTH = 1;
const AUTHOR_NAME_MAX_LENGTH = 255;
const AUTHOR_NAME_MIN_LENGTH = 1;
const MAX_ROWS = 10;

const PendingPostEditor = ({ onSubmit, onCancel, post }) => {
  const [newText, setNewText] = useState(post.text);
  const [newAuthorName, setNewAuthorName] = useState(post.authorName);

  // TODO: use betterTrim!
  const textIsTooLong = newText.length > TEXT_MAX_LENGTH;
  const textIsTooShort = newText.length < TEXT_MIN_LENGTH;
  const authorNameIsTooLong = newAuthorName.length > AUTHOR_NAME_MAX_LENGTH;
  const authorNameIsTooShort = newAuthorName.length < AUTHOR_NAME_MIN_LENGTH;

  const submitDisabled =
    textIsTooLong ||
    textIsTooShort ||
    authorNameIsTooLong ||
    authorNameIsTooShort;

  const textError = textIsTooLong
    ? `Texten är för lång. Maxlängd är ${TEXT_MAX_LENGTH} tecken.`
    : textIsTooShort
    ? `Texten är för kort. Minlängd är ${TEXT_MIN_LENGTH} tecken.`
    : '';

  const authorNameError = authorNameIsTooLong
    ? `Namnet är för långt. Maxlängd är ${AUTHOR_NAME_MAX_LENGTH} tecken.`
    : authorNameIsTooShort
    ? `Namnet är för kort. Minlängd är ${AUTHOR_NAME_MIN_LENGTH} tecken.`
    : '';

  const handleSubmit = (e) => {
    if (!submitDisabled) {
      e.preventDefault();
      submitPost();
    }
  };

  const handleKeyDown = (e) => {
    if (isSubmitHotkey(e) && !submitDisabled) {
      submitPost();
    }
  };

  const submitPost = () => {
    onSubmit({ ...post, text: newText, authorName: newAuthorName });
  };

  const handleTextChange = (event) => {
    setNewText(event.target.value);
  };

  const handleAuthorNameChange = (event) => {
    setNewAuthorName(event.target.value);
  };

  return (
    <form onKeyDown={handleKeyDown} onSubmit={handleSubmit} className={s.root}>
      <div className={s.textEditorAndHeader}>
        <h4 className={s.header}>{'Redigera besökarinlägg'}</h4>
        <TextArea
          id={`text-textArea-${post.id}`}
          label={'Inlägg'}
          rows={3}
          maxRows={MAX_ROWS}
          value={newText}
          onChange={handleTextChange}
          error={textError}
          autoFocus
        />
        <Input
          id={`authorName-input-${post.id}`}
          label={'Namn'}
          value={newAuthorName}
          onChange={handleAuthorNameChange}
          error={authorNameError}
          autoComplete="off"
        />
      </div>
      <div className={s.actions}>
        {onCancel && (
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            className={s.cancelButton}
          >
            {'Avbryt'}
          </Button>
        )}
        <Button
          type="submit"
          variant="primary"
          className={s.submitButton}
          disabled={submitDisabled}
          tooltip={`(${isOSX ? '⌘' : 'Ctrl'}+Enter)`}
        >
          {'Uppdatera'}
        </Button>
      </div>
    </form>
  );
};

PendingPostEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  post: PropTypes.shape({
    text: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PendingPostEditor;
