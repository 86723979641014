import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Timestamp from '../Timestamp';
import s from './GuestsTable.module.scss';

export default function GuestsTableRow({ guest }) {
  if (!guest) {
    return null;
  }

  const mLastRefreshTokenCreatedAt = moment(guest.lastRefreshTokenCreatedAt);
  const mGuestCreatedAt = moment(guest.created_at);
  return (
    <tr key={guest.id}>
      <td className={s.idCell}>{guest.id}</td>
      <td className={s.avatar}>
        {guest.avatar ? (
          <div className={s.avatarImageContainer}>
            <img
              src={process.env.REACT_APP_AVATAR_BASE_URL + guest.avatar.path}
              alt={`${guest.displayName} bylinebild`}
              className={s.avatarImage}
            />{' '}
          </div>
        ) : (
          ''
        )}
      </td>
      <td className={s.displayNameCell}>{guest.displayName}</td>
      <td className={s.titleCell}>{guest.title}</td>
      <td className={s.status}>{guest.status}</td>
      <td className={s.createdAt}>
        <Timestamp mDate={mGuestCreatedAt} variant="iso" />
      </td>
      <td className={s.initiatorEmailCell}>{guest.initiatorEmail}</td>
      <td>
        <div>
          <Link to={`/streams/${guest.streamId}`}>{guest.streamId}</Link>
        </div>
      </td>
      <td>
        <div>{guest.isModerationRequired ? 'Ja' : 'Nej'}</div>
      </td>
      <td className={s.lastRefreshTokenCreatedAtCell}>
        {mLastRefreshTokenCreatedAt.isValid() && (
          <Timestamp mDate={mLastRefreshTokenCreatedAt} variant="iso" />
        )}
      </td>
    </tr>
  );
}

GuestsTableRow.propTypes = {
  guest: PropTypes.object,
};
