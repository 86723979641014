import { useState, useCallback } from 'react';

import logger from 'utils/logger';

export function useLocalStorage(key, initialValue) {
  const [getItem, setInnerItem] = useState(() => {
    try {
      return window.localStorage.getItem(key) ?? initialValue;
    } catch (error) {
      logger.error(error);
      return initialValue;
    }
  });

  const setItem = useCallback(
    (value) => {
      setInnerItem(value);
      try {
        window.localStorage.setItem(key, value);
      } catch (error) {
        logger.error(error);
      }
    },
    [key]
  );

  const removeItem = useCallback(() => {
    setInnerItem(initialValue);
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      logger.error(error);
    }
  }, [key, initialValue]);

  return [getItem, setItem, removeItem];
}

export default useLocalStorage;
