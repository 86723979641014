import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';

function withAdminAuthorization(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const { isAdmin } = useContext(UserContext);

    useEffect(() => {
      if (isAdmin === false) {
        navigate('/streams');
      }
    }, [isAdmin, navigate]);

    if (isAdmin === false) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAdminAuthorization;
