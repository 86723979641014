import React from 'react';
import PropTypes from 'prop-types';

import s from './Description.module.scss';

const Description = ({ children }) => <div className={s.root}>{children}</div>;

Description.propTypes = {
  children: PropTypes.node,
};

export default Description;
