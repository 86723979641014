import { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from 'contexts/UserContext';

const ShowForAdminOrEditorOrNonModeratedGuest = ({ children }) => {
  const { isAdminOrEditor, isUnmoderatedGuest } = useContext(UserContext);
  if (isAdminOrEditor || isUnmoderatedGuest) {
    return children;
  }

  return null;
};

ShowForAdminOrEditorOrNonModeratedGuest.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShowForAdminOrEditorOrNonModeratedGuest;
