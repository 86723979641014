import cn from 'classnames';
import Button from 'components/Button/Button';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import useMenuDropdownEvents from 'components/MenuDropdown/useMenuDropdownEvents';
import { ReactComponent as EllipsisIcon } from 'img/ellipsis.svg';
import s from './MoreActions.module.scss';
import { MenuAction } from 'Types';

type MoreActionsProps = {
  id: string;
  actions: MenuAction[];
  buttonLabel?: string;
  className?: string;
};

const MoreActions = ({
  id,
  actions,
  buttonLabel = 'Åtgärder',
  className,
}: MoreActionsProps) => {
  const {
    isOpen,
    toggleButtonRef,
    menuRef,
    wrapperRef,
    handleMenuButtonClick,
    handleMenuButtonKeydown,
  } = useMenuDropdownEvents();

  const buttonId = `more-actions-${id}`;

  return (
    <div className={cn(s.root, className)} ref={wrapperRef}>
      <Button
        ref={toggleButtonRef}
        className={s.toggleButton}
        variant="secondary"
        round
        id={buttonId}
        aria-label={buttonLabel}
        aria-haspopup="menu"
        aria-expanded={isOpen}
        onClick={handleMenuButtonClick}
        onKeyDown={handleMenuButtonKeydown}
      >
        <EllipsisIcon />
      </Button>
      <MenuDropdown
        ref={menuRef}
        className={s.actionList}
        actions={actions}
        isOpen={isOpen}
        ariaLabelledby={buttonId}
      />
    </div>
  );
};

export default MoreActions;
