import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import withAuthentication from 'components/withAuthentication';
import withAdminAuthorization from 'components/withAdminAuthorization';
import Module from 'components/Module/Module';
import ReportedTable from 'components/ReportedTable/ReportedTable';
import api from '../services/api';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

import Item from 'styleguide/Item/Item';

const sortPosts = (field, doSortAscending) => (postA, postB) => {
  const sortOrderMultiplier = doSortAscending ? 1 : -1;

  if (!postA[field] && postB[field]) {
    return -1 * sortOrderMultiplier;
  }

  if (postA[field] && !postB[field]) {
    return 1 * sortOrderMultiplier;
  }

  if (postA[field] > postB[field]) {
    return 1 * sortOrderMultiplier;
  } else {
    return -1 * sortOrderMultiplier;
  }
};

const ReportedPage = () => {
  const { streamId } = useParams();
  const [reportedForStream, setReportedForStream] = useState([]);
  const [sortField, setSortField] = useState();
  const [doSortAscending, setDoSortAscending] = useState(true);
  const [checkedHideTestStream, setCheckedHideTestStream] = useState(false);
  const [excludedStatuses, setExcludedStatuses] = useState([]);

  const setSortFieldOrToggleDirection = useCallback(
    (newField) => {
      if (sortField === newField) {
        setDoSortAscending(!doSortAscending);
      } else {
        setSortField(newField);
      }
    },
    [doSortAscending, sortField]
  );

  const sortAndSaveData = useCallback(
    ({ data }) => {
      if (!data) {
        return false;
      }

      if (sortField) {
        data.pendingPosts.sort(sortPosts(sortField, doSortAscending));
      }
      setReportedForStream(data.pendingPosts);
    },
    [sortField, doSortAscending]
  );

  const listReportedForStreamId = useCallback(async () => {
    return sortAndSaveData(await api.pendingPostListReported(streamId, 999));
  }, [sortAndSaveData, streamId]);

  const listReportedAll = useCallback(async () => {
    return sortAndSaveData(
      await api.pendingPostListReportedAll(
        999,
        checkedHideTestStream,
        excludedStatuses
      )
    );
  }, [checkedHideTestStream, excludedStatuses, sortAndSaveData]);

  const onChangeExcludeStatus = useCallback(
    (e) => {
      if (e.target.checked) {
        setExcludedStatuses([...excludedStatuses, e.target.value]);
      } else {
        setExcludedStatuses(
          excludedStatuses.filter((val) => val !== e.target.value)
        );
      }
    },
    [excludedStatuses, setExcludedStatuses]
  );

  const onChangeCheckedHideTestStream = useCallback(() => {
    setCheckedHideTestStream(!checkedHideTestStream);
  }, [checkedHideTestStream, setCheckedHideTestStream]);

  useEffect(() => {
    if (streamId) {
      listReportedForStreamId();
    } else {
      listReportedAll();
    }
  }, [streamId, listReportedForStreamId, listReportedAll]);

  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title="Rapporterade och flaggade inlägg" />
        <PageTitle>Rapporterade och flaggade inlägg</PageTitle>
        <Module>
          {!streamId && (
            <>
              <label>
                <input
                  onChange={onChangeCheckedHideTestStream}
                  type="checkbox"
                  id="hideTestStreamCheckbox"
                  name="hideTestStreamCheckbox"
                  checked={checkedHideTestStream}
                />{' '}
                Dölj testströmmen
              </label>{' '}
              <label>
                <input
                  onChange={onChangeExcludeStatus}
                  type="checkbox"
                  name="excludeStatuses"
                  checked={
                    Array.isArray(excludedStatuses) &&
                    excludedStatuses.includes('duplicate')
                  }
                  value="duplicate"
                />{' '}
                Dölj upprepande
              </label>{' '}
              <label>
                <input
                  onChange={onChangeExcludeStatus}
                  type="checkbox"
                  name="excludeStatuses"
                  checked={
                    Array.isArray(excludedStatuses) &&
                    excludedStatuses.includes('reportedAnnoying')
                  }
                  value="reportedAnnoying"
                />{' '}
                Dölj störande
              </label>
            </>
          )}

          <ReportedTable
            pendingPosts={reportedForStream}
            setSortField={setSortFieldOrToggleDirection}
          ></ReportedTable>

          <Item title="Raw data">
            <pre>{JSON.stringify(reportedForStream, null, 2)}</pre>
          </Item>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(withAdminAuthorization(ReportedPage));
