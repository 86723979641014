import { useState } from 'react';
import { svtDateFormat, svtDateFormatFull } from 'utils/date';
import LinkButton from 'components/LinkButton/LinkButton';
import useForceUpdateInterval from 'hooks/useForceUpdateInterval';
import s from './Timestamp.module.scss';
import { Moment } from 'moment';

type TimestampProps = {
  mDate: Moment;
  variant: 'svt' | 'iso';
};

const Timestamp = ({ mDate, variant = 'svt' }: TimestampProps) => {
  // So that all items doesn't rerender simultaneously, causing lags. 10.00 - 11.00 seconds.
  const tenSecondsIsh = Math.floor(Math.random() * 100 + 1000) / 100;
  useForceUpdateInterval(tenSecondsIsh);
  const [showFullSVTFormat, setShowFullSVTFormat] = useState(false);

  switch (variant) {
    case 'iso':
      return <time dateTime={mDate.format()}>{mDate.format('L LT')}</time>;

    case 'svt':
    default:
      return (
        <LinkButton onClick={() => setShowFullSVTFormat(!showFullSVTFormat)}>
          <time dateTime={mDate.format()} className={s.root}>
            {showFullSVTFormat
              ? svtDateFormatFull(mDate)
              : svtDateFormat(mDate)}
          </time>
        </LinkButton>
      );
  }
};

export default Timestamp;
