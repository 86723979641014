import PropTypes from 'prop-types';
import _ from 'lodash';

import ReplyContainer from './ReplyContainer';
import s from './Replies.module.scss';

const Replies = ({ replies = {}, streamId, postId, isEditable }) => {
  const repliesArray = _.chain(replies)
    .map((reply, id) => ({ ...reply, id }))
    .orderBy('createdAt', 'asc')
    .value();
  if (!repliesArray.length) {
    return null;
  }
  return (
    <div className={s.root}>
      {repliesArray.map((reply) => (
        <ReplyContainer
          reply={reply}
          key={reply.id}
          streamId={streamId}
          postId={postId}
          isEditable={isEditable}
        />
      ))}
    </div>
  );
};

Replies.propTypes = {
  replies: PropTypes.object,
  postId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default Replies;
