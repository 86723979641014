import PropTypes from 'prop-types';

export const LinkElement = ({ attributes, element, children }) => {
  return (
    <a {...attributes} href={element.url}>
      {children}
    </a>
  );
};

LinkElement.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  children: PropTypes.array,
};

export const ParagraphElement = ({ attributes, children }) => {
  return <p {...attributes}>{children}</p>;
};

ParagraphElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.array,
};
