import React from 'react';
import PropTypes from 'prop-types';

import PostContainer from 'components/Post/PostContainer';
import Loader from 'components/Loader/Loader';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';
import NoItemsNotice from 'components/NoItemsNotice/NoItemsNotice';
import s from './PostStream.module.scss';

const PostStream = ({
  loading,
  hasMorePosts,
  loadingMorePosts,
  posts,
  streamId,
  isEditable,
  loadMorePosts,
  isHighlightsFeatureEnabled,
}) => {
  if (loading) {
    return <Loader />;
  }
  const showLoadMore = hasMorePosts && !loadingMorePosts;
  return (
    <div className={s.root}>
      <div className={s.postStream}>
        <h2 className="visuallyhidden">Publicerade inlägg</h2>
        {!posts.length && <NoItemsNotice>Inga inlägg</NoItemsNotice>}
        {posts.map((post) => (
          <PostContainer
            key={post.id}
            post={post}
            streamId={streamId}
            isEditable={isEditable}
            isHighlightsFeatureEnabled={isHighlightsFeatureEnabled}
          />
        ))}
      </div>
      {showLoadMore && <LoadMoreButton onClick={loadMorePosts} />}
      {loadingMorePosts && <Loader />}
    </div>
  );
};

PostStream.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.object,
    })
  ),
  hasMorePosts: PropTypes.bool.isRequired,
  loadingMorePosts: PropTypes.bool.isRequired,
  streamId: PropTypes.string.isRequired,
  loadMorePosts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isHighlightsFeatureEnabled: PropTypes.bool,
};

export default PostStream;
