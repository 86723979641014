// Docs for serializing in Slate > 0.50:
// https://docs.slatejs.org/concepts/10-serializing#html
// https://docs.slatejs.org/concepts/10-serializing#deserializing

import { Text } from 'slate';
import escapeHtml from 'escape-html';

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');
  return children;
};

// Does not escape HTML so it can be used for character count
const unsafeSerialize = (node) => {
  if (Text.isText(node)) {
    let string = node.text;
    return string;
  }

  const children = node.children.map((n) => unsafeSerialize(n)).join('');
  return children;
};

const PlainSerializer = {
  serialize,
  unsafeSerialize,
};
export default PlainSerializer;
