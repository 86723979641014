import createDataSdk, {
  TrackCustomEvent,
  TrackViewEvent,
} from '@svt/data-web-sdk';

const VERSION = process.env.REACT_APP_GIT_SHA || '';
const HELIX_ENABLED = Boolean(Number(process.env.REACT_APP_HELIX_ENABLED));
const HELIX_ENV = (() => {
  switch (process.env.REACT_APP_HELIX_ENV) {
    case 'production':
    case 'prod':
      return 'prod';
    case 'stage':
      return 'stage';
    default:
      return 'dev';
  }
})();

export const helixSdk = createDataSdk({
  product: {
    name: 'direktcenter-editor',
    family: 'internt',
    version: VERSION,
  },
  config: {
    env: HELIX_ENV,
    dataStorageConsent: true,
    useReadOnlyCookieHandler: false,
  },
});

export function trackEvent(event: Omit<TrackCustomEvent, 'type'>) {
  if (!HELIX_ENABLED) {
    return;
  }
  try {
    helixSdk.trackCustomEvent({
      ...event,
      type: 'custom',
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while tracking event to Helix', error);
  }
}

export function trackPageView(helix: Omit<TrackViewEvent, 'name'>) {
  if (HELIX_ENABLED) {
    try {
      helixSdk.trackViewEvent({
        ...helix,
        name: 'pageview',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while tracking page view to Helix', error);
    }
  }
}
