import htmlSerializer from '../components/PostEditor/TextEditor/htmlSerializer';
export const sampleImage = '/d57405cffaa9';

export const getPostEditorContext = ({
  attachment = null,
  body = '',
  attachmentEnabled = false,
} = {}) => ({
  value: {
    state: {
      postInProgress: {
        slateValue: htmlSerializer.deserializeString(body),
        attachment,
      },
      attachmentEnabled,
    },
    dispatcher: () => {},
  },
});

export const getSampleStream = ({
  title = 'En fin ström',
  timeOffset = 0,
  isActive = true,
} = {}) => ({
  id: `${Math.random(1)}`,
  title: title,
  createdAt: {
    seconds: Math.round(new Date().getTime() / 1000) + timeOffset,
  },
  isActive: isActive,
});

export const sampleHtml =
  '<p>Therese Johaug gjorde i somras succé då hon vann <strong>10 000 meter</strong> på norska mästerskapen, och har även öppnat för att springa den distansen vid EM nästa sommar.</p><p><em>— Jag har aldrig haft så mycket mjölksyra</em></p><p>Kommenterade stjärnan på <a href="#">sin instagram</a>.</p>';

export const getSamplePost = ({
  userRole = 'editor',
  body = sampleHtml,
  pinned = false,
  isEditable = true,
  isEditing = false,
  isReplying = false,
  includeReplies = false,
} = {}) => ({
  post: {
    body: body,
    createdAt: {
      seconds: Math.round(new Date().getTime() / 1000),
    },
    pinned: pinned,
    replies: includeReplies
      ? { 1: getSampleReplyData(), 2: getSampleReplyData() }
      : {},
    id: '123',
    author: {
      role: userRole,
      displayName: 'John Doe',
    },
  },
  removePost: () => {},
  updatePost: () => {},
  togglePin: () => {},
  createReply: () => {},
  unsetIsEditing: () => {},
  setIsEditing: () => {},
  setIsReplying: () => {},
  unsetIsReplying: () => {},
  isEditing: isEditing,
  isReplying: isReplying,
  streamId: '123',
  isEditable: isEditable,
});

export const getSampleReplyData = ({
  userRole = 'editor',
  body = sampleHtml,
} = {}) => ({
  body: body,
  createdAt: {
    seconds: Math.round(new Date().getTime() / 1000),
  },
  author: {
    role: userRole,
    displayName: 'John Doe',
  },
});

export const getSampleReply = ({ isEditing = false, userRole, body } = {}) => ({
  reply: getSampleReplyData({ userRole, body }),
  removeReply: () => {},
  updateReply: () => {},
  setIsEditing: () => {},
  unsetIsEditing: () => {},
  isEditing: isEditing,
});

export const getSamplePendingPost = ({
  loading = false,
  isReplying = false,
  isActive = false,
  isChangingApprovedForGuest = false,
  streamHasGuests = false,
} = {}) => ({
  post: {
    createdAt: {
      seconds: Math.round(new Date().getTime() / 1000),
    },
    text: 'Det fungerar ganska bra men störning av prat förekommer hela tiden. Man är inte så produktiv när det pratas från olika håll. Det kan tidvis vara svårt att koncentrera sig men jag är glad över att ha en egen arbetsplats och inte flexikontor där man aldrig vet var man ska sitta och lägga tid på att installera sig varje dag. Det är bra på ett socialt sätt med öppet kontorslandskap.',
    authorName: 'Mariann',
    isApprovedForGuest: true,
  },
  publish: () => {},
  publishWithReply: () => {},
  remove: () => {},
  setIsApprovedForGuest: () => {},
  isChangingApprovedForGuest: isChangingApprovedForGuest,
  reply: () => {},
  cancelReply: () => {},
  isReplying: isReplying,
  isActive: isActive,
  loading: loading,
  streamHasGuests: streamHasGuests,
});
