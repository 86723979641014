import { useContext } from 'react';
import UserContext from 'contexts/UserContext';

type ShowForAdminOrEditorProps = {
  children: JSX.Element | null;
};

const ShowForAdminOrEditor = ({ children }: ShowForAdminOrEditorProps) => {
  const { isAdminOrEditor } = useContext(UserContext);
  if (isAdminOrEditor) {
    return children;
  }

  return null;
};

export default ShowForAdminOrEditor;
