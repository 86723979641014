import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import Tooltip from 'components/Tooltip/Tooltip';
import { GUEST } from 'utils/roles';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CopyToClipboardField from 'components/CopyToClipboardField/CopyToClipboardField';
import { ReactComponent as RemoveIcon } from 'img/trash_icon.svg';
import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';

import s from './GuestListItem.module.scss';

const MODERATED_GUEST_INFO =
  'Gästen får enbart se utvalda inkommande besökarinlägg';
const UNMODERATED_GUEST_INFO = 'Gästen får se alla inkommande besökarinlägg';

const GuestListItem = ({
  guest,
  showOpen,
  onDeleteGuest,
  onShowInviteLink,
  onCloseAllGuestListItems,
}) => {
  const [deleteGuestConfirmOpen, setDeleteGuestConfirmOpen] = useState(false);

  const generateTokenUrl = (token) => {
    const editorUrl = process.env.REACT_APP_EDITOR_URL;
    return `${editorUrl}/guest/${token}`;
  };

  const GuestDescription = (
    <div className={s.nameTitleRemove}>
      <Avatar
        role={GUEST}
        displayName={guest.displayName}
        avatar={guest.avatar}
        enablePreview
      />

      <div className={s.displayNameAndTitle}>
        <div className={s.displayName}>{guest.displayName}</div>
        <div className={s.title}>{guest.title}</div>
        <Tooltip className={s.tooltip}>
          {guest.isModerationRequired
            ? MODERATED_GUEST_INFO
            : UNMODERATED_GUEST_INFO}
        </Tooltip>
      </div>

      <button
        className={s.removeButton}
        onClick={() => setDeleteGuestConfirmOpen(true)}
        data-testid="guestRemoveButton"
        title="Radera gästen"
      >
        <RemoveIcon />
      </button>
    </div>
  );

  const PotentialListItemOpened = () => {
    return (
      showOpen && (
        <li
          key={guest.token}
          className={cn([s.listItem, s.openedListItem])}
          data-testid="guestListItem"
        >
          <div className={s.listItemHeader}>
            {GuestDescription}
            <div>
              <button
                className={cn([s.showLinkButton, s.showLinkButtonOpen])}
                onClick={onCloseAllGuestListItems}
                type="button"
              >
                Skicka länk
                <DownArrowIcon className={s.downArrowIcon} />
              </button>
            </div>
          </div>
          <div className={s.moderationInfo}>
            {guest.isModerationRequired
              ? MODERATED_GUEST_INFO
              : UNMODERATED_GUEST_INFO}
          </div>
          <div>
            <h3>Be gästen logga in via länken</h3>
          </div>
          <CopyToClipboardField
            value={generateTokenUrl(guest.token)}
            label="Inbjudningslänk"
            buttonText="Kopiera länk"
          />
        </li>
      )
    );
  };

  const PotentialListItemClosed = () =>
    !showOpen && (
      <li className={s.listItem} key={guest.token}>
        <div className={s.listItemHeader}>
          {GuestDescription}
          <div>
            <button
              className={s.showLinkButton}
              onClick={() => onShowInviteLink(guest.id)}
            >
              Skicka länk
              <DownArrowIcon className={s.downArrowIcon} />
            </button>
          </div>
        </div>
      </li>
    );

  return (
    <React.Fragment>
      <PotentialListItemOpened />
      <PotentialListItemClosed />
      <ConfirmDialog
        title="Ta bort gäst?"
        confirmText="Ta bort"
        open={deleteGuestConfirmOpen}
        onCancel={() => setDeleteGuestConfirmOpen(false)}
        onConfirm={() => onDeleteGuest(guest.id)}
      >
        Är du säker på att du vill ta bort {guest.displayName}?
      </ConfirmDialog>
    </React.Fragment>
  );
};

GuestListItem.propTypes = {
  guest: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    title: PropTypes.string,
    id: PropTypes.number,
    avatar: PropTypes.shape({
      path: PropTypes.string,
    }),
    isModerationRequired: PropTypes.bool,
  }),
  showOpen: PropTypes.bool,
  onDeleteGuest: PropTypes.func.isRequired,
  onShowInviteLink: PropTypes.func.isRequired,
  onCloseAllGuestListItems: PropTypes.func.isRequired,
};

export default GuestListItem;
