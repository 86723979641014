type videoIdType = string;

// Even though really old Escenic-ID:s might be even shorter
const isEscenicId = (videoId: videoIdType) =>
  Boolean(videoId.match(/^[0-9]{8,10}$/));

// This is a very genereous definition of SVT-ID
const isSvtId = (videoId: videoIdType) =>
  Boolean(videoId.match(/^[a-zA-Z0-9]{7,21}$/));

export const isLikelyDksCode = (videoId: videoIdType) =>
  Boolean(videoId.match(/^[A-Z]{1,2}-/));

const isValidVideoId = (videoId: videoIdType) => {
  return isEscenicId(videoId) || isSvtId(videoId);
};

export default isValidVideoId;
