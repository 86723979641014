import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import s from './TextArea.module.scss';

type TextAreaProps = {
  id?: string;
  value?: string;
  label: string;
  error?: string;
  className?: string;
  rows?: number;
  maxRows?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      className,
      rows = 3,
      maxRows = 15,
      error,
      ...restProps
    }: TextAreaProps,
    ref
  ) => {
    return (
      <label className={cn(s.root, { [s.hasError]: error }, className)}>
        <TextareaAutosize
          ref={ref}
          rows={rows}
          maxRows={maxRows}
          className={s.textArea}
          {...restProps}
        />
        <span className={s.label}>{label}</span>
        {error && <span className={s.error}>{error}</span>}
      </label>
    );
  }
);

export default TextArea;
