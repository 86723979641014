import axios from 'axios';
import api from 'services/api';
import removeJpegMetadata from 'utils/removeJpegMetadata';
import { trackEvent } from 'utils/statistics';
import logger from './logger';

const uploadImageFile = async (file, flashNotification) => {
  const trackImageUploadError = (fileName, errorType) => {
    trackEvent({
      name: 'upload-image-fail',
      eventValues: {
        fileName,
        errorType,
      },
    });
  };

  const flashError = (message) => {
    flashNotification({
      type: 'error',
      message,
    });
  };

  const { data, status } = await api.getFileUploadUrl();
  // Does FAIL ever happen?

  if (status === 'fail') {
    trackImageUploadError(file.name, 'api-status-fail');
    flashError('Kände inte igen filändelsen. Vänligen ladda upp en bild');
    return;
  }

  if (status === 'error') {
    trackImageUploadError(file.name, 'api-status-error');
    flashError(
      'Tyvärr fungerar inte bilduppladdningen just nu. Vänligen försök igen'
    );
    return;
  }

  const strippedFile = await removeJpegMetadata(file);

  try {
    await axios.put(data.uploadUrl, strippedFile);
  } catch (error) {
    trackImageUploadError(file.name, 'image-upload-error');
    flashError(
      'Tyvärr fungerar inte bilduppladdningen just nu. Vänligen försök igen'
    );
    return;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_IMAGE_BASE_URL}${data.filePath}?fm=json`
    );
    const imageJsonData = response.data;

    const isCorruptImgixResponse = !(imageJsonData instanceof Object);
    if (isCorruptImgixResponse) {
      throw new Error('Image upload response corrupt');
    }

    return {
      path: data.filePath,
      originalWidth: imageJsonData.PixelWidth,
      originalHeight: imageJsonData.PixelHeight,
    };
  } catch (error) {
    logger.error(error);

    trackImageUploadError(file.name, 'image-upload-error');
    flashError([
      'Någonting gick fel med bilduppladdningen.',
      'Vänligen kontrollera filformatet och försök igen',
    ]);

    return;
  }
};

export default uploadImageFile;
