import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import s from './Tooltip.module.scss';

type TooltipProps = {
  children: ReactNode;
  className?: string;
};

const Tooltip: FC<TooltipProps> = ({ children, className }) => (
  <div className={cn(s.root, className)}>{children}</div>
);

export default Tooltip;
