import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import withAuthentication from 'components/withAuthentication';
import withAdminAuthorization from 'components/withAdminAuthorization';
import Module from 'components/Module/Module';
import UsersTable from 'components/UsersTable/UsersTable';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

import api from '../services/api';

const sortPosts = (field, doSortAscending) => (postA, postB) => {
  const sortOrderMultiplier = doSortAscending ? 1 : -1;

  if (!postA[field] && postB[field]) {
    return -1 * sortOrderMultiplier;
  }

  if (postA[field] && !postB[field]) {
    return 1 * sortOrderMultiplier;
  }

  if (postA[field] > postB[field]) {
    return 1 * sortOrderMultiplier;
  } else {
    return -1 * sortOrderMultiplier;
  }
};

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [sortField, setSortField] = useState();
  const [doSortAscending, setDoSortAscending] = useState(true);

  const setSortFieldOrToggleDirection = useCallback(
    (newField) => {
      if (sortField === newField) {
        setDoSortAscending(!doSortAscending);
      } else {
        setSortField(newField);
      }
    },
    [doSortAscending, sortField]
  );

  const sortAndSaveData = useCallback(
    ({ data }) => {
      if (!data) {
        return false;
      }

      if (sortField) {
        data.users.sort(sortPosts(sortField, doSortAscending));
      }
      setUsers(data.users);
    },
    [doSortAscending, sortField]
  );

  const listUsers = useCallback(async () => {
    return sortAndSaveData(await api.usersList());
  }, [sortAndSaveData]);

  useEffect(() => {
    listUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listUsers]);

  const title = 'Användare';

  return (
    <PageContent>
      <PageMainContainer>
        <Helmet title={title} />
        <PageTitle>{title}</PageTitle>

        <Module>
          <UsersTable
            users={users}
            setSortField={setSortFieldOrToggleDirection}
          ></UsersTable>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(withAdminAuthorization(UsersPage));
