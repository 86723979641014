import React, { Component, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import ErrorContext from 'contexts/ErrorContext';
import NotFoundPage from './NotFoundPage';
import ServerErrorPage from './ServerErrorPage';
import UnauthorizedPage from './UnauthorizedPage';

class PotentialErrorPageBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caughtError: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { caughtError: error };
  }

  render() {
    let caughtError;
    if (this.state.caughtError instanceof Error) {
      caughtError = this.state.caughtError.message;
    } else {
      caughtError = this.state.caughtError;
    }
    return <PotentialErrorPage {...this.props} caughtError={caughtError} />;
  }
}

const PotentialErrorPage = ({ children, caughtError }) => {
  const { error, unsetNotFound, notFound, unauthorized } =
    useContext(ErrorContext);
  const { pathname } = useLocation();

  useEffect(() => {
    !error && unsetNotFound();
  }, [error, pathname, unsetNotFound]);

  if (unauthorized) {
    return <UnauthorizedPage role={unauthorized} />;
  }

  if (error || caughtError) {
    return <ServerErrorPage error={error || caughtError} />;
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return children;
};

PotentialErrorPage.propTypes = {
  children: PropTypes.node,
  caughtError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default PotentialErrorPageBoundary;
