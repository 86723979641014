import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import useHighlightedPosts from 'hooks/useHighlightedPosts';
import UserContext from 'contexts/UserContext';
import withAuthentication from 'components/withAuthentication';
import withGuestAuthorization from 'components/withGuestAuthorization';
import PostStreamContainer from 'components/PostStream/PostStreamContainer';
import PendingPostStreamContainer from 'components/PendingPostStream/PendingPostStreamContainer';
import StreamMeta from 'components/StreamMeta/StreamMeta';
import PostEditorContainer from 'components/PostEditor/PostEditorContainer';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';
import Switch from 'components/Switch/Switch';
import Highlights from 'components/Highlights/Highlights';
import { DirektcenterPost, Stream, StreamId } from 'Types';
import s from './PostingPage.module.scss';

type PostingPageProps = {
  stream: Stream;
  loading: boolean;
  streamId: StreamId;
  handleStreamDeleted: () => void;
  createPost: (post: DirektcenterPost) => void;
};

const PostingPage = ({
  stream,
  loading,
  streamId,
  handleStreamDeleted,
  createPost,
}: PostingPageProps) => {
  const { isAdminOrEditor } = useContext(UserContext);
  const { hasHighlights, highlightedPosts } = useHighlightedPosts(streamId);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const isHighlightsFeatureEnabled = [
    'Riks',
    'Lokalt',
    'Sport',
    'Test',
  ].includes(stream.parentSection);

  useEffect(
    function resetFilteringIfNoHighlights() {
      if (!hasHighlights) {
        setIsFilterActive(false);
      }
    },
    [hasHighlights]
  );

  return (
    <PageContent withAside>
      <Helmet title={stream.title} />
      <PageMainContainer loading={loading} withAside>
        <div className={s.root}>
          <div className={s.streamMeta}>
            <StreamMeta
              stream={stream}
              streamId={streamId}
              onStreamDeleted={handleStreamDeleted}
            />
          </div>

          <div className={s.createPostEditor}>
            <h2 className="visuallyhidden">Skriv inlägg</h2>
            {stream.isActive && (
              <PostEditorContainer
                onSubmit={createPost}
                submitText={'Publicera'}
                attachmentEnabled={true}
                bigSubmit={true}
                isAuthorEditable={true}
              />
            )}
            {!stream.isActive && isAdminOrEditor && (
              <div className={s.streamInactiveMessage}>
                Strömmen är inaktiv. Aktivera strömmen i inställningarna ovan
                för att kunna skapa nya inlägg.
              </div>
            )}

            {hasHighlights && (
              <>
                <Highlights
                  posts={highlightedPosts}
                  stream={stream}
                  streamId={streamId}
                />
                <div className={s.filter}>
                  <Switch
                    onLabel="Visa endast markerade händelser"
                    offLabel="Visa endast markerade händelser"
                    onChange={(e) => {
                      setIsFilterActive(e.target.checked);
                    }}
                    variant="gray"
                    labelRight
                  />
                </div>
              </>
            )}
          </div>
          <PostStreamContainer
            streamId={streamId}
            isEditable={Boolean(stream.isActive)}
            filterHighlighted={isFilterActive}
            isHighlightsFeatureEnabled={isHighlightsFeatureEnabled}
          />
        </div>
      </PageMainContainer>

      <PendingPostStreamContainer
        streamId={streamId}
        isActive={Boolean(stream.isActive)}
        isVisitorPostingEnabled={stream.isVisitorPostingEnabled || false}
      />
    </PageContent>
  );
};

export default withAuthentication(withGuestAuthorization(PostingPage));
