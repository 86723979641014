import { SectionDataType, Stream } from 'Types';
import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { trackEvent } from 'utils/statistics';
import { createSectionData } from 'utils/streamSection';

const DEFAULT_ITEMS_PER_PAGE = 50;

const _parseSectionData = (sectionData: SectionDataType) => {
  const parsedSectionData: { section?: string; parentSection?: string } = {};

  if (sectionData.section) {
    parsedSectionData.section = sectionData.section;
  }

  if (sectionData.parentSection) {
    parsedSectionData.parentSection = sectionData.parentSection;
  }

  return parsedSectionData;
};

type useSearchStreamsType = {
  section: string;
  searchQuery: string;
};

const useSearchStreams = ({ section, searchQuery }: useSearchStreamsType) => {
  const [searchResult, setSearchResult] = useState<Stream[]>([]);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_ITEMS_PER_PAGE);

  const hasSearchResult = searchResult.length > 0;

  const executeSearch = useCallback(
    async ({
      query,
      sectionData,
      limit,
    }: {
      sectionData: SectionDataType;
      query: string;
      limit: number;
    }) => {
      const { data } = (await api.streamSearch({
        query,
        limit,
        ..._parseSectionData(sectionData),
      })) as {
        // TODO Remove when API is in TypeScript
        data: {
          hasMoreItems: boolean;
          searchResults: Stream[];
        };
      };

      setLimit(limit);
      setSearchResult(data?.searchResults || []);
      setHasNextPage(data?.hasMoreItems || false);
    },
    []
  );

  useEffect(() => {
    const searchStreams = async () => {
      setIsLoadingSearchResults(true);

      const newLimit = DEFAULT_ITEMS_PER_PAGE;
      const sectionData = createSectionData(section);

      trackEvent({
        name: 'search-streams',
        eventValues: {
          limit: newLimit,
          section: sectionData.section,
          parentSection: sectionData.parentSection,
          searchQuery,
        },
      });

      await executeSearch({
        query: searchQuery,
        sectionData,
        limit: newLimit,
      });

      setIsLoadingSearchResults(false);
    };

    searchStreams();
  }, [searchQuery, section, executeSearch]);

  const loadMore = async () => {
    setIsLoadingMore(true);

    const newLimit = DEFAULT_ITEMS_PER_PAGE + limit;
    const sectionData = createSectionData(section);

    trackEvent({
      name: 'load-more-streams',
      eventValues: {
        newLimit,
        section: sectionData.section,
        parentSection: sectionData.parentSection,
      },
    });

    await executeSearch({
      query: searchQuery,
      sectionData,
      limit: newLimit,
    });

    setIsLoadingMore(false);
  };

  return {
    loadMore,
    result: {
      hasSearchResult,
      hasNextPage,
      searchResult,
    },
    loading: {
      isLoadingSearchResults,
      isLoadingMore,
    },
  };
};

export default useSearchStreams;
