import React, { useContext } from 'react';

import NotificationContext from 'contexts/NotificationContext';
import { PostEditorContext, postEditorActions } from '../../PostEditorStore';
import AttachmentImage from '../../../Attachment/AttachmentImage';
import ImageEditor from './ImageEditor';
import uploadImageFile from 'utils/uploadImageFile';

const ImageEditorContainer = () => {
  const { state: postEditorState, dispatcher: postEditorDispatcher } =
    useContext(PostEditorContext);

  const { flashNotification } = useContext(NotificationContext);

  const mergeIntoAttachment = (data) => {
    postEditorDispatcher({
      type: postEditorActions.MERGE_ATTACHMENT,
      payload: data,
    });
  };

  const setIsUploadingImage = (value) =>
    postEditorDispatcher({
      type: postEditorActions.SET_IS_UPLOADING_IMAGE,
      payload: value,
    });

  const handleFileUpload = async (e) => {
    setIsUploadingImage(true);
    const file = e.target.files[0];

    const data = await uploadImageFile(file, flashNotification);

    if (!data) {
      setIsUploadingImage(false);
    }

    mergeIntoAttachment(data);
  };

  const attachment = postEditorState.postInProgress.attachment;

  return (
    <ImageEditor
      isUploadingImage={postEditorState.isUploadingImage}
      attachment={attachment}
      handleFileUpload={handleFileUpload}
      setIsUploadingImage={setIsUploadingImage}
      mergeIntoAttachment={mergeIntoAttachment}
    />
  );
};

ImageEditorContainer.type = 'image-editor';

ImageEditorContainer.initialData = {
  type: AttachmentImage.type,
  caption: '',
  alt: '',
  path: '',
};

export default ImageEditorContainer;
