import PropTypes from 'prop-types';
import moment from 'moment';

import Timestamp from 'components/Timestamp';
import Tooltip from 'components/Tooltip/Tooltip';

import s from './VisitorPostsTable.module.scss';

const PotentialTooltip = (params) => {
  if (!params.children) {
    return null;
  }
  return Tooltip(params);
};

export default function VisitorPostsTableRow({ pendingPost }) {
  if (!pendingPost) {
    return null;
  }

  const statusPresentation = {
    published: <span className={s.published}>publicerat</span>,
    deleted: <span className={s.deleted}>raderat</span>,
    pending: <span className={s.pending}>opublicerat</span>,
    duplicate: <span className={s.blockedAuto}>upprepande</span>,
  };

  const mCreatedAt = moment(pendingPost.createdAt);
  return (
    <tbody>
      <tr key={pendingPost.id}>
        <td>
          <Timestamp mDate={mCreatedAt} variant="iso" />
        </td>
        <td>{pendingPost.authorName}</td>
        <td className={s.tooltipTrigger}>
          <div>
            {pendingPost.visitorHash?.substring(0, 10)}
            <span
              className={s.visitorIcon}
              style={{
                background: `#${pendingPost.visitorHash?.substring(
                  pendingPost.visitorHash.length - 6
                )}`,
              }}
            >
              &nbsp;
            </span>
          </div>
          <PotentialTooltip className={s.tooltip}>
            {pendingPost.visitorHash}
          </PotentialTooltip>
        </td>
        <td>{statusPresentation[pendingPost.status]}</td>
        <td className={s.idCell}>{pendingPost.id}</td>
      </tr>
      <tr>
        <td colSpan={5} className={s.mainTextCell}>
          {pendingPost.text}
        </td>
      </tr>
    </tbody>
  );
}

VisitorPostsTableRow.propTypes = {
  pendingPost: PropTypes.object,
};
