import { useRef, useEffect } from 'react';
import _ from 'lodash';

const useDebouncedCallback = (callback, debounceTimeMs) => {
  const savedCallback = useRef(_.debounce(callback, debounceTimeMs));

  useEffect(() => {
    savedCallback.current = _.debounce(callback, debounceTimeMs);
  }, [callback, debounceTimeMs]);

  return savedCallback.current;
};

export default useDebouncedCallback;
