import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import CreateGuestsEditor from './CreateGuestsEditor';
import GuestList from './GuestList';
import Button from 'components/Button/Button';
import api from '../../services/api';

const GuestsContainer = ({ streamId, showGuestSettings }) => {
  const [guests, setGuests] = useState([]);
  const [openedGuestId, setOpenedGuestId] = useState(null);
  const [isCreatingGuest, setIsCreatingGuest] = useState(false);

  const onListGuests = useCallback(async () => {
    const { data } = await api.guestList(streamId);
    if (Array.isArray(data)) {
      setGuests(data);
    }
  }, [streamId]);

  const onDeleteGuest = async (guestId) => {
    await api.guestDelete(guestId);
    onListGuests();
  };

  const onCloseAllGuestListItems = () => {
    setOpenedGuestId(null);
  };

  const onCreateGuest = () => {
    onCloseAllGuestListItems();
    setIsCreatingGuest(true);
  };

  const PotentialGuestList = () => {
    const guestList = (
      <GuestList
        guests={guests}
        onDeleteGuest={onDeleteGuest}
        onShowInviteLink={setOpenedGuestId}
        openedGuestId={openedGuestId}
        onCloseAllGuestListItems={onCloseAllGuestListItems}
      />
    );

    if (!isCreatingGuest && guests.length === 0) {
      return guestList;
    }

    if (guests.length > 0) {
      return guestList;
    }

    return null;
  };

  useEffect(() => {
    if (showGuestSettings) {
      onListGuests();
    }
  }, [onListGuests, showGuestSettings]);

  return (
    <React.Fragment>
      <PotentialGuestList />
      {isCreatingGuest ? (
        <CreateGuestsEditor
          close={() => setIsCreatingGuest(false)}
          streamId={streamId}
          onListGuests={onListGuests}
          setOpenedGuestId={setOpenedGuestId}
        />
      ) : (
        <Button
          variant="primary"
          label="Lägg till gäst"
          onClick={onCreateGuest}
        >
          + Lägg till gäst
        </Button>
      )}
    </React.Fragment>
  );
};

GuestsContainer.propTypes = {
  streamId: PropTypes.string.isRequired,
  showGuestSettings: PropTypes.bool,
};

export default GuestsContainer;
