import React from 'react';

import ErrorContext from '../ErrorContext';

// eslint-disable-next-line react/prop-types
const ErrorContextProviderMock = ({ children }) => {
  return (
    <ErrorContext.Provider
      value={{
        error: null,
        notFound: false,
        setNotFound: () => {},
        unsetNotFound: () => {},
        setError: () => {},
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContextProviderMock;
