import Svg, { SvgProps } from './Svg';
import './AnimatedLiveIcon.module.scss';

type AnimatedLiveIconProps = Omit<SvgProps, 'children' | 'viewBox'>;

export default function AnimatedLiveIcon(props: AnimatedLiveIconProps) {
  return (
    <Svg {...props} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="7" id="live-icon-area" />
      <circle cx="8" cy="8" r="7" id="live-icon-ring" />
      <circle cx="8" cy="8" r="3" id="live-icon-center" />
    </Svg>
  );
}
