import { DirektcenterPost } from 'Types';

const DEFAULT_IMAGE_HEADING = 'Bild';
const DEFAULT_VIDEO_HEADING = 'Video';

function stripHTML(html: string) {
  // TODO: How should we strip html correctly?
  return html.replace(/<(?:.|\n)*?>/gm, '');
}

function getHeadingFromHtmlBody(html: string) {
  const firstParagraph = html.split('</p>')[0];
  return stripHTML(firstParagraph.replace('<p>', ''));
}

function getHtmlBodyWithoutHeading(html: string) {
  return html.split('</p>').slice(1).join('</p>');
}

function getHeadingFromPost(post: DirektcenterPost) {
  const heading = getHeadingFromHtmlBody(post.body);

  if (heading) {
    return heading;
  }

  switch (post.attachment?.type) {
    case 'webpage':
      return post.attachment.title;

    case 'image':
      return post.attachment.caption || DEFAULT_IMAGE_HEADING;

    case 'svt-video':
      return DEFAULT_VIDEO_HEADING;

    default:
      return '';
  }
}

export default function getHighlightParts(post: DirektcenterPost) {
  const heading = getHeadingFromPost(post);
  const body = getHtmlBodyWithoutHeading(post.body);

  return {
    heading,
    body,
  };
}
