import React from 'react';
import PropTypes from 'prop-types';

import GuestListItem from './GuestListItem';

import s from './GuestList.module.scss';

const GuestList = ({
  guests,
  onDeleteGuest,
  onShowInviteLink,
  openedGuestId,
  onCloseAllGuestListItems,
}) => {
  return (
    <React.Fragment>
      <h2 className={s.header}>Gäster</h2>
      {guests.length === 0 && <p>Det finns inga gäster i den här strömmen.</p>}
      <ul className={s.guestList}>
        {guests.map((guest) => (
          <GuestListItem
            key={guest.id}
            guest={guest}
            showOpen={guest.id === openedGuestId}
            onDeleteGuest={onDeleteGuest}
            onShowInviteLink={onShowInviteLink}
            onCloseAllGuestListItems={onCloseAllGuestListItems}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

GuestList.propTypes = {
  onDeleteGuest: PropTypes.func.isRequired,
  onShowInviteLink: PropTypes.func.isRequired,
  onCloseAllGuestListItems: PropTypes.func.isRequired,
  openedGuestId: PropTypes.number,
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    })
  ),
};

export default GuestList;
