import { Component, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import NotificationContext from '../../contexts/NotificationContext';
import api from '../../services/api';
import Post from './Post';
import AnimateIn from 'components/AnimateIn/AnimateIn';

function PostContainer({
  post,
  streamId,
  isEditable,
  isHighlightsFeatureEnabled,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { flashError } = useContext(NotificationContext);

  async function updatePost({
    body,
    attachment,
    pinned,
    highlighted,
    createdAt,
    author,
    signature,
  }) {
    setIsEditing(false);
    const response = await api.postUpdate(streamId, post.id, {
      body,
      attachment,
      pinned,
      highlighted,
      createdAt,
      author,
      signature,
    });
    if (response.status !== 'success') {
      flashError('Failed to update the post!');
    }
  }

  async function removePost() {
    setIsVisible(false);
    const response = await api.postDelete(streamId, post.id);
    if (response.status !== 'success') {
      flashError('Failed to delete the post!');
      setIsVisible(true);
    }
  }

  async function renewPost() {
    const response = await api.postRenew(streamId, post.id, {
      ...post,
    });
    if (response.status !== 'success') {
      flashError('Failed to renew the post!');
    }
  }

  async function createReply(reply) {
    setIsReplying(false);
    const response = await api.replyCreate(streamId, post.id, {
      body: reply.body,
      author: reply.author,
    });

    if (response.status !== 'success') {
      flashError('Failed to create the reply!');
    }
  }

  return (
    <AnimateIn isVisible={isVisible} animateInOnMount={true}>
      <Post
        post={post}
        updatePost={updatePost}
        removePost={removePost}
        renewPost={renewPost}
        setIsReplying={() => {
          setIsReplying(true);
          setIsEditing(false);
        }}
        unsetIsReplying={() => setIsReplying(false)}
        setIsEditing={() => {
          setIsReplying(false);
          setIsEditing(true);
        }}
        unsetIsEditing={() => setIsEditing(false)}
        createReply={createReply}
        togglePin={() => {
          updatePost({
            ...post,
            pinned: !post.pinned,
          });
        }}
        toggleHighlight={() => {
          updatePost({
            ...post,
            highlighted: !post.highlighted,
          });
        }}
        streamId={streamId}
        isEditing={isEditing}
        isReplying={isReplying}
        isEditable={isEditable}
        isHighlightsFeatureEnabled={isHighlightsFeatureEnabled}
      />
    </AnimateIn>
  );
}

export default class PostContainerWithErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unexpectedError: false,
    };
  }

  // Note: there is not yet a solution for this using hooks
  // https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
  componentDidCatch(err) {
    this.setState({
      unexpectedError: true,
    });
  }

  render() {
    if (this.state.unexpectedError) {
      return <div>Unexpected error occured!</div>;
    }
    return <PostContainer {...this.props} />;
  }
}

PostContainer.propTypes = {
  post: PropTypes.object.isRequired,
  streamId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isHighlightsFeatureEnabled: PropTypes.bool,
};
