import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import NotificationContext from 'contexts/NotificationContext';
import ErrorContext from 'contexts/ErrorContext';
import api from '../../services/api';
import CreateStreamEditor from './CreateStreamEditor';

const CreateStreamEditorContainer = ({
  onDidCreate = () => {},
  onDidCancel = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const { flashNotification } = useContext(NotificationContext);
  const { setError } = useContext(ErrorContext);

  async function createStream(stream) {
    setLoading(true);
    const { status, message, data } = await api.streamCreate(stream);

    switch (status) {
      case 'success':
        onDidCreate(data.id);
        return;
      case 'fail':
        flashNotification({
          type: 'error',
          message: `Failed to create stream: ${data.join(', ')}`,
        });
        return setLoading(false);
      default:
        return setError(message);
    }
  }

  return (
    <CreateStreamEditor
      loading={loading}
      onCreate={createStream}
      onCancel={onDidCancel}
    />
  );
};

CreateStreamEditorContainer.propTypes = {
  onDidCreate: PropTypes.func,
  onDidCancel: PropTypes.func,
};

export default CreateStreamEditorContainer;
