import { ReactComponent as SettingsIcon } from 'img/kuggis.svg';

import Button from 'components/Button/Button';

import s from './_StreamInfo.module.scss';
import { Stream, StreamId } from 'Types';
import api from 'services/api';
import { useContext } from 'react';
import NotificationContext from 'contexts/NotificationContext';
import ShowForAdmin from 'components/ShowForAdmin/ShowForAdmin';
import { trackEvent } from 'utils/statistics';
import UserContext from 'contexts/UserContext';

type DisasterStreamInfoProps = {
  stream: Stream;
  showSettings: boolean;
  toggleSettings: () => void;
  streamId: StreamId;
};

const DisasterStreamInfo = ({
  stream,
  streamId,
  showSettings,
  toggleSettings,
}: DisasterStreamInfoProps) => {
  const { user } = useContext(UserContext);
  const { flashError, flashNotification } = useContext(NotificationContext);

  const toggleStreamActive = async () => {
    trackEvent({
      name: !stream.isActive
        ? 'disaster-publish-stream'
        : 'disaster-unpublish-stream',
      eventValues: {
        userId: user?.id,
        userEmail: user?.email,
      },
    });

    const response = await api.streamUpdate(streamId, {
      ...stream,
      isActive: !stream.isActive,
      isVisitorPostingEnabled: false,
      isHighlightsBoxVisible: false,
      liveBroadcast: stream.liveBroadcast,
    });

    if (response.status === 'success') {
      const message = stream.isActive
        ? 'Nödströmmen är avpublicerad'
        : 'Nödströmmen är publicerad';

      flashNotification({
        message,
        type: 'success',
      });
    } else {
      flashError(
        `Något gick fel när nödströmmen skulle ${
          stream.isActive ? 'avpubliceras' : 'publiceras'
        }`
      );
    }
  };

  return (
    <div className={s.root}>
      <div className={s.header}>
        <h1 className={s.title}>{stream.title}</h1>
      </div>

      <Button
        variant={stream.isActive ? 'danger' : 'primary'}
        onClick={() => {
          toggleStreamActive();
        }}
      >
        {stream.isActive ? `Avpublicera Nödströmmen` : `Publicera Nödströmmen`}
      </Button>
      <ShowForAdmin>
        <div className={s.actionButtons}>
          <Button
            className={s.button}
            data-testid="streamSettingsButton"
            onClick={toggleSettings}
            variant={showSettings ? 'active' : 'light'}
            round
            tooltip="Inställningar"
          >
            <SettingsIcon />
          </Button>
        </div>
      </ShowForAdmin>
    </div>
  );
};

export default DisasterStreamInfo;
