import React from 'react';
import cn from 'classnames';
import styles from './Svg.module.scss';

export type SvgProps = {
  children: React.ReactNode;
  className?: string;
  size?: string | number;
  style?: React.CSSProperties;
  title?: string;
  viewBox?: string;
};

export default function Svg({
  children,
  className,
  size = '1em',
  style,
  title,
  viewBox = '0 0 16 16',
}: SvgProps) {
  return (
    <svg
      className={cn([styles.root, className])}
      fill="currentColor"
      focusable={false}
      height={size}
      stroke="currentColor"
      strokeWidth="0"
      style={style}
      viewBox={viewBox}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
}
