import { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import NotificationContext from '../../../contexts/NotificationContext';
import api from '../../../services/api';
import Reply from './Reply';

export default function ReplyContainer({
  streamId,
  postId,
  reply,
  isEditable,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const { flashError } = useContext(NotificationContext);

  async function removeReply() {
    const response = await api.replyRemove(streamId, postId, reply.id);
    if (response.status !== 'success') {
      flashError('Failed to remove the reply!');
    }
  }

  async function updateReply(reply) {
    setIsEditing(false);
    const response = await api.replyUpdate(streamId, postId, reply.id, {
      body: reply.body,
      createdAt: reply.createdAt,
      author: reply.author,
      signature: reply.signature,
    });
    if (response.status !== 'success') {
      flashError('Failed to update the reply!');
    }
  }

  return (
    <Reply
      reply={reply}
      removeReply={removeReply}
      updateReply={updateReply}
      setIsEditing={() => setIsEditing(true)}
      unsetIsEditing={() => setIsEditing(false)}
      isEditing={isEditing}
      isEditable={isEditable}
    />
  );
}

ReplyContainer.propTypes = {
  postId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  reply: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
};
