import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './PageMainContainer.module.scss';
import Loader from 'components/Loader/Loader';

function PageMainContainer({ children, loading, withAside }) {
  return (
    <main className={cn(s.root, { [s.withAside]: withAside })}>
      {loading ? (
        <span className={s.loading}>
          <Loader />
        </span>
      ) : (
        children
      )}
    </main>
  );
}

PageMainContainer.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  withAside: PropTypes.bool,
};

PageMainContainer.defaultProps = {
  loading: false,
};

export default PageMainContainer;
