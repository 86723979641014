import React from 'react';
import PropTypes from 'prop-types';

import s from './Color.module.scss';

const Color = ({ hex, children }) => (
  <div className={s.root}>
    <span className={s.hex} style={{ backgroundColor: hex }} />
    <span className={s.text}>{children}</span>
  </div>
);

Color.propTypes = {
  hex: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Color;
