import moment from 'moment';
import { MdError } from 'react-icons/md';
import DOMPurify from 'dompurify';

import Tooltip from 'components/Tooltip/Tooltip';
import getHighlightParts from 'utils/getHighlightParts';
import getHighlightFlag from 'utils/getHighlightFlag';
import Timestamp from 'components/Timestamp';

import { DirektcenterPost } from '../../Types';

import styles from './HighlightItem.module.scss';

type HighlightItemProps = {
  post: DirektcenterPost;
};

export default function HighlightItem({ post }: HighlightItemProps) {
  const mCreatedAt = post.createdAt && moment(post.createdAt.toDate());
  const { body, heading } = getHighlightParts(post);
  const flag = getHighlightFlag(body, heading, post);

  return (
    <li className={styles.listItem}>
      <div className={styles.timestamp}>
        <Timestamp mDate={moment(mCreatedAt)} variant="svt" />
      </div>
      <div className={styles.headingWrapper}>
        <h4
          className={styles.heading}
          key={post.id}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(heading) }}
        />
      </div>
      <div className={styles.warningWrapper}>
        {flag && (
          <div className={styles.warning}>
            <MdError />
            <Tooltip className={styles.tooltip}>{flag}</Tooltip>
          </div>
        )}
      </div>
    </li>
  );
}
