import React from 'react';
import cn from 'classnames';

import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import s from './ImageEditor.module.scss';

type ImageEditorProps = {
  isUploadingImage: boolean;
  attachment: {
    path: string;
    alt: string;
    caption: string;
  };
  handleFileUpload: () => any;
  setIsUploadingImage: (arg: boolean) => any;
  mergeIntoAttachment: (arg0: any) => any;
};

const ImageEditor = ({
  isUploadingImage,
  attachment,
  handleFileUpload,
  setIsUploadingImage,
  mergeIntoAttachment,
}: ImageEditorProps) => (
  <React.Fragment>
    {!isUploadingImage && !attachment.path && (
      <div className={cn(s.imageFileInputWrapper)}>
        <input
          autoFocus
          className={s.imageFileInput}
          onChange={handleFileUpload}
          type="file"
          accept="image/*"
          required
          id="imageFileInputId"
        />
        <label className={s.imageFileInputLabel} htmlFor="imageFileInputId">
          Ladda upp bild
        </label>
      </div>
    )}
    {isUploadingImage && (
      <div className={cn(s.imageFileInputWrapper, s.isUploading)}>
        <Loader absoluteCenter>Laddar upp bild...</Loader>
      </div>
    )}
    {attachment.path && (
      <img
        onLoad={() => setIsUploadingImage(false)}
        onError={() => setIsUploadingImage(false)}
        src={`${process.env.REACT_APP_IMAGE_BASE_URL}${attachment.path}?auto=format`}
        alt={attachment.alt || 'Förhandsvisning av bild'}
        className={cn(s.image, { [s.imageHidden]: isUploadingImage })}
      />
    )}
    <Input
      label="Bildtext"
      type="text"
      value={attachment.caption}
      onChange={(e) => mergeIntoAttachment({ caption: e.target.value })}
    />
    <Input
      label="Alt-text"
      type="text"
      value={attachment.alt}
      onChange={(e) => mergeIntoAttachment({ alt: e.target.value })}
    />
  </React.Fragment>
);

export default ImageEditor;
