import { useState, useEffect, useRef } from 'react';

const useMenuDropdownEvents = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleButtonRef = useRef(null);
  const menuRef = useRef(null);
  const wrapperRef = useRef(null);

  const setFocusToFirstItem = () => {
    window.setTimeout(() => {
      if (menuRef.current && menuRef.current.querySelector('li:first-child')) {
        menuRef.current.querySelector('li:first-child').focus();
      }
    }, 10);
  };

  const setFocusToLastItem = () => {
    window.setTimeout(() => {
      if (menuRef.current && menuRef.current.querySelector('li:last-child')) {
        menuRef.current.querySelector('li:last-child').focus();
      }
    }, 10);
  };

  const handleMenuButtonClick = (event) => {
    if (!isOpen) {
      setFocusToFirstItem();
    }

    setIsOpen(!isOpen);
  };

  const handleMenuButtonKeydown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      setIsOpen(true);
      if (event.key === 'ArrowUp') {
        setFocusToLastItem();
      } else {
        setFocusToFirstItem();
        window.setTimeout(() => {
          setFocusToFirstItem();
        }, 10);
      }
    }
  };

  useEffect(() => {
    const menuButtonElement = toggleButtonRef.current;
    const menuElement = menuRef.current;
    const wrapperElement = wrapperRef.current;
    let menuItemsFirstCharacters = null;
    let menuItemElements = null;

    const setFocusByFirstCharacter = (char) => {
      const getIndexFirstChars = (startIndex, char) => {
        for (let i = startIndex; i < menuItemsFirstCharacters.length; i++) {
          if (char.toLowerCase() === menuItemsFirstCharacters[i]) {
            return i;
          }
        }
        return -1;
      };

      let startIndex = menuItemElements.indexOf(document.activeElement) + 1;
      if (startIndex === menuItemElements.length) {
        startIndex = 0;
      }

      let index = getIndexFirstChars(startIndex, char);
      if (index === -1) {
        index = getIndexFirstChars(0, char);
      }

      if (index > -1) {
        menuItemElements[index].focus();
      }
    };

    const handleMenuKeydown = (event) => {
      switch (event.key) {
        case 'Enter':
        case ' ':
          event.preventDefault();
          event.target.click();
          if (menuButtonElement) {
            menuButtonElement.focus();
          }
          break;
        case 'Escape':
          event.preventDefault();
          setIsOpen(false);
          menuButtonElement.focus();
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (document.activeElement.previousElementSibling) {
            document.activeElement.previousElementSibling.focus();
          } else {
            setFocusToLastItem();
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (document.activeElement.nextElementSibling) {
            document.activeElement.nextElementSibling.focus();
          } else {
            setFocusToFirstItem();
          }
          break;
        case 'Home':
          event.preventDefault();
          setFocusToFirstItem();
          break;
        case 'End':
          event.preventDefault();
          setFocusToLastItem();
          break;
        default:
          if (/^[a-zA-ZåäöÅÄÖ]$/.test(event.key)) {
            setFocusByFirstCharacter(event.key);
            // https://www.w3.org/TR/wai-aria-practices/examples/menu-button/js/PopupMenuAction.js
          }
          return;
      }
    };

    const handleWrapperFocusout = (event) => {
      if (!wrapperElement.contains(event.relatedTarget)) {
        setIsOpen(false);
      }
    };

    const handleMenuClick = (event) => {
      setIsOpen(false);
    };

    if (isOpen && menuElement) {
      menuElement.addEventListener('keydown', handleMenuKeydown);
      menuElement.addEventListener('click', handleMenuClick);

      if (wrapperElement) {
        wrapperElement.addEventListener('focusout', handleWrapperFocusout);
      }

      menuItemElements = [...menuElement.querySelectorAll('li')];
      menuItemsFirstCharacters = menuItemElements.map((listItem) =>
        listItem.textContent.substring(0, 1).toLowerCase()
      );
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener('keydown', handleMenuKeydown);
        menuElement.removeEventListener('click', handleMenuClick);
      }

      if (wrapperElement) {
        wrapperElement.removeEventListener('focusout', handleWrapperFocusout);
      }
    };
  }, [isOpen]);

  return {
    isOpen,
    toggleButtonRef,
    wrapperRef,
    menuRef,
    handleMenuButtonClick,
    handleMenuButtonKeydown,
  };
};

export default useMenuDropdownEvents;
