import React from 'react';

import Module from 'components/Module/Module';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

type ServerErrorPageProps = {
  error: string;
};

function ServerErrorPage({ error }: ServerErrorPageProps) {
  return (
    <PageContent>
      <PageMainContainer>
        <PageTitle>Oväntat fel!</PageTitle>
        <Module type="error">
          <p>
            <strong>{error}</strong>
          </p>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
}

export default ServerErrorPage;
