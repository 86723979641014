import { useRef, useEffect } from 'react';
import _ from 'lodash';

/**
 * Checks for updates to scrollTop for a specific element. Throttled.
 * @param {func} handler callback that gets passed scrollTop when the value changes
 * @param {element} element element to check scrollTop on
 * @param {number} throttle run handler every <throttle> ms at most
 */
const useScrollTopListener = (handler, element, throttle = 100) => {
  const savedEventListener = useRef();
  const savedScrollTop = useRef(0);

  useEffect(() => {
    savedEventListener.current = _.throttle(() => {
      const newScrollTop = element.scrollTop;
      if (savedScrollTop.current !== newScrollTop) {
        handler(newScrollTop);
      }
      savedScrollTop.current = newScrollTop;
    }, throttle);
  }, [element, handler, throttle]);

  useEffect(() => {
    if (!element) return;
    element.addEventListener('scroll', savedEventListener.current);
    return () => {
      element.removeEventListener('scroll', savedEventListener.current);
    };
  }, [element]);
};

export default useScrollTopListener;
