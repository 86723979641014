import React, { useContext } from 'react';

import AttachmentEditorForm from './AttachmentEditorForm';
import NewAttachmentSelector from './NewAttachmentSelector';
import { PostEditorContext } from 'components/PostEditor/PostEditorStore';

const AttachmentEditor = () => {
  const { state: postEditorState } = useContext(PostEditorContext);
  if (postEditorState.postInProgress.attachment) {
    return <AttachmentEditorForm />;
  } else {
    return <NewAttachmentSelector />;
  }
};

export default AttachmentEditor;
