import React from 'react';
import PropTypes from 'prop-types';

import s from './PageTitle.module.scss';

const PageTitle = ({ children }) => <h1 className={s.root}>{children}</h1>;

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
