import {
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAt,
  endBefore,
  onSnapshot,
} from 'firebase/firestore';
import _ from 'lodash';
import getInitializedFirestore from '../../utils/getInitializedFirestore';

const noop = () => {};

const StreamCollection = ({
  streamId,
  collectionName,
  orderByArray,
  postsPerPage,
}) => {
  const firestore = getInitializedFirestore();

  let state = {
    onUpdate: null,
    onError: null,
    onUnreadUpdate: null,
    onUnreadError: null,
    unsubscribeUnreadFirestoreListener: null,
    unsubscribeFirestoreListener: null,
    limit: postsPerPage,
    paused: false,
    mostRecentSnapshot: null,
    filterHighlighted: false,
  };

  const _firestoreDatesToIsoDates = (value) => {
    if (value && value.toDate) {
      return value.toDate().toISOString();
    }
    return value;
  };

  const _repliesToRepliesWithIsoDates = (replies) => {
    return _.mapValues(replies, (reply) => {
      return _.mapValues(reply, _firestoreDatesToIsoDates);
    });
  };

  const _processDatesInReplies = (value, key) => {
    if (key === 'replies') {
      return _repliesToRepliesWithIsoDates(value);
    }
    return value;
  };

  const _querySnapshotToDocs = (querySnapshot) => {
    if (!querySnapshot) {
      return [];
    }
    const docs = [];
    querySnapshot.forEach((doc) => {
      const docDataWithIsoDates = _.chain(doc.data())
        .mapValues(_firestoreDatesToIsoDates)
        .mapValues(_processDatesInReplies)
        .value();
      docs.push({ ...docDataWithIsoDates, id: doc.id });
    });
    return docs;
  };

  const _getFirstItemFromMostRecentSnapshot = () => {
    return state.mostRecentSnapshot && state.mostRecentSnapshot.docs[0];
  };

  const _hasItems = () => {
    return state.mostRecentSnapshot && state.mostRecentSnapshot.size > 0;
  };

  const _unsubscribeFirestoreListener = () => {
    state.unsubscribeFirestoreListener && state.unsubscribeFirestoreListener();
  };

  const _unsubscribeUnreadFirestoreListener = () => {
    state.unsubscribeUnreadFirestoreListener &&
      state.unsubscribeUnreadFirestoreListener();
  };

  const _setupFirestoreListener = () => {
    _unsubscribeFirestoreListener();
    if (state.paused && !_hasItems()) {
      return;
    }

    const handleQuerySnapshot = (querySnapshot) => {
      state.mostRecentSnapshot = querySnapshot;
      const items = _querySnapshotToDocs(querySnapshot);
      return state.onUpdate(items);
    };

    let q = query(
      collection(
        doc(collection(firestore, 'streams'), streamId),
        collectionName
      ),
      ...orderByArray.map(([key, order]) => orderBy(key, order))
    );

    if (state.filterHighlighted) {
      q = query(q, where('highlighted', '==', true));
    }

    if (state.paused) {
      q = query(q, startAt(_getFirstItemFromMostRecentSnapshot()));
    }

    state.unsubscribeFirestoreListener = onSnapshot(
      query(q, limit(state.limit)),
      handleQuerySnapshot,
      state.onError
    );
  };

  const _setupFirestoreUnreadListener = () => {
    const handleSnapshot = (snapshot) => {
      return state.onUnreadUpdate(snapshot.size);
    };

    let q = query(
      collection(
        doc(collection(firestore, 'streams'), streamId),
        collectionName
      ),
      ...orderByArray.map(([key, order]) => orderBy(key, order))
    );

    if (_hasItems()) {
      q = query(q, endBefore(_getFirstItemFromMostRecentSnapshot()));
    }

    state.unsubscribeUnreadFirestoreListener = onSnapshot(
      q,
      handleSnapshot,
      state.onUnreadError
    );
  };

  const hasMoreItems = () => {
    return Boolean(
      state.mostRecentSnapshot &&
        state.mostRecentSnapshot.size > state.limit - 1
    );
  };

  const pause = () => {
    state.paused = true;
    _setupFirestoreListener();
  };

  const resume = () => {
    state.paused = false;
    _setupFirestoreListener();
  };

  const listen = (onUpdate, onError = noop) => {
    state.onUpdate = onUpdate;
    state.onError = onError;
    _setupFirestoreListener();
  };

  const unsubscribe = () => {
    state.onUpdate = null;
    state.onError = null;
    _unsubscribeFirestoreListener();
  };

  const listenUnread = (onUpdate, onError = noop) => {
    if (!state.paused) {
      return onError(new Error('Cannot setup unread listener if not paused!'));
    }
    state.onUnreadUpdate = onUpdate;
    state.onUnreadError = onError;
    _setupFirestoreUnreadListener();
  };

  const unsubscribeUnread = () => {
    state.onUnreadUpdate = null;
    state.onUnreadError = null;
    _unsubscribeUnreadFirestoreListener();
  };

  const loadMoreItems = () => {
    if (!hasMoreItems()) {
      return;
    }
    state.limit = state.limit + postsPerPage;
    _setupFirestoreListener();
  };

  const setFilterHighlighted = (isFilterActive) => {
    state.filterHighlighted = isFilterActive;
    _setupFirestoreListener();
  };

  return {
    listen,
    unsubscribe,
    pause: pause,
    resume: resume,
    listenUnread,
    unsubscribeUnread,
    hasMoreItems,
    loadMoreItems,
    state,
    setFilterHighlighted,
  };
};

export default StreamCollection;
