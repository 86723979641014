import React from 'react';
import PropTypes from 'prop-types';

import PendingPostStream from './PendingPostStream';
import usePauseManager from './usePauseManager';
import usePaginatedPendingPostStream from 'hooks/usePaginatedPendingPostStream';

const PendingPostStreamContainer = ({
  streamId,
  isActive,
  isVisitorPostingEnabled,
}) => {
  const pauseManager = usePauseManager();

  const paginatedStream = usePaginatedPendingPostStream({
    streamId,
    isPaused: pauseManager.pauseState !== 'ACTIVE',
    postsPerPage: 20,
  });

  return (
    <PendingPostStream
      pauseManager={pauseManager}
      paginatedStream={paginatedStream}
      streamId={streamId}
      isActive={isActive}
      isVisitorPostingEnabled={isVisitorPostingEnabled}
    />
  );
};

PendingPostStreamContainer.propTypes = {
  streamId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isVisitorPostingEnabled: PropTypes.bool.isRequired,
};

export default PendingPostStreamContainer;
