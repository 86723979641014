import React from 'react';
import cn from 'classnames';

import s from './Switch.module.scss';

type FilterToggleProps = {
  onLabel: string;
  offLabel: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'green' | 'gray';
  labelRight?: boolean;
  labelLeft?: boolean;
  checked?: boolean;
};

export default function Switch({
  onLabel,
  offLabel,
  onChange,
  variant = 'green',
  labelRight = false,
  labelLeft = false,
  ...props
}: FilterToggleProps) {
  const allProps = {
    ...props,
  } as { role?: 'button' };
  if (onChange) {
    allProps.role = 'button';
  }
  const label = (
    <>
      <span className={s.onLabel} data-testid="switchOnLabel">
        {onLabel}
      </span>
      <span className={s.offLabel} data-testid="switchOffLabel">
        {offLabel}
      </span>
    </>
  );
  return (
    <label
      className={cn(s.root, {
        [s.variantGreen]: variant === 'green',
        [s.variantGray]: variant === 'gray',
      })}
      data-testid="switch"
    >
      <input
        className={s.input}
        type="checkbox"
        onChange={onChange}
        {...allProps}
      />
      {labelLeft && label}
      <span
        className={cn(s.rail, {
          [s.labelRight]: labelRight,
          [s.labelLeft]: labelLeft,
        })}
      >
        <span className={s.ball} />
      </span>
      {labelRight && label}
    </label>
  );
}
